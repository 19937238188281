<div class="row gx-md-3 align-items-center position-relative">
  <!-- Origin Airport -->
  <div class="col-12 col-md">
    <app-airport-search class="t-airport-search t-origin" #originSearch [iconType]="'fa-location-crosshairs'" [(formInput)]="dynamicPackagesSearch.origin" [placeHolder]="'¿De dónde saldrás?'" (formInputChange)="onOriginChange()" ngDefaultControl>
    </app-airport-search>
  </div>
  <!-- SwapDirections -->
  <a class="t-switch-directions" matRipple (click)="swapDestinations()">
    <i class="fa-regular fa-arrows-repeat"></i>
  </a>
   <!-- Destination Airport -->
  <div class="col-12 col-md">
    <app-airport-search class="t-airport-search t-destination" #destinationSearch [isDestination]="true" [iconType]="'fa-location-dot'" [(formInput)]="dynamicPackagesSearch.destination" [placeHolder]="'¿A dónde querés viajar?'" (formInputChange)="onDestinationChange()" ngDefaultControl>
    </app-airport-search>
  </div>
</div>