import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/authentication/user.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';

@Component({
  selector: 'app-header-login-mobile',
  templateUrl: './header-login-mobile.component.html',
  styleUrl: './header-login-mobile.component.scss',
})
export class HeaderLoginMobileComponent {
  user$ = this.authService.getUser();

  constructor(
    private authService: AuthenticationService,
    private legacyRouter: LegacyRouterService
  ) {}

  logOut() {
    window.location.href = '/logout';
  }

  goToMyProfile() {
    this.legacyRouter.redirectToProfile();
  }

  goToLogIn() {
    window.location.href = '/login';
  }

  goToCreateAccount() {
    window.location.href = '/create-account';
  }
}
