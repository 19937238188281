<!-- Login -->
<div class="t-create-account d-flex vh-100 overflow-hidden">
    <section class="t-sidebar-img col d-none d-lg-block p-5">
        <h1 class="h1 fw-bold text-white text-center">
            Conectamos amigos <br />
            con experiencias únicas
        </h1>
    </section>

    <section class="t-content-create-account bg-white col d-grid align-items-baseline overflow-auto">
        <nav class="d-flex justify-content-end w-100 border-bottom p-3">
            <a href="" class="text-dark"><i class="fa-regular fa-xmark fs-2"></i></a>
        </nav>
        <main class="d-flex justify-content-center align-items-center">
            <div class="t-content-create-account text-center px-3 py-5">
                <!-- Sign Up - step 1 -->
                <!-- <div>
          <img
            src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg"
            width="120"
            alt="Logo TocTocViajes"
            class="d-flex mx-auto"
          />

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Crear cuenta</h2>
            <p class="text-secondary m-0">
              Selecciona una de las opciones para continuar.
            </p>
          </div>

          <h4 class="mb-3 text-secondary">Continuar con</h4>
          <button
            type="button"
            mat-stroked-button
            aria-label="Ingresar con correo electrónico"
            class="w-100 p-4 justify-content-start mb-2"
          >
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-envelope text-primary fs-5 me-2"></i
              >Correo electrónico
            </div>
          </button>
          <button
            type="button"
            mat-stroked-button
            aria-label="Ingresar con Facebook"
            class="w-100 p-4 justify-content-start"
          >
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-facebook text-primary fs-5 me-2"></i
              >Ingresar con Facebook
            </div>
          </button>
        </div> -->
                <!-- Sign Up - step 2-->
                <div *ngIf="!createAccountSuccesfull">
                    <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" width="120" alt="Logo TocTocViajes" class="d-flex mx-auto" />

                    <div class="row mt-4 mb-5">
                        <h2 class="h2 mb-2">Crear cuenta</h2>
                        <p class="text-secondary m-0">
                            Ingresá tus datos para crear tu cuenta.
                        </p>
                    </div>

                    <form [formGroup]="createAccountForm" (ngSubmit)="createUser()" class="text-start">
                        <div class="row mb-3">
                            <label class="fw-semibold mb-1">Nombre y Apellido</label>
                            <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                                <input type="text" matInput placeholder="Ej. Juan Perez" formControlName="name" />
                            </mat-form-field>
                        </div>
                        <div class="row mb-3">
                            <label class="fw-semibold mb-1">Email</label>
                            <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                                <input #emailInput type="email" matInput placeholder="Ej. juan.carlos@correo.com" formControlName="email" />
                            </mat-form-field>
                        </div>

                        <div class="row mb-3">
                            <label class="fw-semibold mb-1">Contraseña</label>
                            <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                                <input type="password" matInput placeholder="Ingresa tu contraseña" [type]="hide ? 'password' : 'text'" formControlName="password" />
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <i class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} fa-2xs"></i>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="row mb-4">
                            <div class="col-12">
                                <h6 class="mb-3"><small>La contraseña debe tener:</small></h6>
                            </div>
                            <div class="col">
                                <ul>
                                    <li class="text-secondary mb-1">
                                        <i class="fa-solid fa-circle-check fa-lg me-2" [class.text-success]="criteria.lowerCase" [class.opacity-25]="!criteria.lowerCase"></i><small>Minúsculas</small>
                                    </li>
                                    <li class="text-secondary mb-1">
                                        <i class="fa-solid fa-circle-check fa-lg me-2" [class.text-success]="criteria.upperCase" [class.opacity-25]="!criteria.upperCase"></i><small>Mayúsculas</small>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-auto">
                                <ul>
                                    <li class="text-secondary mb-1">
                                        <i class="fa-solid fa-circle-check fa-lg me-2" [class.text-success]="criteria.number" [class.opacity-25]="!criteria.number"></i><small>Números</small>
                                    </li>
                                    <li class="text-secondary mb-1">
                                        <i class="fa-solid fa-circle-check fa-lg me-2" [class.text-success]="criteria.length" [class.opacity-25]="!criteria.length"></i><small>Entre 8 y
                                            16 characters</small>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <button type="submit" mat-flat-button color="primary" aria-label="Crear cuenta" class="w-100 text-center mb-2 p-4">
                            <div *ngIf="!loading">
                                Crear cuenta
                            </div>
                            <div *ngIf="loading">
                                <i class="fas fa-spinner-third fa-fw fs-4 fa-spin"></i>
                            </div>
                        </button>
                    </form>

                    <p class="mt-4 text-secondary">
                        ¿Ya tenés cuenta?
                        <a href="/login" class="text-primary" aria-label="Ingresar">Iniciar sesión</a>
                    </p>

                    <hr />

                    <p class="text-secondary">
                        <small>
                            El uso de tu cuenta quedará sujeto a nuestros
                            <a href="#" class="text-dark text-decoration-underline" (click)="openTermsOfUse(); $event.preventDefault();" aria-label="Ver Términos de uso">Términos de uso</a>. Tus datos personales se tratarán
                            según nuestra
                            <br class="d-none d-sm-block" />
                            Política de privacidad.
                        </small>
                    </p>
                </div>
                <!-- Sign Up - step 3-->
                <div *ngIf="createAccountSuccesfull">
                    <div class="row justify-content-center">
                        <div class="bg-light rounded-circle w-auto p-5 position-relative d-flex justify-content-center align-items-center">
                            <i class="fa-light fa-envelope fa-2xl position-absolute"></i>
                        </div>
                    </div>

                    <div class="row mt-4 mb-5">
                        <h2 class="h2 mb-2">Revisá tu email</h2>
                        <p class="text-secondary mb-2">
                            Te enviamos las instrucciones de recuperación de contraseña a tu
                            correo electrónico.
                        </p>
                        <p class="text-secondary mb-0">
                            Si no recibiste el email, revisá tu casilla de spam o
                            <a href="#" (click)="tryAgain()" aria-label="back">intentá con otro correo electrónico</a>.
                        </p>
                    </div>

                    <button type="button" mat-flat-button color="primary" aria-label="Entendido" class="w-100 text-center mb-2 p-4" (click)="goToLastUrl()">
                        Entendido
                    </button>
                </div>
            </div>
        </main>
    </section>
</div>