<h5 class="mb-3">Productos</h5>
<div class="fs-6">
  <mat-radio-group aria-label="Selecciona una región" class="d-grid t-radio-group t-btns-filters" [(ngModel)]="selectedRadioValueProduct" (change)="onRadioChangeProduct(selectedRadioValueProduct)">
    <div class="row row-cols-2 g-3">
      <div class="col">
        <mat-radio-button value="10" color="primary" [checked]="selectedRadioValueProduct === '10'" routerLink="/promociones" [queryParams]="{ product: null }" queryParamsHandling="merge" class="p-0">Todos los productos</mat-radio-button>
      </div>
      <div class="col">
        <mat-radio-button value="11" color="primary" [checked]="selectedRadioValueProduct === '11'" routerLink="/promociones" [queryParams]="{ product: 'vuelo' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/flights-product-light.svg" width="20" />Vuelos</mat-radio-button>
      </div>
      <div class="col">
        <mat-radio-button value="13" color="primary" [checked]="selectedRadioValueProduct === '13'" routerLink="/promociones" [queryParams]="{ product: 'vuelo-hotel' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/dp-product-light.svg" width="20" />Vuelo + Hotel</mat-radio-button>
      </div>
      <div class="col">
        <a class="mat-mdc-radio-button mat-primary align-items-center" href="https://paquetes.toctocviajes.com/paquetes" target="_blank"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/packages-product-light.svg" width="20" /><label class="mdc-label mat-mdc-radio-button mdc-form-field" for="">Paquetes</label></a>
        <!-- <mat-radio-button value="14" color="primary" [checked]="selectedRadioValueProduct === '14'" routerLink="" [queryParams]="{ product: 'paquete' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/packages-product-light.svg" width="20" />Paquetes</mat-radio-button> -->
      </div>
    </div>

  </mat-radio-group>
</div>

<h5 class="m-0 mt-5 mb-3">Regiones</h5>
<div class="row ">
  <div class="col">
    <div class="fs-6">
      <mat-radio-group aria-label="Selecciona una región" class="d-grid  t-radio-group t-btns-filters" [(ngModel)]="selectedRadioValueRegion" (change)="onRadioChangeRegion(selectedRadioValueRegion)">
        <div class="row row-cols-2 g-3">
          <div class="col">
            <mat-radio-button value="1" color="primary" [checked]="true" value="1" routerLink="/promociones" [queryParams]="{ region: null }" queryParamsHandling="merge" class="p-0">Todas las regiones</mat-radio-button>
          </div>
          <div class="col">
            <mat-radio-button value="4" color="primary" routerLink="/promociones" [queryParams]="{ region: 'brasil' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/brasil-region.svg" />Brasil</mat-radio-button>
          </div>
          <div class="col">
            <mat-radio-button value="5" color="primary" routerLink="/promociones" [queryParams]="{ region: 'caribe' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/caribbean-region.svg" />Caribe</mat-radio-button>
          </div>
          <div class="col">
            <mat-radio-button value="6" color="primary" routerLink="/promociones" [queryParams]="{ region: 'europa' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/europe-region.svg" />Europa</mat-radio-button>
          </div>
          <div class="col">
            <mat-radio-button value="3" color="primary" routerLink="/promociones" [queryParams]="{ region: 'america-del-norte' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/northamerica-region.svg" />Norteam&eacute;rica</mat-radio-button>
          </div>
          <div class="col">
            <mat-radio-button value="2" color="primary" routerLink="/promociones" [queryParams]="{ region: 'latinoamerica' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/latinoamerica-region.svg" width="36" />Latinoam&eacute;rica</mat-radio-button>
          </div>
          <div class="col">
            <mat-radio-button value="7" color="primary" routerLink="/promociones" [queryParams]="{ region: 'asia' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/asia-region.svg" />Asia</mat-radio-button>
          </div>
          <div class="col">
            <mat-radio-button value="8" color="primary" routerLink="/promociones" [queryParams]="{ region: 'oceania' }" queryParamsHandling="merge"><img src="https://tocuyprodmedia.blob.core.windows.net/img/illustrations/promotions-filter-icons/oceania-region.svg" width="50" />Ocean&iacute;a</mat-radio-button>
          </div>
          <!-- <div class="col-6">
            <mat-radio-button value="9" color="primary" routerLink="" [queryParams]="{ region: 'africa' }" queryParamsHandling="merge">&Aacute;frica</mat-radio-button>
          </div> -->
        </div>
      </mat-radio-group>
    </div>
  </div>

</div>

<a href="https://paquetes.toctocviajes.com/" target="_blank" class="d-flex d-lg-none align-items-center justify-content-between border-top border-light fs-6 text-dark py-4 my-4">
  Visitá nuestra sección de paquetes
  <i class="fa-solid fa-chevron-right fa-sm mt-1"></i>
</a>

<div class="p-3 border-top position-sticky bottom-0 bg-white w-100 d-lg-none">
  <button class="w-100" type="button" mat-flat-button color="primary" (click)="closeSheet()">Listo</button>
</div>