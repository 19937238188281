import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ErrorManagementService } from 'src/app/services/error-management.service';
import { LastVisitedUrlService } from 'src/app/services/last-visited-url.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  loading: boolean = false;

  showType: 'logIn' | 'recovery' | 'sendMail' = 'logIn';

  recoveryEmail = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private authService: AuthenticationService,
    private route: Router,
    private errorService: ErrorManagementService,
    private lastVisitedUrlService: LastVisitedUrlService
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.route.navigate(['/']);
    }
  }

  logIn() {
    if (!this.loading && this.loginForm.valid) {
      if (
        this.loginForm &&
        this.loginForm.value &&
        this.loginForm.value.userName &&
        this.loginForm.value.password
      ) {
        this.loading = true;
        this.authService
          .logIn(this.loginForm.value.userName, this.loginForm.value.password)
          .subscribe({
            next: (response) => {
              if (response) {
                const lastUrl = this.lastVisitedUrlService.getLastUrlVisited();
                this.route.navigate([lastUrl]);
              }
            },
            error: (error) => {
              this.loading = false;
              this.errorService.showValidationErrors(error, '');
            },
            complete: () => {
              this.loading = false;
            },
          });
      }
    }
  }

  recoverPassword() {
    this.showType = 'recovery';
  }

  backToLogIn() {
    this.showType = 'logIn';
  }

  recover() {
    if (!this.loading && this.recoveryEmail.valid) {
      const email = this.recoveryEmail.value;
      if (email) {
        this.loading = true;
        this.authService.recoveryPassword(email).subscribe({
          next: (response) => {
            if (response) {
              this.showType = 'sendMail';
            }
          },
          error: (error) => {
            this.errorService.showValidationErrors(error, '');
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          },
        });
      }
    }
  }

  showLogIn() {
    return this.showType === 'logIn';
  }

  showRecovery() {
    return this.showType === 'recovery';
  }

  showCheckMailMessage() {
    return this.showType === 'sendMail';
  }
}
