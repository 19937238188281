import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './local-storage.service';
import { FlightSearchModel } from '../models/search/flight-search-leg';
import { HotelSearchModel } from '../models/search/hotel-search';
import { DynamicPackageSearchModel } from '../models/search/dynamic-package-search';
import { InsuranceSearchModel } from '../models/search/insurance-search';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private environment: EnvironmentService,
    private localStorageService: LocalStorageService
  ) {}

  saveFlightSearch(search: FlightSearchModel) {
    if (this.environment.isBrowser) {
      this.localStorageService.set('ttv-lastSearch-flights', search);
      this.saveLastSearchedProduct('flight');
    }
  }

  retrieveLastFlightSearch(): FlightSearchModel | undefined {
    if (this.environment.isBrowser) {
      return this.localStorageService.get('ttv-lastSearch-flights');
    }

    return undefined;
  }

  saveLastSearchedProduct(
    product: 'flight' | 'hotel' | 'flightHotel' | 'package' | 'insurance'
  ) {
    if (this.environment.isBrowser) {
      this.localStorageService.set('ttv-lastSearchedProduct', product);
    }
  }

  retrieveLastSearchProduct():
    | 'flight'
    | 'hotel'
    | 'flightHotel'
    | 'package'
    | 'insurance' {
    if (this.environment.isBrowser) {
      return (
        this.localStorageService.get('ttv-lastSearchedProduct') || 'flight'
      );
    }

    return 'flight';
  }

  saveHotelSearch(search: HotelSearchModel) {
    if (this.environment.isBrowser) {
      this.localStorageService.set('ttv-lastSearch-hotels', search);
      this.saveLastSearchedProduct('hotel');
    }
  }

  retrieveLastHotelSearch(): HotelSearchModel | undefined {
    if (this.environment.isBrowser) {
      return this.localStorageService.get('ttv-lastSearch-hotels');
    }

    return undefined;
  }

  saveDynamicPackageSearch(search: DynamicPackageSearchModel) {
    if (this.environment.isBrowser) {
      this.localStorageService.set('ttv-lastSearch-flightHotel', search);
      this.saveLastSearchedProduct('flightHotel');
    }
  }

  retrieveLastDynamicPackageSearch(): DynamicPackageSearchModel | undefined {
    if (this.environment.isBrowser) {
      return this.localStorageService.get('ttv-lastSearch-flightHotel');
    }

    return undefined;
  }

  saveLastInsuranceSearch(insuranceSearch: InsuranceSearchModel) {
    if (this.environment.isBrowser) {
      this.localStorageService.set('ttv-lastSearch-insurance', insuranceSearch);
      this.saveLastSearchedProduct('insurance');
    }
  }

  retrieveInsuranceSearch(): InsuranceSearchModel | undefined {
    if (this.environment.isBrowser) {
      return this.localStorageService.get('ttv-lastSearch-insurance');
    }

    return undefined;
  }
}
