<!-- Logged User - Dropdown Menu -->
<ng-container *ngIf="user$ | async as user">
  <button mat-stroked-button class="rounded-pill" [matMenuTriggerFor]="menu" aria-label="Abrir menu de sesión">
    <div class="d-flex align-items-center gap-2">
      <span class="fw-normal lh-sm">{{ user.firstName }}</span>
      <i class="fa-light fa-user fs-5" aria-hidden="true"></i>
    </div>
  </button>
  <mat-menu #menu="matMenu" xPosition="before" class="mt-2 rounded-3">
    <button mat-menu-item (click)="goToMyProfile()">
      <i class="fa-regular fa-user me-3"></i>
      <span>Mi perfil</span>
    </button>
    <button mat-menu-item (click)="logOut()">
      <i class="fa-regular fa-arrow-right-from-bracket me-2"></i>
      <span>Cerrar sesión</span>
    </button>
  </mat-menu>
</ng-container>
<!-- Unlogged user - Dropdown Menu -->
<ng-container *ngIf="!(user$ | async)">
  <button mat-stroked-button class="rounded-pill" [matMenuTriggerFor]="unloggedMenu" aria-label="Abrir menu de sesión">
    <div class="d-flex align-items-center">
      <i class="fa-light fa-user fs-5"></i>
    </div>
  </button>
  <mat-menu #unloggedMenu="matMenu" xPosition="before" class="mt-2 rounded-3">
    <div class="text-center p-3 pt-4">
      <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/outline-icon-user-ok.svg" width="60px" alt="Inicio de sesión" class="mb-3" />
      <div class="fs-5 fw-semibold d-inline-table lh-sm mb-5">
        Iniciá sesión y descubrí beneficios
      </div>
      <button mat-flat-button color="primary" class="w-100 mb-2" (click)="goToLogIn()">
        Iniciar sesión
      </button>
      <button mat-button color="primary" class="w-100" (click)="goToCreateAccount()">Crear una cuenta</button>
    </div>
  </mat-menu>
</ng-container>