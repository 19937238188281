import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private cookieService: CookieService) {}

  set(key: string, object: any) {
    localStorage.setItem(key, JSON.stringify(object));
  }
  get(key: string) {
    const item = localStorage.getItem(key);

    if (item) {
      try {
        return JSON.parse(item, this.reviver);
      } catch (error) {
        return null;
      }
    }
  }

  private reviver(key: string, value: any) {
    let dateFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    if (typeof value === 'string' && dateFormat.test(value)) {
      return new Date(value);
    }

    return value;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  setCookie(key: string, object: any) {
    this.cookieService.set(key, JSON.stringify(object));
  }

  removeCookie(key: string) {
    this.cookieService.delete(key);
  }
}
