<div class="container">
  <div class="t-location-search-mobile_header position-sticky start-0 top-0 w-100 bg-white"></div>
  <!-- Sticky header > Title -->
  <div class="d-flex align-items-center justify-content-between ps-3">
    <h5 class="fw-normal m-0">¿Dónde querés hospedarte?</h5>
    <button class="p-3 bg-transparent border-0" (click)="close($event)">
      <i class="fa-regular fs-2 text-secondary fa-times"></i>
    </button>
  </div>
  <!-- Sticky header > Input -->
  <div class="w-100 p-3 pt-2">
    <form>
      <mat-form-field class="t-location-search_form-field w-100 t-mat-form-field t-hide-bottom-messages" appearance="outline">
        <div class="w-100 d-flex align-items-center">
          <ng-container #pinMarker *ngIf="spinning(); else pinMarker">
            <i class="fa-regular fa-lg fa-circle-notch fa-spin text-primary me-3"></i>
          </ng-container>
          <ng-template #pinMarker>
            <i class="fa-regular fs-5 fa-location-dot text-primary me-3"></i>
          </ng-template>
          <input type="text" matInput #inputElement [formControl]="formInput" [placeholder]="getTitle()" />
          <ng-container *ngIf="formInput.value">
            <i class="fa-regular fa-times ms-2 text-secondary" style="cursor: pointer;" (click)="formInput.setValue(undefined)"></i>
          </ng-container>
        </div>
      </mat-form-field>
    </form>
  </div>
</div>
<!-- Autocomplete -->
<div class="t-location-search-mobile_wrapper">
  <ul class="t-autocomplete-list d-flex flex-column p-0">
    <ng-container *ngFor="let region of (regions | async)">
      <!-- Autocomplete > Type -->
      <li class="t-autocomplete-list d-flex flex-column p-0">
        <div class="ps-4 py-2 bg-secondary bg-opacity-10 fw-bold text-dark">
          {{ getRegionType(region) }}
        </div>
      </li>
      <!-- Autocomplete > Location -->
      <ng-container *ngFor="let option of region.locations" class="row t-autocomplete-list d-flex flex-column p-0">
        <li class="t-autocomplete-list_result d-flex gap-3 align-items-center text-dark px-4 overflow-hidden border-bottom border-light" (click)="selectItem(option)">
          <i class="fa-regular fa-location-dot fs-5"></i>
          <span class="fw-semibold lh-sm text-dark text-truncate fs-6">
            {{ getDisplay(option) }}
          </span>
      </ng-container>
    </ng-container>
  </ul>
</div>