<div class="d-flex align-items-center justify-content-between justify-content-sm-start gap-3 mb-4 mb-xl-3">
    <div class="h5 d-inline-flex fw-normal m-0">Armá tu viaje a medida</div>
    <span class="d-flex text-center w-auto bg-success text-white fw-semibold px-2 rounded-pill py-1 px-3 lh-0">
        <small>Hasta 30% OFF</small>
    </span>
</div>
<!-- Search-dp-bar------------------------------------------------- -->
<div class="row g-3">
    <div class="col-12 col-xl">
        <div class="row g-3">
            <div class="col-12 col-xl position-relative">
                <app-dynamic-package-airport-search [(dynamicPackagesSearch)]="dynamicPackageSearch" (destinationChange)="onDestinationChange()"></app-dynamic-package-airport-search>
            </div>
            <div class="col-12 col-md-6 col-xl-auto">
                <app-date-picker-range #datePicker [departDate]="dynamicPackageSearch.departDate" [arrivalDate]="dynamicPackageSearch.arrivalDate" (departDateChange)="onDepartDateChange($event)" (arrivalDateChange)="onArrivalDateChange($event)"></app-date-picker-range>
            </div>
            <div class="col-12 col-md-6 col-xl-auto">
                <app-rooms-counter-panel #roomsCounterPanel [(rooms)]="dynamicPackageSearch.rooms"></app-rooms-counter-panel>
            </div>
        </div>
    </div>
    <!-- Checks -->
    <div class="col-12 order-xl-3">
        <div class="row align-items-start gx-xl-5 mb-xl-2">
            <div class="col-12 col-xl-3">
                <mat-checkbox color="primary" class="ms-n2" [(ngModel)]="dynamicPackageSearch.hotelVariants.isDifferentCity">
                    <div class="ms-n2">Quiero Hotel en otra ciudad</div>
                </mat-checkbox>
                <div *ngIf="isDifferentCity()" class="mb-3">
                    <app-location-search #locationSearch [(formInput)]="dynamicPackageSearch.hotelVariants.location" [placeHolder]="'¿Dónde querés hospedarte? Hotel, Ciudad, País...'" ngDefaultControl>
                    </app-location-search>
                </div>
            </div>
            <div class="col-12 col-xl-auto">
                <mat-checkbox color="primary" class="ms-n2" [(ngModel)]="dynamicPackageSearch.hotelVariants.isDifferentDate">
                    <div class="ms-n2">Quiero Hotel solo por unos días</div>
                </mat-checkbox>
                <div *ngIf="isDifferentDate()" class="mb-3">
                    <app-date-picker-range [departDate]="dynamicPackageSearch.hotelVariants.checkInDate" [arrivalDate]="dynamicPackageSearch.hotelVariants.checkOutDate" (departDateChange)="onVariantCheckInDateChange($event)" (arrivalDateChange)="onVariantCheckOutDateChange($event)" [hasMinDate]="true" [minDate]="dynamicPackageSearch.departDate" [hasMaxDate]="true" [maxDate]="dynamicPackageSearch.arrivalDate">
                    </app-date-picker-range>
                </div>
            </div>
        </div>
    </div>
    <!-- CTA Search -->
    <div class="col-12 col-xl-auto">
        <button mat-flat-button color="accent" class="t-search-button rounded-3 px-4 w-100" (click)="search()">
            Buscar
        </button>
    </div>
</div>