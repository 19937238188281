import { Injectable } from '@angular/core';
import { ErrorManagementService } from './error-management.service';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private onlineStatus = new BehaviorSubject<boolean>(navigator.onLine);
  private offlineErrorMessage: string =
    'Identificamos un error de conexión. Por favor, verifica tu conexión e ingresá nuevamente.';
  private backOnlineMessage: string = 'Se ha restaurado la conexión a la red.';

  constructor(private errorService: ErrorManagementService) {
    fromEvent(window, 'online').subscribe(() => this.updateOnlineStatus(true));
    fromEvent(window, 'offline').subscribe(() =>
      this.updateOnlineStatus(false)
    );

    if (!navigator.onLine) {
      this.errorService.showOfflineError(this.offlineErrorMessage, '');
    }
  }

  private updateOnlineStatus(isOnline: boolean) {
    this.onlineStatus.next(isOnline);
    if (isOnline) {
      this.errorService.showBackOnline(this.backOnlineMessage, '');
    } else {
      this.errorService.showOfflineError(this.offlineErrorMessage, '');
    }
  }

  getNetworkStatus() {
    return this.onlineStatus.asObservable();
  }
}
