import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/authentication/user.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrl: './header-login.component.scss',
})
export class HeaderLoginComponent {
  user$ = this.authService.getUser();

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private legacyRouter: LegacyRouterService
  ) {}

  logOut() {
    window.location.href = '/logout';
  }

  goToMyProfile() {
    this.legacyRouter.redirectToProfile();
  }

  goToLogIn() {
    window.location.href = '/login';
  }

  goToCreateAccount() {
    this.router.navigate(['/create-account']);
  }
}
