export enum TravelType {
    OneWay,
    RoundTrip,
    MultipleFlights
}

export enum CabinType {
  Tourist = 1,
  Business = 2,
}
