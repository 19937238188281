<form (click)="showMobileForm($event)">
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" class="t-location-search_form-field w-100 t-mat-form-field t-hide-bottom-messages">
                <div class="w-100 d-flex align-items-center">
                    <ng-container *ngIf="spinning(); else pinMarker">
                        <i class="fa fa-circle-notch fa-spin text-primary me-3"></i>
                    </ng-container>
                    <ng-template #pinMarker>
                        <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                    </ng-template>
                    <input #inputElement type="text" matInput [formControl]="formInput" [matAutocomplete]="auto" placeholder="{{ placeHolder }}" class="fs-6 fw-semibold text-truncate" (blur)="selectFirstOption()" (focus)="onFocus($event)" />
                    <ng-container *ngIf="formInput.value">
                        <i class="fal fa-times ms-2" style="cursor: pointer;" (click)="formInput.setValue(undefined)"></i>
                    </ng-container>
                </div>
                <!-- Autocomplete -->
                <mat-autocomplete hideSingleSelectionIndicator class="t-location-autocomplete mt-2 rounded-3" #auto="matAutocomplete" [displayWith]="getDisplay" [panelWidth]="!isMobile ? '30rem' : 0" [class]="'mat-elevation-z20'" (optionSelected)="onOptionSelected()">
                    <ng-container *ngFor="let group of groupedRegions">
                        <!-- Autocomplete > Regiion Type -->
                        <mat-option class="t-autocomplete-list d-flex flex-column p-0 overflow-hidden">
                            <div class="ps-4 py-2 bg-secondary bg-opacity-10 fw-bold text-dark">
                                {{ getRegionType(group) }}
                            </div>
                        </mat-option>
                        <!-- Autocomplete > Location -->
                        <mat-option *ngFor="let option of group.locations" [value]="option" class="t-autocomplete-list d-flex flex-column p-0">
                            <div class="t-autocomplete-list_result d-flex gap-3 align-items-center ps-4 pe-3 overflow-hidden border-bottom border-light" matRipple>
                                <i class="fa-regular fa-location-dot fs-5"></i>
                                <span class="fw-semibold lh-sm text-dark text-truncate fs-6">
                                    {{ getDisplay(option) }}
                                </span>
                            </div>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
</form>