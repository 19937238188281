import { Injectable } from '@angular/core';
import { Continent } from '../models/continent.model';

@Injectable({
  providedIn: 'root',
})
export class ContinentService {
  private continents: Continent[] = [
    { code: 0, name: 'África' },
    { code: 1, name: 'América del Norte' },
    { code: 2, name: 'Asia' },
    { code: 3, name: 'Caribe' },
    { code: 4, name: 'Europa' },
    { code: 5, name: 'América Latina' },
    { code: 6, name: 'Oceanía' },
  ];

  getContinents(): Continent[] {
    return this.continents;
  }
}
