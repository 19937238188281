<!-- HOTELS---------------------------------------------------------------- -->
<div class="h5 d-inline-flex fw-normal mb-4 mb-xl-3">Encontrá tu alojamiento perfecto</div>
<div class="row gx-3">

  <div class="col-12 col-xl">
    <div class="row align-items-center g-3">
      <div class="col-12 col-xl">
        <app-location-search 
          #locationSearch 
          [(formInput)]="hotelSearch.destination" 
          [placeHolder]="'¿Dónde querés hospedarte? Hotel, Ciudad, País...'" 
          (formInputChange)="onLocationChange()" 
          ngDefaultControl>
        </app-location-search>
      </div>
      <div class="col-12 col-md col-xl-auto">
        <app-date-picker-range 
          #datePicker 
          [departDate]="hotelSearch.checkInDate" 
          [arrivalDate]="hotelSearch.checkOutDate" 
          (departDateChange)="onCheckInDateChange($event)" 
          (arrivalDateChange)="onCheckOutDateChange($event)" 
          [displayMode]="displayMode"
        ></app-date-picker-range>
      </div>
      <div class="col-12 col-md col-xl-auto">
        <app-rooms-counter-panel #roomsCounterPanel [(rooms)]="hotelSearch.rooms"></app-rooms-counter-panel>
      </div>
    </div>
  </div>

  <div class="col-12 order-xl-3 col-xl-12 mt-4 mb-xl-3">
    <div class="row align-items-center g-3">
      <div class="col-12 col-xl-auto me-lg-3">
        <div class="text-success fw-semibold lh-sm">Hasta 30% de descuento en tu Vuelo + Hotel </div>
      </div>
      <div class="col-12 col-xl-auto">
        <a href="/custom-package" class="text-primary fw-semibold lh-sm">
          <i class="fa-solid fa-magnifying-glass me-1"></i>Buscar en Vuelo + Hotel
        </a>
      </div>
    </div>
  </div>

   <!-- Search CTA -->
  <div class="col-12 col-xl-auto order-xl-2 mt-4 mt-xl-0">
    <button mat-flat-button color="accent" class="t-search-button px-4 w-100" (click)="search()">
      Buscar
    </button>
  </div>
</div>