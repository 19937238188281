<!-- CTA Datepiker Desktop -->
<div class="d-none d-lg-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-date-picker-button" mat-ripple>
    <i class="fa-regular fa-calendar fs-5 text-primary me-3"></i>
    <div class="d-flex align-items-center h-100 fs-6 fw-semibold">
        <button class="bg-transparent p-0 border-0 fw-semibold text-capitalize h-100 flex-shrink-0" [ngbPopover]="datePickerPopover" triggers="manual" #popOverFrom="ngbPopover" [placement]="displayMode === 'inline'? 'bottom' : 'right'" container="body" (click)="togglePopover('from')" [autoClose]="'outside'" [popoverClass]="'t-custom-popover--desktop'">
            <span [ngClass]="{'text-dark': !isSelectingFromDate(), 'text-primary': isSelectingFromDate()}"> {{ getDate(fromDate) | luxonFormat: "short" }}</span>
        </button>
        <span class="mx-1">-</span>
        <button class="bg-transparent p-0 border-0 fw-semibold text-capitalize h-100 flex-shrink-0" [ngbPopover]="datePickerPopover" triggers="manual" #popOverTo="ngbPopover" [placement]="displayMode === 'inline'? 'bottom' : 'right'" container="body" [autoClose]="'outside'" (click)="togglePopover('to')" [popoverClass]="'t-custom-popover--desktop'">
            <span [ngClass]="{'text-dark': !isSelectingToDate(), 'text-primary': isSelectingToDate()}"> {{ getArrivalDate() }}</span>
        </button>
        <span class="mx-1 text-secondary d-lg-none d-xxl-inline-flex">-</span>
        <span class="text-secondary d-lg-none d-xxl-inline-flex flex-shrink-0">{{ getDaysDiff() }} días</span>
    </div>
</div>
<!-- CTA Datepiker Mobile -->
<div class="d-lg-none d-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-date-picker-button" mat-ripple>
    <i class="fa-regular fa-calendar fs-5 text-primary me-3"></i>
    <div class="d-flex align-items-center h-100 fs-6 fw-semibold">
        <button class="bg-transparent p-0 border-0 fw-semibold text-capitalize h-100 flex-shrink-0" (click)="showMobileDatepicker()">
            <span class="text-dark">{{ getDate(fromDate) | luxonFormat: "short" }}</span>
        </button>
        <span class="mx-1">-</span>
        <button class="bg-transparent p-0 border-0 fw-semibold text-capitalize h-100 flex-shrink-0" (click)="showMobileDatepicker()">
            <span class="text-dark"> {{ getDate(toDate) | luxonFormat: "short" }}</span>
        </button>
        <span class="mx-1 text-secondary d-lg-none d-xxl-inline-flex">-</span>
        <span class="text-secondary d-lg-none d-xxl-inline-flex flex-shrink-0">{{ getDaysDiff() }} días</span>
    </div>
</div>

<ng-template #datePickerPopover let-popover="popover">
    <div class="row">
        <div class="popover-datepicker-container" (click)="$event.stopPropagation()">
            <ngb-datepicker (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="range" navigation="arrows" outsideDays="hidden" [minDate]="_minDate" [maxDate]="_maxDate" [startDate]="startDate" (mouseleave)="onMouseLeave()" triggers="manual" class="t-custom-datepicker" [weekdays]="translationWidth"></ngb-datepicker>
        </div>
    </div>
</ng-template>

<ng-template #range let-date let-focused="focused" let-currentMonth="currentMonth">
    <span class="t-custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date">
        {{ date.day }}
    </span>
</ng-template>