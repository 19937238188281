import { FormControl } from "@angular/forms";
import { DateTime } from "luxon";
import { CabinType, TravelType } from "src/app/models/flights/flight-utilities";
import { PassengerSearch } from "src/app/models/passenger-search";

export class FlightSearchLegVM {
  origin = new FormControl();
  destination = new FormControl();
  departDate: Date;
  arrivalDate: Date;
  isOneWay = false;

  constructor() {
    this.departDate = new Date(new Date(Date.now() + 2 * 24 * 60 * 60 * 1000));
    this.arrivalDate = new Date(new Date(Date.now() + 6 * 24 * 60 * 60 * 1000));
  }
}

export class FlightSearchVM {
  simpleLeg = new FlightSearchLegVM();
  legs: FlightSearchLegVM[] = [];
  flexibileDates = new FormControl();
  isMultipleLegSearch = false;
  passengers = {
    adults: 1,
    childrens: 0,
    infants: 0,
    cabinType: CabinType.Tourist,
  } as PassengerSearch;

  private _type = TravelType.RoundTrip;
  get type() {
    return this._type;
  }
  set type(t: TravelType) {
    this._type = t;
    this.isMultipleLegSearch = false;
    if (this._type === TravelType.MultipleFlights)
      this.isMultipleLegSearch = true;
  }

  constructor() {
    const leg = new FlightSearchLegVM();
    leg.isOneWay = true;
    this.legs = [leg];
  }

  getSearchURL() {
    switch (this.type) {
      case TravelType.OneWay:
        return this.getOneWaySearchURL();
      case TravelType.RoundTrip:
        return this.getRoundTripSearchURL();
      case TravelType.MultipleFlights:
        return this.getMultipleFlightsSearchURL();
      default:
        return this.getRoundTripSearchURL();
    }
  }

  addLeg() {
    const lastLeg = this.legs[this.legs.length - 1];
    const leg = new FlightSearchLegVM();

    if (lastLeg && lastLeg.destination) {
      leg.origin.setValue(lastLeg.destination.value);
      leg.departDate = new Date(lastLeg.departDate);
    } else {
      leg.origin.setValue({ code: 'MVD', name: 'Montevideo (MVD)' });
    }

    this.legs.push(leg);
  }

  removeLeg(index: number) {
    this.legs.splice(index, 1);
  }

  getRoundTripSearchURL() {
    // TODO: Revisar que son los parametros que fue el texto

    const nearDates = this.getNearDatesUrl();
    return (
      `${this.simpleLeg?.origin?.value.code}/` +
      `${this.simpleLeg?.destination?.value.code}/` +
      `${this.simpleLeg?.departDate?.toISOString().slice(0, 10)}/` +
      `${this.simpleLeg?.arrivalDate?.toISOString().slice(0, 10)}/` +
      `false/` +
      `${this.passengers?.adults}/` +
      `${this.passengers?.childrens}/` +
      `${this.passengers?.infants}/` +
      `false/` +
      `${this.passengers?.cabinType}/` +
      `4/` +
      `1/` +
      `/` +
      `/` +
      `${nearDates}`
    );
  }

  getOneWaySearchURL() {
    // TODO: Revisar que son los parametros que fue el texto
    const nearDates = this.getNearDatesUrl();
    return (
      `${this.simpleLeg?.origin?.value.code}/` +
      `${this.simpleLeg?.destination?.value.code}/` +
      `${this.simpleLeg?.departDate?.toISOString().slice(0, 10)}/` +
      `false/` +
      `${this.passengers?.adults}/` +
      `${this.passengers?.childrens}/` +
      `${this.passengers?.infants}/` +
      `false/` +
      `${this.passengers?.cabinType}/` +
      `4/` +
      `1/` +
      `/` +
      `/` +
      `${nearDates}`
    );
  }

  getMultipleFlightsSearchURL() {
    // TODO: Revisar que son los parametros que fue el texto
    const url = this.getLegsURL();

    return (
      `${url}` +
      `false/` +
      `${this.passengers?.adults}/` +
      `${this.passengers?.childrens}/` +
      `${this.passengers?.infants}/` +
      `false/` +
      `${this.passengers?.cabinType}/` +
      `4/` +
      `1/` +
      `/` +
      `/` +
      `list`
    );
  }

  getNearDatesUrl(): string {
    if (this.flexibileDates && this.flexibileDates.value == true) {
      return `calendar`;
    }

    return `list`;
  }

  getLegsURL(): string {
    let url = '';

    this.legs.forEach((leg) => {
      url += this.getLegSearchURL(leg);
    });

    return url;
  }

  getLegSearchURL(leg: FlightSearchLegVM) {
    if (leg) {
      return (
        `${leg.origin?.value.code}/` +
        `${leg.destination?.value.code}/` +
        `${leg.departDate?.toISOString().slice(0, 10)}/`
      );
    }

    return '';
  }

  validateSearch(): any {
    if (this.type === TravelType.MultipleFlights) {
      return this.validateMultipleSearch();
    }

    return this.validateSimpleSearch();
  }

  validateMultipleSearch() {
    for (let i = 0; i < this.legs.length; i++) {
      if (!this.legs[i].origin.value || !this.legs[i].destination.value)
        return {
          valid: false,
          message: 'Por favor, seleccioná los orígenes y destinos',
        };
      else if (
        i > 0 &&
        DateTime.fromJSDate(this.legs[i].departDate).diff(
          DateTime.fromJSDate(this.legs[i - 1].departDate),
          'days'
        ).days < 0
      ) {
        return {
          valid: false,
          message: 'Por favor, seleccioná las fechas de salida',
        };} else if (!this.isDepartureDateOnRange(this.legs[i].departDate)) {
          return {
          message: 'La fecha de los tramos no debe ser mayor a dos días a partir de hoy',
          valid: false}
        }
    }
    return {
      valid: true,
      message: '',
    };
  }

  validateSimpleSearch(): any {
    let valid = true;
    let message = '';

    if (this.simpleLeg) {
      let departCode = null;
      if (this.simpleLeg.origin?.value)
        departCode = this.simpleLeg.origin.value.code;
      let arrivalCode = null;
      if (this.simpleLeg.destination?.value)
        arrivalCode = this.simpleLeg.destination.value.code;

      if (this.type === TravelType.RoundTrip) {
        if (!departCode) {
          message = 'Por favor, ingresá tu ciudad de origen';
          valid = false;
        } else if (!arrivalCode) {
          message = 'Por favor, ingresá tu ciudad de destino';
          valid = false;
        } else if (!this.simpleLeg.departDate) {
          message = 'Por favor, seleccioná la fecha de salida';
          valid = false;
        } else if (!this.simpleLeg.arrivalDate) {
          message = 'Por favor, seleccioná la fecha de llegada';
          valid = false;
        } else if (departCode === arrivalCode) {
          message = 'El origen y el destino no pueden ser iguales';
          valid = false;
        } else if (
          !(
            DateTime.fromJSDate(this.simpleLeg.arrivalDate).diff(
              DateTime.fromJSDate(this.simpleLeg.departDate),
              'days'
            ).days >= 0
          )
        ) {
          message = 'La fecha de salida debe ser menor a la de llegada';
          valid = false;
        } else if (!this.isDepartureDateOnRange(this.simpleLeg.departDate)) {
          message =
            'La fecha de salida debe ser mayor a dos días a partir de hoy';
          valid = false;
        } else if (!this.isDepartureDateOnRange(this.simpleLeg.arrivalDate)) {
          message =
            'La fecha de llegada debe ser mayor a dos días a partir de hoy';
          valid = false;
        }
      } else if (this.type === TravelType.OneWay) {
        if (!departCode) {
          message = 'Por favor, ingresá tu ciudad de origen';
          valid = false;
        } else if (!arrivalCode) {
          message = 'Por favor, ingresá tu ciudad de destino';
          valid = false;
        } else if (!this.simpleLeg.departDate) {
          message = 'Por favor, seleccioná la fecha de salida';
          valid = false;
        } else if (departCode === arrivalCode) {
          message = 'El origen y el destino no pueden ser iguales';
          valid = false;
        } else if (!this.isDepartureDateOnRange(this.simpleLeg.departDate)) {
          message =
            'La fecha de salida debe ser mayor a dos días a partir de hoy';
          valid = false;
        }
      }
    } else {
      message = 'Por favor, ingresá tu ciudad de origen y destino';
      valid = false;
    }

    if (valid) {
      if (this.passengers?.adults < this.passengers?.infants) {
        message = 'La cantidad de bebés no puede ser mayor a la de adultos';
        valid = false;
      }
    }

    return {
      valid: valid,
      message: message,
    };
  }
  
  isDepartureDateOnRange(departDate: Date): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const minimumDate = new Date(today);
    minimumDate.setDate(today.getDate() + 2);

    return departDate >= minimumDate;
  }
}