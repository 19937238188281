import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ErrorManagementService {
  constructor(public snackBar: MatSnackBar) {}

  showValidationErrors(message: string, action: string) {
    const snack = this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['t-snackbar', 't-error'],
    });
    return snack;
  }

  showOfflineError(message: string, action: string) {
    const snack = this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['t-snackbar', 't-error'],
    });
    return snack;
  }

  showBackOnline(message: string, action: string) {
    const snack = this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['t-snackbar', 't-success'],
    });
    return snack;
  }
}
