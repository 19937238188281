import { DateTime } from 'luxon';
import { Continent } from '../models/continent.model';

export class InsuranceSearchVM {
  continent: Continent;
  startDate: Date;
  endDate: Date;
  passengers: number;

  constructor() {
    this.continent = { code: 4, name: 'Europa' };
    this.startDate = new Date();
    this.endDate = new Date();
    this.passengers = 1;
  }

  getSearchURL(): string {
    return (
      `${this.startDate.toISOString().slice(0, 10)}/` +
      `${this.endDate.toISOString().slice(0, 10)}/` +
      `${this.passengers}/` +
      `${this.continent.code}/` +
      `/list`
    );
  }

  validateSearch() {
    let valid = true;
    let message = '';

    if (!this.continent) {
      valid = false;
      message = 'Por favor, selecciona un continente';
    } else if (!this.startDate) {
      valid = false;
      message = 'Por favor, seleccione una fecha de salida';
    } else if (!this.endDate) {
      valid = false;
      message = 'Por favor, seleccione una fecha de llegada';
    } else if (
      !(
        DateTime.fromJSDate(this.endDate).diff(
          DateTime.fromJSDate(this.startDate),
          'days'
        ).days >= 0
      )
    ) {
      valid = false;
      message = 'La fecha de salida no puede ser mayor a la fecha de regreso';
    } else if (this.passengers < 1) {
      valid = false;
      message = 'Por favor, selecciona al menos un pasajero';
    } else if (this.passengers > 9) {
      valid = false;
      message = 'No se pueden seleccionar más de 9 pasajeros';
    }

    return { valid, message };
  }
}
