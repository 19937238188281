<div class="row gy-3 gx-lg-5">
  <div class="col-12 col-lg-9">
    <!-- Tramo -->
    <div *ngFor="let leg of flightSearch.legs; let i = index" class="row align-items-center gx-3 mb-3">
      <!--  Tramo > Title mobile -->
      <div class="col-12 d-md-none d-flex align-items-center justify-content-between mb-2">
        <span class="fw-semibold lh-sm py-2"><small>Tramo {{i+1}} </small></span>
        <span class="fw-semibold text-danger lh-sm py-2" *ngIf="showDeleteButton()" (click)="deleteSearch(i)">
          <i class="fa-regular fa-minus me-1"></i><small>Eliminar</small>
        </span>
      </div>
      <!--  Tramo > Leg -->
      <div class="col">
        <app-flight-one-leg [(flightSearchLeg)]="flightSearch.legs[i]" (departDateChange)="onDepartDateChange($event)" [legId]="i" [minDate]="i === 0 ? getMinDate(0) : flightSearch.legs[i - 1].departDate"></app-flight-one-leg>
      </div>
      <!--  Tramo > Delete Leg desktop -->
      <div *ngIf="showDeleteButton()" class="d-none d-md-block col-auto">
        <button mat-stroked-button color="warn" class="t-btn-delete-flight" (click)="deleteSearch(i)">
          <i class="fa-regular fa-xmark fa-lg"></i>
        </button>
      </div>
      <!--  Tramo > Divider mobile -->
      <div class="col-12 d-lg-none">
        <mat-divider class="border-secondary border-opacity-10 mt-3 mb-0"></mat-divider>
      </div>
    </div>

    <!-- + Agregar tramo -->
    <div *ngIf="showAddSearch()" class="row">
      <div class="col-12">
        <button mat-stroked-button color="primary" class="t-btn-add-flight align-self-end d-flex w-100" (click)="addSearch()">
          <i class="fa-solid fa-plus me-1"></i>
          Agregar tramo
        </button>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-3">
    <div class="row align-items-center g-3">
      <!-- Passengers CTA -->
      <div class="col-12">
        <app-passengers-counter-panel #passengerCounterPanel [(passengers)]="flightSearch.passengers"></app-passengers-counter-panel>
      </div>
      <!-- Search CTA -->
      <div class="col-12">
        <button mat-flat-button color="accent" class="t-search-button rounded-3 px-4 w-100" (click)="search()">
          Buscar
        </button>
      </div>
    </div>
  </div>
</div>