import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MaterialsModule } from '../materials/materials.module';
import { HotelSearchBarHomeComponent } from './hotel-search-bar-home/hotel-search-bar-home.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    HotelSearchBarHomeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialsModule,
    FormsModule
  ],
  exports: [
    HotelSearchBarHomeComponent
  ]
})
export class HotelsModule { }
