<div class="h5 d-inline-flex fw-normal mb-3">¿A dónde querés viajar?</div>
<div class="row g-0 gy-4 gy-xl-3">
  <!-- Panel Tabs -->
  <div class="col-12 col-xl-auto order-xl-3">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel" animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start" class="t-flights-searcher-tabs">
      <a mat-tab-link *ngFor="let link of links" (click)="onTravelTypeSelected(link.travelType)" [active]="activeLink == link.travelType">
        {{link.description}}
      </a>
    </nav>
  </div>
<!-- RoundTrip, OneWay, MultipleFlights Searchers----------------------- -->
  <mat-tab-nav-panel #tabPanel class="px-0">
    <ng-container [ngSwitch]="activeLink">
      <app-flight-search-bar [flightSearch]="flightSearch" [travelType]="TravelType.RoundTrip" *ngSwitchCase="TravelType.RoundTrip"></app-flight-search-bar>
      <app-flight-search-bar [flightSearch]="flightSearch" [travelType]="TravelType.OneWay" *ngSwitchCase="TravelType.OneWay"></app-flight-search-bar>
      <app-flight-multiple-search-bar [flightSearch]="flightSearch" *ngSwitchCase="TravelType.MultipleFlights"></app-flight-multiple-search-bar>
    </ng-container>
  </mat-tab-nav-panel>

  <!-- Fechas cercanas Desktop (Only RoundTrip, OneWay) -->
  <div *ngIf="showNearDates()" class="d-none d-xl-block col ps-5 order-last">
    <div class="row align-items-center justify-content-end">
      <div class="col-auto py-1 me-xl-3">
        <div class="fw-semibold text-success">¡Mejorá tu precio si tenes fechas flexibles!</div>
      </div>
      <div class="col-auto py-1">
        <mat-checkbox color="primary" class="ms-n2" (change)="onChangeNearDates($event)">
         <div class="ms-n2">Buscar en fechas cercanas</div> 
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>