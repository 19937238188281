import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import {
  Observable,
  filter,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  Subscription,
} from 'rxjs';
import { AirportSearch, AirportData } from '../models/airport-search.model';
import { Router } from '@angular/router';
import { AirportService } from 'src/app/services/airports/airport.service';

@Component({
  selector: 'app-airport-search-mobile',
  templateUrl: './airport-search-mobile.component.html',
  styleUrl: './airport-search-mobile.component.scss',
})
export class AirportSearchMobileComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  formInput = new FormControl();
  title: string = '';
  iconType: string = 'fa-location-dot';
  groupedAirports: AirportSearch[] = [];
  airports!: Observable<AirportSearch[]>;
  resultNotFound: boolean = false;
  airportsSub: Subscription = new Subscription();
  isDestination: boolean = false;

  @ViewChild('inputElement') inputElement!: ElementRef;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<AirportSearchMobileComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private cityAutocomplete: AirportService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.focus();
  }

  ngOnInit() {
    this.airportsSub = this.createOptionsObservable(this.formInput).subscribe({
      next: (items) => {
        this.resultNotFound = false;
        if (items && items.length > 0) {
          this.groupedAirports = items;
        } else {
          this.resultNotFound = true;
        }
      },
      error: (error) => {
        this.resultNotFound = true;
      },
    });
    this.title = this.data.title;
    this.iconType = this.data.iconType;
    this.isDestination = this.data.isDestination;
  }

  createOptionsObservable(control: FormControl): Observable<AirportSearch[]> {
    return control.valueChanges.pipe(
      filter((text) => typeof text === 'string' || text instanceof String),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((token) => {
        if (token.length < 3) {
          this.groupedAirports = [];
          this.resultNotFound = false;
          return [];
        }

        return this.cityAutocomplete.getCityAirportsOptions(token);
      })
    );
  }

  showNotFoundResult() {
    const inputValue = this.formInput.value;
    return inputValue && inputValue.length > 2 && this.resultNotFound;
  }

  goTo() {
    this.router.navigate(['/promociones']);
  }

  showSuggestion() {
    if (!this.isDestination) return false;

    const inputValue = this.formInput.value;
    return (
      (!this.groupedAirports || this.groupedAirports.length === 0) &&
      (!inputValue || inputValue.length < 3)
    );
  }

  selectItem(airport: AirportData) {
    this.bottomSheetRef.dismiss({
      value: airport,
    });
  }

  getDisplay(elem?: AirportData) {
    if (elem) {
      if (elem.selectedDisplayName) return elem.selectedDisplayName;
      if (elem.name) return elem.name;
    }

    return '';
  }

  getCode(elem?: AirportData) {
    return elem ? elem.code : undefined;
  }

  getCityName(elem?: AirportData) {
    return elem ? elem.name : undefined;
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  spinning() {
    if (
      typeof this.formInput.value === 'string' &&
      this.formInput.value.length &&
      this.formInput.value.length > 2
    )
      return true;
    else return false;
  }

  getTitle() {
    return this.title;
  }

  getLowerTitle() {
    return this.title.toLowerCase();
  }

  ngOnDestroy() {
    if (this.airportsSub) this.airportsSub.unsubscribe();
  }

  hasNearCity(elem?: AirportData) {
    return elem && elem.nearCity;
  }

  getDisplayNearCity(elem?: AirportData) {
    return elem && elem.nearCity ? `cerca de ${elem.displayNearCity}` : '';
  }
}
