<!-- Desktop CTA Mat-Menu -->
<div [matMenuTriggerFor]="passengersMenu" aria-label="Selección pasajeros y cabina" class="d-none d-lg-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-passengers-button" mat-ripple>
  <i class="fa-regular fa-user fs-5  text-primary me-3"></i>
  <span class="fw-semibold text-truncate d-inline-block">{{ getPassangerCount() }} - {{ getCabinType() }}</span>
</div>

<!-- Mobile CTA ButtomSheet -->
<div aria-label="Selección pasajeros y cabina" class="d-flex d-lg-none align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-passengers-button" mat-ripple (click)="openBottomSheet()">
  <i class="fa-regular fa-user fs-5  text-primary me-3"></i>
  <span class="fw-semibold text-truncate d-inline-block">{{ getPassangerCount() }} - {{ getCabinType() }}</span>
</div>

<!-- Dekstop Template - MatMenu -->
<mat-menu  #passengersMenu="matMenu" xPosition="before" class="t-backdrop t-passengers-menu rounded-3 mt-2" hasBackdrop="true">
  <div class="px-3 pt-3 pb-3" (click)="$event.stopPropagation()">
    <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
  </div>
</mat-menu>

<!-- Mobile template - BottomSheet -->
<ng-template #mobileTemplate>
  <!--Mobile template > Nav header -->
  <div class="container t-rooms-counter-panel-mobile_header position-sticky start-0 top-0 w-100 bg-white d-flex align-items-center justify-content-between ps-4 pe-0">
      <div class="h5 fw-normal m-0">Pasajeros</div>
      <button class="p-4 bg-transparent border-0" (click)="closePassengers()">
          <i class="fa-regular fs-2 text-secondary fa-times"></i>
      </button>
  </div>

  <div class="container p-4 pb-0">
      <!--Mobile template > Base Template -->
      <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
      <!--Mobile template > Bottom CTAs -->
      <div class="d-flex gap-3 justify-content-between border-top border-opacity-25 py-3 mt-5">
          <button mat-flat-button class="w-100" color="primary" [disabled]="isApplyButtonDisabled()" (click)="closePassengers()">
              Listo
          </button>
      </div>
  </div>
</ng-template>

<ng-template #commonTemplate>
  <!-- Adultos -->
  <div class="row align-items-center">
    <div class="col">
      <div class="mat-body-2 text-black lh-1 mb-1">Adultos</div>
    </div>
    <div class="col-6">
      <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
        <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="subtract('adult')" [disabled]="isButtonDisabled('adults', 'minus')">
          <i class="fa-regular fa-minus"></i>
        </button>
        <div class="text-center fw-semibold">
          <span>{{ passengers.adults }}</span>
        </div>
        <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="add('adult')" [disabled]="isButtonDisabled('adults', 'plus')">
          <i class="fa-regular fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <!-- Niños -->
  <div class="row align-items-center my-4">
    <div class="col">
      <div class="mat-body-2 text-black lh-1 mb-1">Niños</div>
      <small class="text-secondary">Entre 2 y 11 años</small>
    </div>
    <div class="col-6">
      <div class="t-step-counter d-flex justify-content-between align-items-center  overflow-hidden">
        <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="subtract('children')" [disabled]="isButtonDisabled('children', 'minus')">
          <i class="fa-regular fa-minus"></i>
        </button>
        <div class="text-center fw-semibold">
          <span>{{ passengers.childrens }}</span>
        </div>
        <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="add('children')" [disabled]="isButtonDisabled('children', 'plus')">
          <i class="fa-regular fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <!-- Bebés -->
  <div class="row align-items-center">
    <div class="col">
      <div class="mat-body-2 text-black lh-1 mb-1">Bebés</div>
      <small class="text-secondary">Menores de 2 años</small>
    </div>
    <div class="col-6">
      <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
        <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="subtract('infants')" [disabled]="isButtonDisabled('infant', 'minus')">
          <i class="fa-regular fa-minus"></i>
        </button>
        <div class="text-center fw-semibold">
          <span>{{ passengers.infants }}</span>
        </div>
        <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="add('infants')" [disabled]="isButtonDisabled('infant', 'plus')">
          <i class="fa-regular fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="my-3 border-light"></mat-divider>
  <div class="row">
    <div class="col-12">
      <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
        <span matPrefix>
          <i class="fa-regular fa-seat-airline fs-5 text-primary me-2"></i>
        </span>
        <mat-select [(value)]="passengers.cabinType" disableRipple>
          <mat-option *ngFor="let cabin of getCabinTypes()" [value]="cabin"> {{ getCabinTypeName(cabin) }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>