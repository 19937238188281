<div class="container">
  <!-- Sticky header -->
  <div class="t-airport-search-mobile_header position-sticky start-0 top-0 w-100 bg-white">
    <!-- Sticky header > Title -->
    <div class="d-flex align-items-center justify-content-between ps-3">
      <h5 class="fw-normal m-0">{{ title }}</h5>
      <button class="p-3 bg-transparent border-0" (click)="close($event)">
        <i class="fa-regular fs-2 text-secondary fa-times"></i>
      </button>
    </div>
    <!-- Sticky header > Input -->
    <div class="w-100 p-3 pt-2">
      <form>
        <mat-form-field class="t-airport-search_form-field w-100 t-mat-form-field t-hide-bottom-messages" appearance="outline">
          <div class="w-100 d-flex align-items-center">
            <ng-container #pinMarker *ngIf="spinning(); else pinMarker">
              <i class="fa-regular fa-lg fa-circle-notch fa-spin text-primary me-3"></i>
            </ng-container>
            <ng-template #pinMarker>
              <i class="fa-regular fs-5 text-primary me-3" [ngClass]="iconType"></i>
            </ng-template>
            <input type="text" matInput class="fs-6 fw-semibold text-truncate" #inputElement [formControl]="formInput" [placeholder]="getTitle()" />
            <ng-container *ngIf="formInput.value">
              <i class="fa-regular fa-times ms-2 text-secondary" style="cursor: pointer;" (click)="formInput.setValue(undefined)"></i>
            </ng-container>
          </div>
        </mat-form-field>
      </form>
    </div>
  </div>

  <!-- Content bottom sheet -->
  <div class="t-airport-search-mobile_wrapper">
    <ul class="t-autocomplete-list d-flex flex-column p-0">

      <!-- <ng-container *ngIf="showSuggestion()">
        <li class="t-autocomplete-list_result d-flex gap-3 align-items-center ps-4 p-3 overflow-hidden" (click)="goTo()" matRipple>
          <i class="fa-regular fa-megaphone fs-5"></i>
          <div class="d-grid gap-1">
            <span class="fw-semibold lh-sm text-dark text-truncate fs-6">
              ¿No estás seguro a dónde viajar?
            </span>
            <small class="text-secondary">Conocé nuestro catálogo de ofertas</small>
          </div>
          <i class="ttv-arrow-icon--box ms-auto"></i>
        </li>
      </ng-container>
      
      <ng-container *ngIf="showNotFoundResult()">
        <li class="t-autocomplete-list_result d-flex gap-3 align-items-center ps-4 p-3 overflow-hidden" matRipple>
          <i class="fa-regular fa-location-dot fs-5"></i>
          <span class="fw-semibold fs-6 lh-sm text-dark text-truncate">
            No se encontraron resultados
          </span>
          <i class="t-arrow-icon--box ms-auto"></i>
        </li>
      </ng-container> -->

      <ng-container *ngFor="let group of groupedAirports">
        <li class="t-autocomplete-list_result d-flex gap-3 align-items-center ps-4 p-3 overflow-hidden border-bottom border-light" (click)="selectItem(group.cityAirport)" matRipple>
          <i class="fa-regular fa-location-dot fs-5"></i>
          <div class="w-100 pe-3 text-truncate">
            <span class="fw-semibold fs-6 lh-sm text-dark text-truncate">
              {{ getCityName(group.cityAirport) }}
            </span>
            <small *ngIf="hasNearCity(group.cityAirport)" class="d-block text-secondary text-truncate">
              {{getDisplayNearCity(group.cityAirport) }}
            </small>
          </div>
        </li>
        <ng-container *ngFor="let option of group.airports">
          <li class="t-autocomplete-list_result d-flex gap-3 align-items-center text-dark ps-5 pe-3 overflow-hidden border-bottom border-light" (click)="selectItem(option)">
            <i class="fa-kit fa-flights-product-regular fs-5"></i>
            <div class="w-100 pe-3 text-truncate">
              <span class="fw-semibold fs-6 lh-sm text-truncate">
                {{ getCode(option )}} Aeropuerto {{
                getDisplay(option) }}
              </span>
              <small *ngIf="hasNearCity(option)" class="d-block text-secondary text-truncate"> {{ getDisplayNearCity(option) }}</small>
            </div>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="showSuggestion()">
        <li class="t-autocomplete-list_result d-flex gap-3 align-items-center text-dark ps-4 pe-3 overflow-hidden" (click)="goTo()" matRipple>
          <i class="fa-regular fa-megaphone fs-5"></i>
          <div class="w-100 pe-3 text-truncate">
            <span class="fw-semibold fs-6 lh-sm text-truncate">
              ¿No estás seguro a dónde viajar?
            </span>
            <small class="d-block text-secondary text-truncate">Conocé nuestro catálogo de ofertas.</small>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="showNotFoundResult()">
        <li class="d-flex gap-3 align-items-center text-dark ps-4 p-3 overflow-hidden">
          <i class="fa-regular fa-magnifying-glass fs-5"></i>
          <div class="w-100 pe-3 text-truncate">
            <span class="fw-semibold fs-6 lh-sm text-truncate">
              No encontramos resultados
            </span>
            <small class="d-block text-secondary text-truncate">Volvé a buscar por ciudad o aeropuerto.</small>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>