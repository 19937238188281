import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, Observable, Subscription, switchMap } from 'rxjs';
import { HotelRegionGroup, LocationSearch, RegionType } from './models/location-search.model';
import { LocationService } from 'src/app/services/location.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { LocationSearchMobileComponent } from './location-search-mobile/location-search-mobile.component';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrl: './location-search.component.scss'
})
export class LocationSearchComponent implements OnInit, OnDestroy {
  airportsSub: Subscription = new Subscription();
  @Input() formInput: FormControl = new FormControl();
  @Output() formInputChange = new EventEmitter<FormControl>();
  groupedRegions: HotelRegionGroup[] = []

  @Input() placeHolder: string = "origen";

  isMobile = false;
  private mobileSubscription!: Subscription;

  @ViewChild('inputElement') input!: ElementRef;

  setFocus() {
    if (this.input && this.input.nativeElement) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 0);
    }
  }

  @Output() setFocusChange = new EventEmitter<boolean>();

  constructor(
    private locationAutocomplete: LocationService,
    private env: EnvironmentService,
    private bottomSheet: MatBottomSheet) {

  }

  ngOnInit(): void {
    this.airportsSub = this.createOptionsObservable(this.formInput).subscribe(items => {
      this.groupedRegions = items;
    });

    this.mobileSubscription = this.env.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  spinning() {
    if (typeof this.formInput.value === "string" && this.formInput.value.length && this.formInput.value.length > 2) return true;
    else return false;
  }

  getDisplay(elem?: LocationSearch): string {
    if (elem && elem.name) {
        return elem.name;
    }

    return '';
  }

  getCode(elem?: LocationSearch) {
    return elem ? elem.id : undefined;
  }

  getRegionType(elem?: HotelRegionGroup) {
    if (elem){
      switch(elem.regionType) {
        case RegionType.City:
          return 'Ciudad';
        case RegionType.Hotel:
          return 'Hotel';
        case RegionType.Neighborhood:
          return 'Barrio';
      }
    }

    return '';
  }

  selectFirstOption() {
    if (
      typeof this.formInput.value === "string" &&
      this.groupedRegions &&
      this.groupedRegions[0] &&
      this.groupedRegions[0].locations &&
      this.groupedRegions[0].locations[0]
    )
      this.formInput.setValue(this.groupedRegions[0].locations[0]);
  }

  createOptionsObservable(control: FormControl): Observable<HotelRegionGroup[]> {
    return control.valueChanges.pipe(
      filter(text => {
        return typeof text === "string" || text instanceof String;
      }),
      filter(text => text.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(token => {
        return this.locationAutocomplete.getLocationOptions(token);
      })
    );
  }

  onOptionSelected(){
    this.formInputChange.emit(this.formInput);
  }

  ngOnDestroy(): void {
    if (this.airportsSub) this.airportsSub.unsubscribe();    
    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
  }

  onFocus(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    if (target) {
      target.select();
    }
  }

  showMobileForm(event: MouseEvent) {
    if (this.isMobile) {
      event.preventDefault();
      const locationSearchBS = this.bottomSheet.open(LocationSearchMobileComponent, {
        data: {
          title: this.placeHolder
        },
        autoFocus: false
      });
      locationSearchBS.afterDismissed().subscribe(data => {        
        if (data) {
          this.formInput.setValue(data.value);
          this.formInputChange.emit(this.formInput);
        }
      });
      return false;
    }

    return false
  }
}
