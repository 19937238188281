import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Continent } from 'src/app/models/continent.model';
import { ContinentService } from 'src/app/services/continent.service';
import { InsuranceSearchVM } from '../insurance-search-vm';
import { SearchService } from 'src/app/services/search.service';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';
import { ErrorManagementService } from 'src/app/services/error-management.service';
import { DatePickerRangeComponent } from 'src/app/components/date-picker-range/date-picker-range.component';
import { InsurancePassengerCounterPanelComponent } from 'src/app/components/searchers/insurance-passenger-counter-panel/insurance-passenger-counter-panel.component';

@Component({
  selector: 'app-insurance-search-bar-home',
  templateUrl: './insurance-search-bar-home.component.html',
  styleUrl: './insurance-search-bar-home.component.scss',
})
export class InsuranceSearchBarHomeComponent implements OnInit {
  @Input() displayMode: 'inline' | 'block' = 'inline';
  continents: Continent[] = [];
  insuranceSearch: InsuranceSearchVM = new InsuranceSearchVM();

  @ViewChild('datePicker') datePicker: DatePickerRangeComponent | undefined;
  @ViewChild('passengerCounterPanel') passengerCounterPanel:
    | InsurancePassengerCounterPanelComponent
    | undefined;

  constructor(
    private continentService: ContinentService,
    private searchService: SearchService,
    private legacyRouter: LegacyRouterService,
    private errorService: ErrorManagementService
  ) {}

  ngOnInit(): void {
    this.setInitialValues();
  }

  private setInitialValues() {
    this.continents = this.continentService.getContinents();
    this.insuranceSearch.continent = this.continents[4];

    const search = this.searchService.retrieveInsuranceSearch();
    if (search) {
      if (search.continent && search.continent.code) {
        this.insuranceSearch.continent = this.continents[search.continent.code];
      }
      this.insuranceSearch.startDate = search.startDate;
      this.insuranceSearch.endDate = search.endDate;
      this.insuranceSearch.passengers = search.passengers;
    } else {
      this.insuranceSearch.startDate = new Date();
      this.insuranceSearch.endDate = new Date();
      this.insuranceSearch.endDate.setDate(
        this.insuranceSearch.endDate.getDate() + 1
      );
      this.insuranceSearch.passengers = 1;
    }
  }

  onContinentChange() {
    if (this.datePicker) {
      this.datePicker.setFocus();
    }
  }

  onStartDateChange(date: Date) {
    this.insuranceSearch.startDate = date;
  }

  onEndDateChange(date: Date) {
    this.insuranceSearch.endDate = date;

    if (this.passengerCounterPanel) {
      this.passengerCounterPanel.setFocus();
    }
  }

  search() {
    const validationResult = this.insuranceSearch.validateSearch();

    if (validationResult.valid) {
      this.saveInsuranceSearch();

      this.legacyRouter.redirectToInsurances(
        this.insuranceSearch.getSearchURL()
      );
    } else {
      this.errorService.showValidationErrors(
        validationResult.message,
        'cerrar'
      );
    }
  }

  saveInsuranceSearch() {
    let insurance = {
      continent: this.insuranceSearch.continent,
      startDate: this.insuranceSearch.startDate,
      endDate: this.insuranceSearch.endDate,
      passengers: this.insuranceSearch.passengers,
    } as InsuranceSearchVM;

    this.searchService.saveLastInsuranceSearch(insurance);
  }
}
