import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { inject } from '@angular/core';
import { LastVisitedUrlService } from '../services/last-visited-url.service';

export const logoutGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  const lastVisitedUrlService = inject(LastVisitedUrlService);

  let lastUrl = lastVisitedUrlService.getLastUrlVisited();

  if (!lastUrl || lastUrl === '' || lastUrl === '/logout') {
    lastUrl = '/home';
  }

  authService.logOut();
  router.navigate([lastUrl]);

  return false;
};
