import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import * as settings from "../settings"

@Injectable({
  providedIn: 'root',
})
export class LegacyRouterService {
  private apirUrl: string;

  constructor(private env: EnvironmentService) {
    this.apirUrl = this.env.getValue<string>(settings.LEGACY_TTV_URL);
  }

  redirectToFlightRoundTrip(url: string) {
    const flightUrl = `${this.apirUrl}flights/round-trip/${url}`;
    window.location.href = flightUrl;
  }

  redirectToFlightOneWay(url: string) {
    const flightUrl = `${this.apirUrl}flights/one-way/${url}`;
    window.location.href = flightUrl;
  }

  redirectToMultipleLegs(url: string) {
    const flightUrl = `${this.apirUrl}flights/multi-leg/${url}`;
    window.location.href = flightUrl;
  }

  redirectToHotelSearch(url: string) {
    const hotelUrl = `${this.apirUrl}hotels/list?${url}`;
    window.location.href = hotelUrl;
  }

  redirectToDynamicPackages(url: string) {
    const dynamicPackagesUrl = `${this.apirUrl}custom-package/${url}`;
    window.location.href = dynamicPackagesUrl;
  }

  redirectToInsurances(url: string) {
    const insurancesUrl = `${this.apirUrl}insurances/${url}`;
    window.location.href = insurancesUrl;
  }

  redirectToProfile() {
    const profileUrl = `${this.apirUrl}profile/user`;
    window.location.href = profileUrl;
  }

  redirectToTermsOfUse() {
    const termsOfUseUrl = `${this.apirUrl}terms`;
    window.open(termsOfUseUrl, '_blank');
  }
}
