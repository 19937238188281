<form (click)="showMobileForm($event)">
  <div class="row">
    <div class="col">
      <!-- Origin-Destination Input -->
      <mat-form-field appearance="outline" class="t-airport-search_form-field w-100 t-mat-form-field t-hide-bottom-messages">
        <div class="w-100 d-flex align-items-center">
          <ng-container #pinMarker *ngIf="spinning(); else pinMarker">
            <i class="fa-regular fa-lg fa-circle-notch fa-spin text-primary me-3"></i>
          </ng-container>
          <ng-template #pinMarker>
            <i class="fa-regular fs-5 text-primary me-3" [ngClass]="iconType"></i>
          </ng-template>
          <input #inputElement type="text" matInput [formControl]="formInput" [matAutocomplete]="auto" placeholder="{{ placeHolder }}" class="fs-6 fw-semibold text-truncate" (blur)="selectFirstOption()" (focus)="onFocus($event)" />
          <ng-container *ngIf="formInput.value">
            <i class="d-none d-md-block fa-regular fa-times ms-2 text-secondary" style="cursor: pointer;" (click)="formInput.setValue(undefined)"></i>
          </ng-container>
        </div>
        <!-- Autocomplete -->
        <mat-autocomplete hideSingleSelectionIndicator class="t-airport-autocomplete mt-2 rounded-3" #auto="matAutocomplete" [displayWith]="getDisplay" [panelWidth]="!isMobile ? '370px' : 0" [class]="'mat-elevation-z20'" (optionSelected)="onOptionSelected()">
          <ng-container *ngFor="let group of groupedAirports">
            <!-- Autocomplete > CityAirport -->
            <mat-option [value]="group.cityAirport" class="w-100  t-autocomplete-list d-flex flex-column p-0">
              <div class="t-autocomplete-list_result d-flex gap-3 align-items-center ps-4 p-3 overflow-hidden border-bottom border-light" matRipple>
                <i class="fa-regular fa-location-dot fs-5"></i>
                <div class="w-100 pe-3 text-truncate">
                  <span class="fw-semibold fs-6 lh-sm text-dark text-truncate">
                    {{ getCityName(group.cityAirport) }}
                  </span>
                  <small *ngIf="hasNearCity(group.cityAirport)" class="d-block text-secondary text-truncate">
                    {{getDisplayNearCity(group.cityAirport) }}
                  </small>
                </div>
              </div>
            </mat-option>
            <!-- Autocomplete > Airport -->
            <mat-option *ngFor="let option of group.airports" [value]="option" class="w-100 t-autocomplete-list d-flex flex-column p-0">
              <div class="t-autocomplete-list_result d-flex gap-3 align-items-center text-dark ps-5 pe-3 overflow-hidden border-bottom border-light" matRipple>
                <i class="fa-kit fa-flights-product-regular fs-5"></i>
                <div class="w-100 pe-3 text-truncate">
                  <span class="fw-semibold fs-6 lh-sm text-truncate">
                    {{ getCode(option )}} Aeropuerto {{
                    getDisplay(option) }}
                  </span>
                  <small *ngIf="hasNearCity(option)" class="d-block text-secondary text-truncate"> {{ getDisplayNearCity(option) }}</small>
                </div>
              </div>
            </mat-option>
          </ng-container>

          <ng-container *ngIf="showSuggestion()">
            <mat-option class="w-100 t-autocomplete-list d-flex flex-column p-0">
              <div class="t-autocomplete-list_result d-flex gap-3 align-items-center text-dark ps-4 pe-3 overflow-hidden" (click)="goTo()" matRipple>
                <i class="fa-regular fa-megaphone fs-5"></i>
                <div class="w-100 pe-3 text-truncate">
                  <span class="fw-semibold fs-6 lh-sm text-truncate">
                    ¿No estás seguro a dónde viajar?
                  </span>
                  <small class="d-block text-secondary text-truncate">Conocé nuestro catálogo de ofertas.</small>
                </div>
              </div>
            </mat-option>
          </ng-container>

          <ng-container *ngIf="showNotFoundResult()">
            <mat-option class="w-100 t-autocomplete-list d-flex flex-column p-0 pe-none bg-white">
              <div class="d-flex gap-3 align-items-center text-dark ps-4 p-3 overflow-hidden">
                <i class="fa-regular fa-magnifying-glass fs-5"></i> 
                <div class="w-100 pe-3 text-truncate">
                  <span class="fw-semibold fs-6 lh-sm text-truncate">
                    No encontramos resultados
                  </span>
                  <small class="d-block text-secondary text-truncate">Volvé a buscar por ciudad o aeropuerto.</small>
                </div>
              </div>
            </mat-option>
          </ng-container>

        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>