import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PassengersCounterPanelComponent } from 'src/app/components/searchers/passengers-counter-panel/passengers-counter-panel.component';
import {
  FlightSearchLegModel,
  FlightSearchModel,
} from 'src/app/models/search/flight-search-leg';
import { ErrorManagementService } from 'src/app/services/error-management.service';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';
import { SearchService } from 'src/app/services/search.service';
import { FlightSearchLegVM, FlightSearchVM } from '../flight-search-vm';
import { SearchFocusService } from 'src/app/services/search-focus.service';
import { AirportSearchModel } from 'src/app/models/search/airport-search';

@Component({
  selector: 'app-flight-multiple-search-bar',
  templateUrl: './flight-multiple-search-bar.component.html',
  styleUrl: './flight-multiple-search-bar.component.scss',
})
export class FlightMultipleSearchBarComponent implements OnInit, OnDestroy {
  @Input() displayMode: 'inline' | 'block' = 'inline';
  @Input() flightSearch: FlightSearchVM = new FlightSearchVM();
  private subscription: Subscription = new Subscription();
  @ViewChild('passengerCounterPanel')
  passengerCounterPanel!: PassengersCounterPanelComponent;

  constructor(
    private legacyRouter: LegacyRouterService,
    private searchService: SearchService,
    private errorService: ErrorManagementService,
    private searchFocus: SearchFocusService
  ) {}

  ngOnInit(): void {
    if (this.flightSearch && this.flightSearch.legs) {
    }
    if (this.flightSearch.legs.length < 1) {
      this.flightSearch.addLeg();
    }

    if (this.flightSearch.legs.length < 2) {
      this.flightSearch.addLeg();
    }
  }

  showAddSearch() {
    return this.flightSearch.legs.length < 3;
  }

  addSearch() {
    this.flightSearch.addLeg();
  }

  deleteSearch(index: number) {
    if (index >= 0 && index < this.flightSearch.legs.length) {
      this.flightSearch.removeLeg(index);
    }
  }

  showDeleteButton() {
    return this.flightSearch.legs.length > 2;
  }

  visibleThirdSearch() {
    return this.flightSearch.legs.length > 2;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search() {
    const validationResult = this.flightSearch.validateSearch();
    if (validationResult.valid) {
      this.saveFlightSearch();

      this.legacyRouter.redirectToMultipleLegs(
        this.flightSearch.getSearchURL()
      );
    } else {
      this.errorService.showValidationErrors(
        validationResult.message,
        'cerrar'
      );
    }
  }

  getMinDate(index: number) {
    if (index < 0 && index >= this.flightSearch.legs.length) {
      return new Date();
    }

    return this.flightSearch.legs[index].departDate;
  }

  onDepartDateChange({ id, date }: { id: number; date: Date }) {
    if (id == this.flightSearch.legs.length - 1) {
      this.passengerCounterPanel.setFocus();
    }

    this.flightSearch.legs[id].departDate = date;

    if (id < this.flightSearch.legs.length - 1) {
      let index = id + 1;

      while (index < this.flightSearch.legs.length) {
        if (this.flightSearch.legs[index].departDate < date) {
          this.flightSearch.legs[index].departDate = date;
        }

        index++;
      }

      this.searchFocus.completeFlight({
        id: id + 1,
        formInput: this.flightSearch.legs[id].destination,
      });
    }
  }

  isMultipleDestination() {
    return this.flightSearch.isMultipleLegSearch;
  }

  isOnlyWay() {
    return (
      !this.flightSearch.isMultipleLegSearch &&
      this.flightSearch.simpleLeg.isOneWay
    );
  }

  isRoundTrip() {
    return (
      !this.flightSearch.isMultipleLegSearch &&
      !this.flightSearch.simpleLeg.isOneWay
    );
  }

  saveFlightSearch() {
    console.log('flight', this.flightSearch);
    let simpleLegOrigin = {
      code: '',
      name: '',
    };
    let simpleLegDestination = {
      code: '',
      name: '',
    };

    if (this.flightSearch && this.flightSearch.simpleLeg) {
      if (
        this.flightSearch.simpleLeg.origin &&
        this.flightSearch.simpleLeg.origin.value
      ) {
        simpleLegOrigin = this.flightSearch.simpleLeg.origin.value;
      }
      if (
        this.flightSearch.simpleLeg.destination &&
        this.flightSearch.simpleLeg.destination.value
      ) {
        simpleLegDestination = this.flightSearch.simpleLeg.destination.value;
      }
    }
    let flight = {
      passengers: this.flightSearch.passengers,
      cabinType: this.flightSearch.passengers.cabinType,
      travelType: this.flightSearch.type,
      isMultipleLegSearch: false,
      flexibleDates: false,
      simpleLeg: {
        origin: {
          code: simpleLegOrigin.code,
          name: simpleLegOrigin.name,
        } as AirportSearchModel,
        destination: {
          code: simpleLegDestination.code,
          name: simpleLegDestination.name,
        } as AirportSearchModel,
        departDate: this.flightSearch.simpleLeg.departDate,
        arrivalDate: this.flightSearch.simpleLeg.arrivalDate,
        isOneWay: true,
      },
      legs: this.getLegs(),
    } as FlightSearchModel;

    this.searchService.saveFlightSearch(flight);
  }

  getLegs(): FlightSearchLegModel[] {
    let legs: FlightSearchLegModel[] = [];

    this.flightSearch.legs.forEach((leg) => {
      legs.push({
        origin: {
          code: leg.origin.value.code,
          name: leg.origin.value.name,
        } as AirportSearchModel,
        destination: {
          code: leg.destination.value.code,
          name: leg.destination.value.name,
        } as AirportSearchModel,
        departDate: leg.departDate,
        arrivalDate: leg.arrivalDate,
        isOneWay: true,
      });
    });

    return legs;
  }
}
