import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DynamicPackageSearchVM } from '../../dynamic-packages-vm';
import { AirportSearchComponent } from 'src/app/components/airport-search/airport-search.component';

@Component({
  selector: 'app-dynamic-package-airport-search',
  templateUrl: './dynamic-package-airport-search.component.html',
  styleUrl: './dynamic-package-airport-search.component.scss',
})
export class DynamicPackageAiprortSearchComponent implements OnInit {
  @Input() dynamicPackagesSearch: DynamicPackageSearchVM =
    new DynamicPackageSearchVM();
  @Output() dynamicPackagesSearchChange =
    new EventEmitter<DynamicPackageSearchVM>();
  @Input() displayMode: 'inline' | 'block' = 'inline';
  @Output() destinationChange = new EventEmitter<void>();

  @ViewChild('destinationSearch') destinationAirport!: AirportSearchComponent;
  @ViewChild('originSearch') originAirport!: AirportSearchComponent;

  constructor() {}

  ngOnInit(): void {}

  swapDestinations() {
    const temp = this.dynamicPackagesSearch.origin.value;
    this.dynamicPackagesSearch.origin.setValue(
      this.dynamicPackagesSearch.destination.value
    );
    this.dynamicPackagesSearch.destination.setValue(temp);
  }

  setOriginFocus() {
    this.originAirport.setFocus();
  }

  onOriginChange() {
    this.destinationAirport.setFocus();
  }

  onDestinationChange() {
    this.dynamicPackagesSearchChange.emit(this.dynamicPackagesSearch);
    this.destinationChange.emit();
  }
}
