<div class="row align-items-center g-3">
  <!-- Origin-Destinations Airport inputs -->
  <div class="col-12 col-xl">
    <app-flights-simple-search [(flightSearch)]="flightSearch.simpleLeg"
      (destinationChange)="onDestinationChange()"></app-flights-simple-search>
  </div>
  <!-- Datepicker -->
  <div class="col-12 col-md-6 col-xl-auto">
    <ng-container *ngIf="isOnlyWay()">
      <app-single-date-picker #datePicker [departDate]="flightSearch.simpleLeg.departDate"
        (departDateChange)="onDepartDateChange($event)" [displayMode]="displayMode"></app-single-date-picker>
    </ng-container>
    <ng-container *ngIf="isRoundTrip()">
      <app-date-picker-range #datePickerRange [departDate]="flightSearch.simpleLeg.departDate"
        [arrivalDate]="flightSearch.simpleLeg.arrivalDate" (departDateChange)="onDepartDateChange($event)"
        (arrivalDateChange)="onArrivalDateChange($event)" [displayMode]="displayMode"></app-date-picker-range>
    </ng-container>
  </div>
  <!-- Passengers -->
  <div class="col-12 col-md-6 col-xl-auto">
    <app-passengers-counter-panel #passengerCounterPanel
      [(passengers)]="flightSearch.passengers"></app-passengers-counter-panel>
  </div>
  <!-- Fechas cercanas Mobile -->
  <div class="col-12 d-xl-none">
    <div class="row align-items-center">
      <div class="col-12 py-1">
        <div class="fw-semibold text-success">¡Mejorá tu precio si tenes fechas flexibles!</div>
      </div>
      <div class="col-12 py-1">
        <mat-checkbox color="primary" class="ms-n2" (change)="onChangeNearDates($event)">
          <div class="ms-n2">Buscar en fechas cercanas</div>
        </mat-checkbox>
      </div>
    </div>
  </div>
  <!-- CTA -->
  <div class="col-12 col-md-12 col-xl-auto">
    <button mat-flat-button color="accent" class="t-search-button rounded-3 px-4 w-100" (click)="search()">
      Buscar
    </button>
  </div>
</div>