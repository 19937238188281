import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightSearchBarComponent } from './flight-search-bar/flight-search-bar.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialsModule } from '../materials/materials.module';
import { FlightsSimpleSearchComponent } from './flight-search-bar/flights-simple-search/flights-simple-search.component';
import { PassengersCounterPanelComponent } from '../components/searchers/passengers-counter-panel/passengers-counter-panel.component';
import { FlightSearchBarHomeComponent } from './flight-search-bar-home/flight-search-bar-home.component';
import { FlightMultipleSearchBarComponent } from './flight-multiple-search/flight-multiple-search-bar.component';
import { FlightOneLegComponent } from './flight-one-leg/flight-one-leg.component';

@NgModule({
  declarations: [
    FlightSearchBarHomeComponent,
    FlightSearchBarComponent,
    FlightsSimpleSearchComponent,
    FlightMultipleSearchBarComponent,
    PassengersCounterPanelComponent,
    FlightOneLegComponent,
  ],
  imports: [CommonModule, SharedModule, MaterialsModule],
  exports: [
    FlightSearchBarHomeComponent,
    FlightSearchBarComponent,
    FlightsSimpleSearchComponent,
    FlightMultipleSearchBarComponent,
    PassengersCounterPanelComponent,
    FlightOneLegComponent,
  ],
})
export class FlightsModule {}
