import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import * as settings from '../settings';
import { catchError, map, Observable, of } from 'rxjs';
import { HotelRegionGroup, LocationSearch, RegionType } from '../components/location-search/models/location-search.model';
import { removeAccents } from '../utils/text-utils';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) {
    this.apiUrl = this.environment.getValue<string>(settings.API_URL);
  }

  getLocationOptions(search: string | String): Observable<HotelRegionGroup[]> {
    if (!search) return of([]);
    const searchFormatted = removeAccents(search).toLowerCase();
    if (this.environment.isBrowser)
      return this.http
        .get<LocationSearch[]>(
          `${this.apiUrl}api/autocompletes/destinations/${searchFormatted}`
        )
        .pipe(
          map((items) => {
            let cities: HotelRegionGroup = {
              regionType: RegionType.City,
              locations: [],
            };
            let neighborhood: HotelRegionGroup = {
              regionType: RegionType.Neighborhood,
              locations: [],
            };
            let hotel: HotelRegionGroup = {
              regionType: RegionType.Hotel,
              locations: [],
            };

            items.forEach((item) => {
              if (item.category === 'city') {
                cities.locations.push(item);
              } else if (item.category === 'neighborhood') {
                neighborhood.locations.push(item);
              } else if (item.category === 'hotel') {
                hotel.locations.push(item);
              }
            });

            let result: HotelRegionGroup[] = [];

            if (cities.locations.length > 0) {
              result.push(cities);
            }
            if (neighborhood.locations.length > 0) {
              result.push(neighborhood);
            }
            if (hotel.locations.length > 0) {
              result.push(hotel);
            }

            return result;
          }),
          catchError((err) => {
            return of([]);
          })
        );

    return of([]);
  }
}
