export interface LocationSearch {
    id: string;
    name: string;
    category: string;
    categoryLocalized: string;
  }
  
  export interface HotelRegionGroup {
    regionType: RegionType;
    locations: LocationSearch[];
  }

  export enum RegionType {
    City = 'city',
    Hotel = 'hotel',
    Neighborhood = 'neighborhood'
  }