import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, catchError, retry, take } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import * as settings from '../../settings';
import { Airport, MultipleAirports } from 'src/app/models/airport.model';
import {
  AirportData,
  AirportSearch,
} from 'src/app/components/airport-search/models/airport-search.model';
import { AirportDataResponse } from './models/airport-data-response.model';
import { removeAccents } from 'src/app/utils/text-utils';

@Injectable({
  providedIn: 'root',
})
export class AirportService {
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) {
    this.apiUrl = this.environment.getValue<string>(settings.API_URL);
  }
  getAutocompleteOptions(search: string): Observable<Airport[]> | undefined {
    if (!search) return of([]);
    const searchFormatted = removeAccents(search).toLowerCase();
    if (this.environment.isBrowser)
      return this.http
        .get<Airport[]>(
          `${this.apiUrl}api/AirportsAndRegions/GetAirports?search=${searchFormatted}`
        )
        .pipe(
          map((items) => items.filter((i) => !i.subitem)),
          map((items) => items.slice(0, 5)),
          map((items) => {
            items.map((s) => {
              s.name = s.name.replace('|||', ' cerca de ');
              return s;
            });
            return items;
          })
        );
    return of([]);
  }

  getCityAirportsOptions(search: string | String): Observable<AirportSearch[]> {
    if (!search) return of([]);
    const searchFormatted = removeAccents(search).toLowerCase();
    if (this.environment.isBrowser)
      return this.http
        .get<AirportDataResponse[]>(
          `${this.apiUrl}api/autocompletes/citiesandairports/${searchFormatted}`
        )
        .pipe(
          map((items) => {
            let result: AirportSearch[] = [];
            let pivot: AirportSearch;
            items.forEach((item) => {
              let airportSearch: AirportData = {
                code: item.code,
                name: item.name,
                iataCode: item.iataCode,
                subitem: item.subitem,
                selectedDisplayName: item.selectedDisplayName,
                parentAllAirports: item.parentAllAirports,
                closeRegionId: item.closeRegionId,
                searchForTomorrowEnabled: item.searchForTomorrowEnabled,
                nearCity: false,
                displayNearCity: '',
              };
              if (
                airportSearch.subitem &&
                pivot &&
                pivot.cityAirport.closeRegionId == item.closeRegionId
              ) {
                const match = airportSearch.name.match(/\(([^)]+)\)/);
                if (match && match[1]) {
                  const airportParts = match[1].split('-');
                  airportSearch.selectedDisplayName = airportParts[1]
                    ? airportParts[1]
                    : airportParts[0];
                }

                pivot.airports.push(airportSearch);
              } else {
                let ar = airportSearch.name.split('|||');
                airportSearch.name = ar[0];
                if (ar.length > 1) {
                  airportSearch.displayNearCity = ar[1];
                  airportSearch.nearCity = true;
                }
                airportSearch.name = airportSearch.name.replace(
                  '(Todos los aeropuertos)',
                  ''
                );

                pivot = {
                  cityAirport: airportSearch,
                  airports: [],
                };
                result.push(pivot);
              }
            });

            return result;
          }),
          catchError((err) => {
            return of([]);
          })
        );

    return of([]);
  }

  getAirportByCode(code: string): Observable<Airport> {
    // if (!code) return of();
    if (this.environment.isBrowser)
      return this.http
        .get<Airport>(
          `${this.apiUrl}api/AirportsAndRegions/GetAirportByCode?code=${code}`
        )
        .pipe(take(1), retry(5));

    return of();
  }

  getAirportsByCode(destinations: any): Observable<MultipleAirports> {
    if (!destinations) return of();
    if (this.environment.isBrowser)
      return this.http
        .get<MultipleAirports>(
          `${
            this.apiUrl
          }api/AirportsAndRegions/GetAirportsByCode?${this.buildAirportsQuery(
            destinations
          )}`
        )
        .pipe(take(1), retry(5));

    return of();
  }

  private buildAirportsQuery(destinations: any) {
    let query = 'leg1.origin=' + destinations.leg1.origin;
    query += '&leg1.destination=' + destinations.leg1.destination;
    if (destinations.leg2.origin && destinations.leg2.destination) {
      query += '&leg2.origin=' + destinations.leg2.origin;
      query += '&leg2.destination=' + destinations.leg2.destination;
    }
    if (destinations.leg3.origin && destinations.leg3.destination) {
      query += '&leg3.origin=' + destinations.leg3.origin;
      query += '&leg3.destination=' + destinations.leg3.destination;
    }
    return query;
  }
}
