import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamic-packages-home',
  templateUrl: './dynamic-packages-home.component.html',
  styleUrl: './dynamic-packages-home.component.scss'
})
export class DynamicPackagesHomeComponent {

}
