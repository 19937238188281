<!-- Custom Mobile Datepicker template -->
<div class="overflow-hidden pt-4">
  <div #mobilePicker>
    <ngb-datepicker (dateSelect)="onDateSelection($any($event))" [displayMonths]="monthsToDisplay" [dayTemplate]="single"
      navigation="none" outsideDays="hidden" [minDate]="_minDate" [maxDate]="_maxDate" class="t-custom-datepicker--mobile"
      [weekdays]="translationWidth" [markDisabled]="isDisabled">
    </ngb-datepicker>
  </div>
</div>

<!-- Custom day Datepicker template -->
<ng-template #single let-date let-focused="focused">
  <span class="t-custom-day" [class.focused]="focused" [class.range]="isSelected(date)"
    [attr.data-selected]="isSelected(date) ? 'true' : null">
    {{ date.day }}
  </span>
</ng-template>

<!-- Sticky CTA -->
<div class="position-sticky bottom-0 start-0 bg-white border-top border-secondary border-opacity-10"
  *ngIf="!disableRange">
  <div class="container g-0 d-flex flex-column gap-2 align-items-center">
    <!-- Selected date -->
    <div class="w-100 d-flex gap-1 align-items-center justify-content-center" *ngIf="!disableRange">
      <div class="py-3 flex-shrink-0" (click)="selectDepartureDate()">
        <span class="fs-6 text-capitalize fw-semibold" [ngClass]="{ 'text-primary': selectingDeparture }">{{
          getDepartureDate() | luxonFormat : "long" }}</span>
      </div>
    </div>
    <!-- Bottom actions -->
    <div class="w-100 d-flex gap-3 align-items-center p-3 pt-0">
      <button mat-stroked-button class="w-100 py-2" (click)="disMissBottomSheet()">Cancelar</button>
      <button mat-flat-button color="primary" class="w-100 py-2" (click)="applyDates()" i18n="@@apply">
        Aplicar
      </button>
    </div>
  </div>
</div>