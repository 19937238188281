import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { CabinType } from 'src/app/models/flights/flight-utilities';
import { PassengerSearch } from 'src/app/models/passenger-search';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-passengers-counter-panel',
  templateUrl: './passengers-counter-panel.component.html',
  styleUrls: ['./passengers-counter-panel.component.scss'],
})
export class PassengersCounterPanelComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @Input() passengers: PassengerSearch = {
    adults: 1,
    childrens: 0,
    infants: 0,
    cabinType: CabinType.Tourist,
  };
  @Output() passengersChange = new EventEmitter<PassengerSearch>();
  cabinTypes = Object.keys(CabinType);

  bottomSheetRef: MatBottomSheetRef<any> | null = null;
  @ViewChild('mobileTemplate', { static: false })
  bottomSheetContent!: TemplateRef<any>;
  isMobile: boolean = false;
  private mobileSubscription!: Subscription;

  constructor(
    private env: EnvironmentService,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnDestroy(): void {
    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.mobileSubscription = this.env.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isMobile) {
      const targetElement = event.target as HTMLElement;

      if (this.isClickOnOverlay(targetElement)) {
        const elementBelow = document.elementFromPoint(
          event.clientX,
          event.clientY
        ) as HTMLElement;

        if (elementBelow && elementBelow !== targetElement) {
          this.triggerClickOnElement(elementBelow);
        }
      }
    }
  }

  isClickOnOverlay(target: HTMLElement): boolean {
    return target.classList.contains('cdk-overlay-backdrop');
  }

  triggerClickOnElement(element: HTMLElement) {
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    element.dispatchEvent(event);
  }

  setFocus() {
    if (!this.isMobile) {
      this.menuTrigger.openMenu();
    } else {
      this.openBottomSheet();
    }
  }

  isButtonDisabled(passengerType: string, buttonType: string): boolean {
    if (buttonType === 'minus') {
      if (passengerType === 'adults') {
        return this.passengers.adults <= 1;
      } else if (passengerType === 'children') {
        return this.passengers.childrens < 1;
      } else {
        return this.passengers.infants < 1;
      }
    } else {
      if (passengerType === 'adults') {
        return this.passengers.adults >= 9;
      } else if (passengerType === 'children') {
        return this.passengers.childrens >= 6;
      } else {
        return this.passengers.infants >= 6;
      }
    }
  }

  applyPassengers() {
    this.passengers = {
      adults: this.passengers.adults,
      childrens: this.passengers.childrens,
      infants: this.passengers.infants,
      cabinType: this.passengers.cabinType,
    };
    this.passengersChange.emit(this.passengers);
    this.menuTrigger.closeMenu();
  }

  closePassengers() {
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();
    }
  }

  add(passengerType: string) {
    if (passengerType === 'adult') {
      this.passengers.adults++;
    } else if (passengerType === 'children') {
      this.passengers.childrens++;
    } else if (passengerType === 'infants') {
      this.passengers.infants++;
    }
  }

  subtract(passengerType: string) {
    if (passengerType === 'adult') {
      this.passengers.adults--;
    } else if (passengerType === 'children') {
      this.passengers.childrens--;
    } else if (passengerType === 'infants') {
      this.passengers.infants--;
    }
  }

  isApplyButtonDisabled() {
    return this.passengers.adults === 0;
  }

  getCabinTypeName(cabinType: CabinType) {
    switch (cabinType) {
      case CabinType.Tourist:
        return 'Turista';
      case CabinType.Business:
        return 'Ejecutiva';
    }
  }

  getCabinTypeNameByIndex(index: number) {
    return index.toString();
  }

  getalgo(cabin: string) {
    return cabin;
  }

  getPassangerCount() {
    const passengerCount =
      this.passengers.adults +
      this.passengers.childrens +
      this.passengers.infants;

    if (passengerCount > 1) {
      return `${passengerCount} Pasajeros`;
    } else {
      return `${passengerCount} Pasajero`;
    }
  }

  getCabinType() {
    return this.getCabinTypeName(this.passengers.cabinType);
  }

  getCabinTypes(): CabinType[] {
    return Object.values(CabinType).filter(
      (value) => typeof value === 'number'
    ) as CabinType[];
  }

  openBottomSheet() {
    if (!this.bottomSheetRef) {
      this.bottomSheetRef = this.bottomSheet.open(this.bottomSheetContent);
      this.bottomSheetRef.afterDismissed().subscribe(() => {
        this.bottomSheetRef = null;
      });
    }
  }
}
