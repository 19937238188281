import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AirportSearchComponent } from '../components/airport-search/airport-search.component';
import { MaterialsModule } from '../materials/materials.module';
import { LuxonFormatPipe } from "../pipes/luxon-format.pipe";
import { NgbDatepickerModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerRangeComponent } from '../components/date-picker-range/date-picker-range.component';
import { DatePickerRangeMobileComponent } from '../components/date-picker-range/date-picker-range-mobile/date-picker-range-mobile.component';
import { AirportSearchMobileComponent } from '../components/airport-search/airport-search-mobile/airport-search-mobile.component';
import { SingleDatePickerMobileComponent } from '../components/date-picker/date-picker-mobile/single-date-picker-mobile.component';
import { SingleDatePickerComponent } from '../components/date-picker/single-date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusDirective } from '../directives/focus.directive';
import { LocationSearchComponent } from '../components/location-search/location-search.component';
import { LocationSearchMobileComponent } from '../components/location-search/location-search-mobile/location-search-mobile.component';
import { RoomsCounterPanelComponent } from '../components/searchers/rooms-counter-panel/rooms-counter-panel.component';
import { InsurancePassengerCounterPanelComponent } from '../components/searchers/insurance-passenger-counter-panel/insurance-passenger-counter-panel.component';

@NgModule({
  declarations: [
    AirportSearchComponent,
    AirportSearchMobileComponent,
    LocationSearchComponent,
    LocationSearchMobileComponent,
    DatePickerRangeComponent,
    DatePickerRangeMobileComponent,
    SingleDatePickerComponent,
    SingleDatePickerMobileComponent,
    FocusDirective,
    RoomsCounterPanelComponent,
    InsurancePassengerCounterPanelComponent,
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    ReactiveFormsModule,
    LuxonFormatPipe,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgbModule,
    FormsModule,
  ],
  exports: [
    AirportSearchComponent,
    AirportSearchMobileComponent,
    LocationSearchComponent,
    LocationSearchMobileComponent,
    NgbPopoverModule,
    DatePickerRangeComponent,
    DatePickerRangeMobileComponent,
    SingleDatePickerComponent,
    SingleDatePickerMobileComponent,
    RoomsCounterPanelComponent,
    FormsModule,
    InsurancePassengerCounterPanelComponent,
  ],
  providers: [LuxonFormatPipe],
})
export class SharedModule {}
