import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Product } from '../models/product.model';
import { EnvironmentService } from 'src/app/services/environment.service';
import * as settings from 'src/app/settings';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private offer$: Observable<any> | undefined;
  private apiUrl: string = '';

  constructor(private http: HttpClient, private env: EnvironmentService) {
    this.apiUrl = this.env.getValue<string>(settings.API_URL);
  }

  public Read(): Observable<any> {
    if (!this.offer$) {
      const url = `${this.apiUrl}api/offers/offerswithregionsfiltered?init=0&productType=-1&quantity=110&region=-1`;
      this.offer$ = this.http.get<Product[]>(url).pipe(shareReplay(1));
    }
    return this.offer$;
  }

  OnShowRegionChange = new EventEmitter<any>();

  getProducts(
    filters: { region: string; product: string } = { region: '', product: '' }
  ): Observable<Product[]> {
    return this.Read().pipe(
      map((response) => {
        const filteredProducts = response.offers.filter(
          (product: {
            offerTypeText: string;
            regionNameFormatted: string;
            region: string;
            product: string;
          }) => {
            return (
              (filters.region === ''
                ? true
                : filters.region === product.regionNameFormatted) &&
              (filters.product === ''
                ? true
                : filters.product === product.offerTypeText)
            );
          }
        );
        return filteredProducts;
      })
    );
  }

  ScrollTop() {
    window.scrollTo(0, 0);
  }
}

