import { Component } from '@angular/core';

@Component({
  selector: 'app-hotels-home',
  templateUrl: './hotels-home.component.html',
  styleUrl: './hotels-home.component.scss'
})
export class HotelsHomeComponent {

}
