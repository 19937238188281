import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { HomeOfferRequest } from './models/home-offer-request.model';
import { Product } from 'src/app/pages/promotions/components/offer/models/product.model';
import { EnvironmentService } from './../environment.service';
import * as settings from '../../settings';
import { SearchService } from '../search.service';
import { TravelType } from 'src/app/models/flights/flight-utilities';

@Injectable({
  providedIn: 'root',
})
export class HomeOfferService {
  private offers$: Observable<any> | undefined;
  homeOffer$: any;

  apiUrl: string = '';

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private searchService: SearchService
  ) {
    this.apiUrl = this.env.getValue<string>(settings.API_URL);
  }

  public Read(): Observable<any> {
    if (!this.offers$) {
      const url = `${this.apiUrl}api/offers/getByCityCodesAndPurchases`;
      const body = this.getBody();
      this.offers$ = this.http.post<Product[]>(url, body).pipe(shareReplay(1));
    }
    return this.offers$;
  }

  getBody(): HomeOfferRequest {
    const cityCodes = this.getCityCodes();
    return {
      cityCodes: cityCodes,
      init: 0,
      quantity: 10,
      prioritizeHome: true,
      purchases: [],
      isMobile: false,
    };
  }

  getCityCodes(): string[] {
    const lastFlightSearch = this.searchService.retrieveLastFlightSearch();
    const lastDynamicPackageSearch =
      this.searchService.retrieveLastDynamicPackageSearch();

    let cityCodes: string[] = [];

    if (lastFlightSearch) {
      if (lastFlightSearch.travelType === TravelType.MultipleFlights) {
        const leg = lastFlightSearch.legs[0];
        if (leg && leg.destination) {
          cityCodes.push(leg.destination.code);
        }
      } else {
        if (
          lastFlightSearch.simpleLeg &&
          lastFlightSearch.simpleLeg.destination
        ) {
          cityCodes.push(lastFlightSearch.simpleLeg.destination.code);
        }
      }
    }

    if (lastDynamicPackageSearch && lastDynamicPackageSearch.destination) {
      cityCodes.push(lastDynamicPackageSearch.destination.code);
    }

    return cityCodes;
  }

  getHomeOffers(): Observable<Product[]> {
    return this.Read().pipe(
      map((response) => {
        return response.offers;
      })
    );
  }

  public getHomeOffersCarousel(): Observable<any[]> {
    if (!this.homeOffer$) {
      const url = `${this.apiUrl}api/offers/homebanneroffers`;
      this.homeOffer$ = this.http
        .get<any[]>(url)
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this.homeOffer$;
  }
}
