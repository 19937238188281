import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MaterialsModule } from '../materials/materials.module';
import { FormsModule } from '@angular/forms';
import { InsuranceSearchBarHomeComponent } from './insurance-search-bar-home/insurance-search-bar-home.component';

@NgModule({
  declarations: [InsuranceSearchBarHomeComponent],
  imports: [CommonModule, SharedModule, MaterialsModule, FormsModule],
  exports: [InsuranceSearchBarHomeComponent],
})
export class InsurancesModule {}
