import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, NgOptimizedImage } from '@angular/common';
// Layout Components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ClickOutsideDirective } from './pages/promotions/shared/directives/click-outside.directive';
import { FlightsModule } from './flights/flights.module';
import { SharedModule } from './shared/shared.module';
import { MaterialsModule } from './materials/materials.module';
import { NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerI18nService } from './services/date-picker-i18n.service';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { FiltersPromotionsComponent } from './pages/promotions/components/filters-promotions/filters-promotions.component';
import { PackagesStaticBannerComponent } from './components/static-banners/packages-static-banner/packages-static-banner.component';
import { BetoctocStaticBannerComponent } from './components/static-banners/betoctoc-static-banner/betoctoc-static-banner.component';
import { OfferComponent } from './pages/promotions/components/offer/offer.component';
import { FiltersPromotionsSheetComponent } from './pages/promotions/components/filters-promotions-sheet/filters-promotions-sheet.component';
import { HomeComponent } from './pages/home/home.component';
import { MobileNewsletterComponent } from './components/mobile-newsletter/mobile-newsletter.component';
import { DesktopNewsletterComponent } from './components/desktop-newsletter/desktop-newsletter.component';
import { TravelInfoComponent } from './pages/static/travel-info/travel-info.component';
import { PaymentTypesComponent } from './pages/static/payment-types/payment-types.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OfferService } from './pages/promotions/components/offer/services/offer.service';
import { NewsletterService } from './pages/promotions/shared/services/newsletter/newsletter.service';
import { FlightsHomeComponent } from './flights/containers/flights-home/flights-home.component';
import { SiteMapComponent } from './pages/static/site-map/site-map.component';
import { HotelsModule } from './hotels/hotels.module';
import { HotelsHomeComponent } from './hotels/containers/hotels-home/hotels-home.component';
import { DynamicPackagesHomeComponent } from './dynamic-packages/containers/dynamic-packages-home/dynamic-packages-home.component';
import { DynamicPackagesModule } from './dynamic-packages/dynamic-packages.module';
import { InsurancesModule } from './insurances/insurances.module';
import { InsurancesHomeComponent } from './insurances/containers/insurances-home/insurances-home.component';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { CustomSerializer } from './store/custom-serializer';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { CookieService } from 'ngx-cookie-service';
import { HeaderLoginComponent } from './components/header-login/header-login.component';
import { HeaderLoginMobileComponent } from './components/header-login/header-login-mobile/header-login-mobile.component';
import { LoginModule } from './pages/login/login.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderLoginComponent,
    HeaderLoginMobileComponent,
    PromotionsComponent,
    FooterComponent,
    FiltersPromotionsComponent,
    PackagesStaticBannerComponent,
    BetoctocStaticBannerComponent,
    OfferComponent,
    FiltersPromotionsSheetComponent,
    HomeComponent,
    FlightsHomeComponent,
    HotelsHomeComponent,
    DynamicPackagesHomeComponent,
    InsurancesHomeComponent,
    MobileNewsletterComponent,
    DesktopNewsletterComponent,
    ClickOutsideDirective,
    TravelInfoComponent,
    PaymentTypesComponent,
    SiteMapComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    CommonModule,
    NgxSkeletonLoaderModule,
    FlightsModule,
    HotelsModule,
    DynamicPackagesModule,
    InsurancesModule,
    LoginModule,
    SharedModule,
    MaterialsModule,
    NgbModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
  ],
  providers: [
    OfferService,
    NewsletterService,
    { provide: NgbDatepickerI18n, useClass: DatePickerI18nService },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
