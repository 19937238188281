import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';
import { passwordValidator } from './password-validator.validator';
import { NewUser } from 'src/app/services/authentication/models/new-user.model';
import { ErrorManagementService } from 'src/app/services/error-management.service';
import { LastVisitedUrlService } from 'src/app/services/last-visited-url.service';
import { Router } from '@angular/router';
import { last } from 'rxjs';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.scss',
})
export class CreateAccountComponent implements OnInit {
  hide: boolean = true;
  createAccountSuccesfull: boolean = false;
  loading: boolean = false;

  createAccountForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\s*\S+\s+\S+/),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, passwordValidator]),
  });

  criteria = {
    lowerCase: false,
    upperCase: false,
    number: false,
    length: false,
  };

  @ViewChild('emailInput') emailInput!: ElementRef | undefined;

  constructor(
    private legacyRouter: LegacyRouterService,
    private authService: AuthenticationService,
    private errorService: ErrorManagementService,
    private lastVisitedUrlService: LastVisitedUrlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createAccountForm.get('password')?.valueChanges.subscribe((value) => {
      if (value) this.validatePasswordCriteria(value);
    });
  }

  openTermsOfUse() {
    this.legacyRouter.redirectToTermsOfUse();
  }

  validatePasswordCriteria(value: string) {
    this.criteria.lowerCase = /[a-z]/.test(value);
    this.criteria.upperCase = /[A-Z]/.test(value);
    this.criteria.number = /\d/.test(value);
    this.criteria.length = value.length >= 8 && value.length <= 16;
  }

  createUser() {
    if (!this.loading && this.createAccountForm.valid) {
      const nameParts = this.createAccountForm
        .get('name')
        ?.value?.trim()
        .split(/\s+/);
      if (nameParts && nameParts.length > 1) {
        const firstName = nameParts[0] ? nameParts[0] : '';
        const lastName = nameParts.slice(1).join(' ');
        const newUser = {
          firstName: firstName,
          lastName: lastName,
          email: this.createAccountForm.get('email')?.value,
          password: this.createAccountForm.get('password')?.value,
          newsletter: false,
          department: '',
        } as NewUser;
        this.loading = true;
        this.authService.createUser(newUser).subscribe({
          next: (response) => {
            if (response) {
              this.createAccountSuccesfull = true;
            } else {
              this.errorService.showValidationErrors(
                'Error al crear la cuenta',
                'Error'
              );
            }
            this.loading = false;
          },
          error: (error) => {
            this.errorService.showValidationErrors(error, 'Error');
            this.loading = false;
          },
        });
      }
    }
  }

  tryAgain() {
    this.createAccountForm.get('email')?.setValue('');
    this.createAccountSuccesfull = false;
    if (this.emailInput) {
      this.emailInput.nativeElement.focus();
    }
  }

  goToLastUrl() {
    let lastUrl = this.lastVisitedUrlService.getLastUrlVisited();

    if (lastUrl || lastUrl === '/login' || lastUrl === '/create-account') {
      lastUrl = '/home';
    }
    this.router.navigate([lastUrl]);
  }
}
