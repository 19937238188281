import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(public router: Router) {}

  year: number = new Date().getFullYear();

  show() {
    return (
      this.router.url !== '/login' && this.router.url !== '/create-account'
    );
  }
}
