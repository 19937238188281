import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { LoginComponent } from './pages/login/login/login.component';
import { PaymentTypesComponent } from './pages/static/payment-types/payment-types.component';
import { TravelInfoComponent } from './pages/static/travel-info/travel-info.component';
import { SiteMapComponent } from './pages/static/site-map/site-map.component';
import { FlightsHomeComponent } from './flights/containers/flights-home/flights-home.component';
import { HotelsHomeComponent } from './hotels/containers/hotels-home/hotels-home.component';
import { DynamicPackagesHomeComponent } from './dynamic-packages/containers/dynamic-packages-home/dynamic-packages-home.component';
import { InsurancesHomeComponent } from './insurances/containers/insurances-home/insurances-home.component';
import { lastSearchGuard } from './guards/last-search.guard';
import { logoutGuard } from './guards/logout.guard';
import { CreateAccountComponent } from './pages/login/create-account/create-account.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [lastSearchGuard],
    component: FlightsHomeComponent,
  },
  {
    path: 'home',
    canActivate: [lastSearchGuard],
    component: FlightsHomeComponent,
  },
  { path: 'flights', pathMatch: 'full', component: FlightsHomeComponent },
  { path: 'hotels', pathMatch: 'full', component: HotelsHomeComponent },
  {
    path: 'custom-package',
    pathMatch: 'full',
    component: DynamicPackagesHomeComponent,
  },
  { path: 'insurances', pathMatch: 'full', component: InsurancesHomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'create-account', component: CreateAccountComponent },
  { path: 'promociones', component: PromotionsComponent },
  { path: 'formas-de-pago', component: PaymentTypesComponent },
  { path: 'info-viaje', component: TravelInfoComponent },
  { path: 'mapa-del-sitio/vuelos', component: SiteMapComponent },
  { path: 'mapa-del-sitio/hoteles', component: SiteMapComponent },
  { path: 'mapa-del-sitio/paquetes', component: SiteMapComponent },
  {
    path: 'mapa-del-sitio',
    redirectTo: 'mapa-del-sitio/vuelos',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    canActivate: [logoutGuard],
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
