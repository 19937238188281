<div class="row" *ngIf="offer$ != undefined">
  <div class="col-12 col-sm-6 col-xl-4 mb-5" *ngFor="let offer of offer$ | async;trackBy: trackByFn; let index = index">
    <!-- Skeleton  offer -->
    <ng-container *ngIf="!contentLoaded">
      <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height:'350px',width: '100%','border-radius': '1rem','margin':'0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height:'1rem',width: '70%','border-radius': '1rem','margin': '1rem 0 0.3rem 0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height:'1rem',width: '90%','border-radius': '1rem','margin': '0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
    </ng-container>

    <ng-container *ngIf="contentLoaded">
      <!-- Offer -->
      <a href="{{ offer.url }}" target="_blank" class="offer t-offer-card">
        <div mat-ripple class="t-offer-card_content overflow-hidden position-relative rounded-4 overflow-hidden bg-secondary bg-gradient bg-opacity-25 mb-3 pt-3">
          <img ngSrc="{{ offer.imageUrl }}" alt="{{ offer.descriptionLine }}" onerror="this.src='assets/img/onerror-no-img.svg'" height="350" width="295" fetchpriority="high" />
          <!-- Offer > Messagge Promo -->
          <div *ngIf="mainCampaign">
            <div class="t-offer-card_content_promo-msj mt-n3" *ngIf="mainCampaign.isPromotionActive &&mainCampaign.promotionPercentageDiscount > 0">
              <div class="t-offer-card_content_promo-msj_icon">
                <i class="fak fa-iso-santander text-danger fa-lg"></i>
              </div>
              <small class="text-white">
                {{mainCampaign.promotionPercentageDiscount| currency: "": "": (mainCampaign.promotionPercentageDiscount % 1 == 0? "1.0-0": "1.2-2")}}% descuento extra con Santander. Tope de descuento USD{{mainCampaign.promotionMaxDiscount| currency: "": "": (mainCampaign.promotionMaxDiscount % 1 == 0? "1.0-0": "1.2-2")}}
              </small>
            </div>
          </div>
          <!-- Offer > Price -->
          <div class="t-offer-card_content_price position-absolute start-0 bottom-0 w-100 d-flex align-items-end justify-content-between text-white p-3 pt-5 lh-1">
            <div class="pt-5 pe-3">
              <div class="fs-6 lh-sm fw-semibold text-uppercase" *ngIf="offer.offerTypeCustomText">
                {{ offer.offerTypeCustomText }}
              </div>
            </div>
            <div class="d-block text-end">
              <div class="fs-6 lh-1 text-decoration-line-through mb-2">
                USD {{ offer.priceOld }}
              </div>
              <div class="d-flex align-items-center fs-3 lh-1 fw-semibold m-0">
                <span class="fs-6 me-1 fw-bold">USD</span>{{ offer.priceNew }}
              </div>
            </div>
          </div>
        </div>
        <!-- Offer > Description -->
        <div class="t-offer-card_description">
          <h4 class="text-dark mb-1">{{ offer.title }}</h4>
          <div class="fs-6 text-secondary t-descript">
            {{ offer.descriptionLine }}
          </div>
        </div>
      </a>
    </ng-container>

    <ng-container *ngIf="contentLoaded">
      <!-- Banners -->
      <div *ngIf="(index + 1) % 4 === 0" class="d-flex d-sm-none justify-content-center">
        <div class="col-12 d-grid gap-3 mt-5 mb-4">
          <!-- Bannef Betoctoc -->
          <app-betoctoc-static-banner></app-betoctoc-static-banner>
          <!-- Bannef Packages -->
          <app-packages-static-banner></app-packages-static-banner>
          <!-- Banner Puntos Santander -->
          <div class="t-banner-puntos-soy w-100 bg-white border border-secondary border-opacity-10 rounded-4 p-3 p-sm-4 overflow-hidden">
            <span class="fs-5 fw-bold text-danger lh-1">
              Hasta 18 cuotas sin recargo <br />
              con tarjetas
              <img src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg" width="109" height="19" alt="Santander" class="mb-2" />
            </span>
            <hr class="my-3 w-25" />
            <span class="d-block fw-semibold text-dark mb-2">
              ¿Tenés Puntos Soy Santander?
            </span>
            <small class="text-secondary">
              ¡Usalos para comprar tu viaje <br />y ahorrá!
            </small>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>