import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { RoomSearch } from 'src/app/models/rooms-search';
import { EnvironmentService } from 'src/app/services/environment.service';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rooms-counter-panel',
  templateUrl: './rooms-counter-panel.component.html',
  styleUrls: ['./rooms-counter-panel.component.scss'],
})
export class RoomsCounterPanelComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @Input() rooms: RoomSearch[] = [{ adults: 1, childrens: [] }];
  @Output() roomsChange = new EventEmitter<RoomSearch[]>();

  bottomSheetRef: MatBottomSheetRef<any> | null = null;

  ages: number[] = Array.from({ length: 18 }, (_, i) => i);
  @ViewChild('mobileTemplate', { static: false })
  bottomSheetContent!: TemplateRef<any>;

  subscription: Subscription = new Subscription();
  isMobile: boolean = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isMobile) {
      const targetElement = event.target as HTMLElement;

      if (this.isClickOnOverlay(targetElement)) {
        const elementBelow = document.elementFromPoint(
          event.clientX,
          event.clientY
        ) as HTMLElement;

        if (elementBelow && elementBelow !== targetElement) {
          this.triggerClickOnElement(elementBelow);
        }
      }
    }
  }

  isClickOnOverlay(target: HTMLElement): boolean {
    return target.classList.contains('cdk-overlay-backdrop');
  }

  triggerClickOnElement(element: HTMLElement) {
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    element.dispatchEvent(event);
  }

  constructor(
    private env: EnvironmentService,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription = this.env.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    // TODO: BORRAR ESTO CUANDO VUELVA A FUNCIONAR CON MAS DE UNA HABITACION
    if (this.rooms.length > 1) {
      for (let i = 1; i < this.rooms.length; i++) {
        this.deleteRoom(i);
      }
    }
  }

  openBottomSheet(): void {
    if (!this.bottomSheetRef) {
      this.bottomSheetRef = this.bottomSheet.open(this.bottomSheetContent);
      this.bottomSheetRef.afterDismissed().subscribe(() => {
        this.bottomSheetRef = null;
      });
    }
  }

  setFocus() {
    if (this.isMobile) {
      this.openBottomSheet();
    } else {
      this.menuTrigger.openMenu();
    }
  }

  getGuestCount() {
    const guests = this.rooms.reduce(
      (acc, room) => acc + room.adults + room.childrens.length,
      0
    );

    if (guests === 1) {
      return '1 Huésped';
    } else {
      return `${guests} Huéspedes`;
    }
  }

  getRoomCount() {
    return this.rooms.length > 1
      ? `${this.rooms.length} Habitaciones`
      : '1 Habitación';
  }

  deleteRoom(index: number) {
    if (this.rooms.length > 1 && index < this.rooms.length) {
      this.rooms.splice(index, 1);
      this.roomsChange.emit(this.rooms);
    }
  }

  getRoomIndex(index: number) {
    return index + 1;
  }

  minus(index: number, type: string) {
    if (index >= 0 && index < this.rooms.length) {
      if (type === 'adults') {
        if (this.rooms[index].adults > 1) {
          this.rooms[index].adults--;
        }
      } else {
        if (this.rooms[index].childrens.length > 0) {
          this.rooms[index].childrens.pop();
        }
      }
      this.roomsChange.emit(this.rooms);
    }
  }

  add(index: number, type: string) {
    if (index >= 0 && index < this.rooms.length) {
      if (type === 'adults') {
        if (this.rooms[index].adults < 4) {
          this.rooms[index].adults++;
        }
      } else {
        if (this.rooms[index].childrens.length < 4) {
          this.rooms[index].childrens.push({ age: 0 });
        }
      }
      this.roomsChange.emit(this.rooms);
    }
  }

  roomHasChildrens(room: RoomSearch) {
    return room.childrens.length > 0;
  }

  addRoom() {
    if (this.rooms.length < 5) {
      this.rooms.push({ adults: 1, childrens: [] });
      this.roomsChange.emit(this.rooms);
    }
  }

  closeRooms() {
    if (this.isMobile) {
      if (this.bottomSheetRef) {
        this.bottomSheetRef.dismiss();
      }
    }
  }

  isButtonDisabled(
    index: number,
    buttonType: string,
    guestType: string
  ): boolean {
    if (index >= 0 && index < this.rooms.length) {
      if (guestType === 'adults') {
        return buttonType === 'minus'
          ? this.rooms[index].adults <= 1
          : this.rooms[index].adults >= 4;
      } else {
        return buttonType === 'minus'
          ? this.rooms[index].childrens.length <= 0
          : this.rooms[index].childrens.length >= 4;
      }
    }

    return false;
  }
}
