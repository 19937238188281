<header *ngIf="show()">

  <!-- Header Slider *ngIf="getIsPromotionActive() | async" --> 
  <div id="t-header-carousel" class="carousel slide" data-bs-ride="carousel" *ngIf="getIsPromotionActive() | async" >
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="3000">
        <a href="https://www.toctocviajes.com/promociones" target="_blank">
          <img class="d-md-none" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--mobile-1--v4.svg" style="object-fit:cover;height:45px;max-width:100%;" alt="Promo mobile" />
          <img class="d-none d-md-block" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--desktop-1--v4.svg" style="object-fit:cover;height:55px;max-width:100%;" alt="Promo Desktop" />
        </a>
      </div>
      <div class="carousel-item" data-bs-interval="3000">
        <a href="https://www.toctocviajes.com/promociones" target="_blank">
          <img class="d-md-none" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--mobile-2--v4.svg" style="object-fit:cover;height:45px;max-width:100%;" alt="Promo mobile" />
          <img class="d-none d-md-block" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--desktop-2--v4.svg" style="object-fit:cover;height:55px;max-width:100%;" alt="Promo desktop" />
        </a>
      </div>
    </div>
  </div>
  <!-- END - Header Slider BE -->

  <div class="bg-white">
    <!-- Sup messages -->
    <!-- Mensajes que se muestras encima del Header -->
    <div class="d-md-none py-2">
      <div class="container-sm px-4">
        <a href="https://www.toctocviajes.com/sucursales" mat-ripple class="d-flex align-items-center justify-content-between bg-success bg-opacity-10 rounded-pill text-success px-3 py-2">
          <div class="d-flex align-items-center">
            <i class="fa-regular fa-store fs-6"></i>
            <small class="ms-2">Conocé nuestras sucursales</small>
          </div>
          <i class="fa-regular fa-chevron-right fa-sm"></i>
        </a>
      </div>
    </div>

    <!-- Header Base -->
    <div class="container-lg px-4 px-xl-0 py-3">
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <a href="/">
            <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" alt="Logo TocTocViajes.com" width="110" height="40" />
          </a>
        </div>
        <div class="col-auto d-flex gap-2">
          <!-- Ocultar si hay promo nghide: layout.showPromo -->
          <a mat-button href="https://www.toctocviajes.com/sucursales" target="_blank" class="d-none d-md-flex bg-success bg-opacity-10 rounded-pill text-success px-3">
            <div class="d-flex align-items-center gap-2">
              <i class="fa-light fa-store fs-5"></i>
              <span class="lh-sm">Conocé nuestras sucursales</span>
            </div>
          </a>
          <!-- Cotizador -->
          <a *ngIf="(user$ | async)?.isStaff" href="{{getQuoterUrl()}}" mat-stroked-button class="d-none d-lg-flex rounded-pill border-secondary border-opacity-25 px-3">
            <div class="d-flex align-items-center gap-2">
              <i class="fa-light fa-dollar-sign fs-5"></i>
              <span class="fs-6 fw-normal d-none d-xl-inline-flex lh-sm">Cotizador</span>
            </div>
          </a>
          <!-- Tel -->
          <a href="tel://+59829085555" mat-stroked-button class="rounded-pill border-secondary border-opacity-25 px-3">
            <div class="d-flex align-items-center gap-2">
              <i class="fa-light fa-phone fs-5"></i>
              <span class="fs-6 fw-normal d-none d-xl-inline-flex">2908 55 55</span>
            </div>
          </a>

          <!-- Newsletter - Dropdown Menu -->
          <div class="d-none d-lg-flex">
            <app-desktop-newsletter />
          </div>

          <div class="d-none d-lg-block">
            <app-header-login></app-header-login>
          </div>

          <!-- Sidebar mobile - burger menu -->
          <div class="d-lg-none ms-1">
            <button mat-stroked-button class="rounded-pill" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-label="Open Menú" aria-controls="offcanvasRight">
              <div class="d-flex align-items-center">
                <i class="fa-light fa-bars fs-5"></i>
              </div>
            </button>
          </div>

        </div>
      </div>
    </div>

    <!-- Products Navbar Desktop -->
    <!-- Este componente aparece únicamente en desktop  -->
    <div class="d-none d-lg-block">
      <div class="container-lg px-sm-4 px-xl-0 py-2">
        <div class="row justify-content-start">
          <div class="col-auto">
            <nav>
              <ul class="p-0 m-0 d-inline-flex gap-1">
                <li class="flex-shrink-0">
                  <a href="/flights" target="_self" mat-button class="rounded-pill py-4 px-3" [class.t-active-nav-link]="isActive('/flights') | async">
                    <div class="d-flex align-items-center gap-2">
                      <i class="fa-kit fa-flights-product-light fs-5"></i>
                      <span class="fs-6 flex-shrink-0">Vuelos</span>
                    </div>
                  </a>
                </li>
                <li class="flex-shrink-0">
                  <a href="/hotels" target="_self" mat-button class="rounded-pill py-4 px-3" [class.t-active-nav-link]="isActive('/hotels') | async">
                    <div class="d-flex align-items-center gap-2">
                      <i class="fa-kit fa-hotels-product-light fs-5"></i>
                      <span class="fs-6 flex-shrink-0">Hoteles</span>
                    </div>
                  </a>
                </li>
                <li class="flex-shrink-0">
                  <a href="/custom-package" target="_self" mat-button class="rounded-pill py-4 px-3" [class.t-active-nav-link]="isActive('/custom-package') | async">
                    <div class="d-flex align-items-center gap-2">
                      <i class="fa-kit fa-dp-product-light fs-5"></i>
                      <span class="fs-6 flex-shrink-0">Vuelo + Hotel</span>
                    </div>
                  </a>
                </li>
                <li class="flex-shrink-0">
                  <a href="https://paquetes.toctocviajes.com/" target="_blank" mat-button class="rounded-pill py-4 px-3">
                    <div class="d-flex align-items-center gap-2">
                      <i class="fa-kit fa-packages-product-light fs-5"></i>
                      <span class="fs-6 flex-shrink-0">Paquetes</span>
                    </div>
                  </a>
                </li>
                <li class="flex-shrink-0">
                  <a href="/insurances" target="_self" mat-button class="rounded-pill py-4 px-3" [class.t-active-nav-link]="isActive('/insurances') | async">
                    <div class="d-flex align-items-center gap-2">
                      <i class="fa-kit fa-insurances-product-light fs-5"></i>
                      <span class="fs-6 flex-shrink-0">Asistencia</span>
                    </div>
                  </a>
                </li>
                <mat-divider class="border-secondary border-opacity-10 d-none d-xl-block" [vertical]="true"></mat-divider>
                <li class="flex-shrink-0">
                  <a mat-button class="rounded-pill py-4 px-3" routerLink="/promociones" [routerLinkActive]="['promociones-tab']">
                    <div class="d-flex align-items-center gap-2">
                      <i class="fa-kit fa-promotions-product-light fs-5"></i>
                      <span class="fs-6 flex-shrink-0">Promociones</span>
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <mat-divider class="border-secondary border-opacity-10"></mat-divider> -->
  <!-- Ocultar mat-divider en home -->
</header>

<!-- Sidebar mobile -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header justify-content-end pb-0">
    <button mat-button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
      <i class="fa-light fa-xmark-large fs-5"></i>
    </button>
  </div>

  <div class="offcanvas-body">
    <!-- User things -->
    <app-header-login-mobile></app-header-login-mobile>


    <!-- Access list -->
    <div class="row mx-n3">
      <ul class="t-sidenav-list w-100 px-0 m-0">
        <mat-divider class="my-2"></mat-divider>
        <li>
          <a href="/flights" target="_self" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-flights-product-regular"></i>
            Vuelos
          </a>
        </li>
        <li>
          <a href="/hotels" target="_self" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-hotels-product-regular"></i>
            Hoteles
          </a>
        </li>
        <li>
          <a href="/custom-package" target="_self" class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-dp-product-regular"></i>
            Vuelo + Hotel
          </a>
        </li>
        <li>
          <a href="https://paquetes.toctocviajes.com" target="_blank" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-packages-product-regular"></i>
            Paquetes
          </a>
        </li>
        <li>
          <a href="/insurances" target="_self" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-insurances-product-regular"></i>
            Asistencia
          </a>
        </li>
        <li>
          <a routerLink="/promociones"  mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-promotions-product-regular"></i>
            Promociones
          </a>
        </li>
        <mat-divider class="my-2"></mat-divider>
      </ul>
    </div>

    <!-- Banner Call-Center -->
    <a mat-ripple href="tel://+59829085555" class="d-flex position-relative align-items-end justify-content-between border border-dark border-opacity-10 rounded-4 text-dark overflow-hidden my-3">
      <div class="p-3 pe-0">
        <div class="fs-5 fw-semibold lh-1 mb-2">Estamos para ayudarte</div>
        <small class="d-block text-secondary mb-4">
          De Lunes a Viernes de 9 a 19hs.<br>Sábados, domingos y feriados <br> de 9 a 17hs.
        </small>
        <div class="d-flex fw-semibold align-items-center">
          <i class="fa-regular fa-phone fs-4 me-2"></i>
          <span class="fs-4 fw-normal lh-sm m-0">2908 55 55</span>
        </div>
      </div>
      <img class="position-absolute bottom-0 end-0" src="https://assets.toctocviajes.com/img/static-banners/img-banner-call-center-agent.webp" loading="lazy" width="140" alt="Estamos para ayudarte" />
    </a>

    <!-- Social media -->
    <div class="d-flex gap-3 justify-content-center mt-4">
      <a href="https://www.instagram.com/toctocviajes/" target="_blank" mat-mini-fab aria-label="Seguinos en Instagram" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-instagram-white.svg" width="42" height="42" alt="Instagram" />
      </a>
      <a href="https://www.facebook.com/toctocviajes" target="_blank" mat-mini-fab aria-label="Seguinos en Facebook" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-facebook-white.svg" width="42" height="42" alt="Facebook" />
      </a>
      <a href="https://www.linkedin.com/company/toctocviajes.com" target="_blank" mat-mini-fab aria-label="Seguinos en LinkedIn" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-linkedin-white.svg" width="42" height="42" alt="Linkedin" />
      </a>
      <a href="https://twitter.com/toctocviajes" target="_blank" mat-mini-fab aria-label="Seguinos en Twitter" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-x-twitter-white.svg" width="42" height="42" alt="X" />
      </a>
    </div>
  </div>
</div>