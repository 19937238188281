import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FlightSearchVM } from '../flight-search-vm';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';
import { SearchService } from 'src/app/services/search.service';
import { FlightSearchModel } from 'src/app/models/search/flight-search-leg';
import { TravelType } from 'src/app/models/flights/flight-utilities';
import { ErrorManagementService } from 'src/app/services/error-management.service';
import { DatePickerRangeComponent } from 'src/app/components/date-picker-range/date-picker-range.component';
import { PassengersCounterPanelComponent } from 'src/app/components/searchers/passengers-counter-panel/passengers-counter-panel.component';
import { SingleDatePickerComponent } from 'src/app/components/date-picker/single-date-picker.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AirportSearchModel } from 'src/app/models/search/airport-search';

@Component({
  selector: 'app-flight-search-bar',
  templateUrl: './flight-search-bar.component.html',
  styleUrl: './flight-search-bar.component.scss',
})
export class FlightSearchBarComponent implements OnInit, OnDestroy {
  @Input() displayMode: 'inline' | 'block' = 'inline';
  @Input() flightSearch: FlightSearchVM = new FlightSearchVM();
  @Input() travelType: TravelType = TravelType.OneWay;

  @ViewChild('datePickerRange') datePickerRange:
    | DatePickerRangeComponent
    | undefined;
  @ViewChild('datePicker') datePicker: SingleDatePickerComponent | undefined;
  @ViewChild('passengerCounterPanel')
  passengerCounterPanel!: PassengersCounterPanelComponent;

  constructor(
    private legacyRouter: LegacyRouterService,
    private searchService: SearchService,
    private errorService: ErrorManagementService
  ) {}

  ngOnInit(): void {}

  onDestinationChange() {
    if (this.flightSearch.type == TravelType.OneWay && this.datePicker) {
      this.datePicker.setFocus();
    } else if (
      this.flightSearch.type == TravelType.RoundTrip &&
      this.datePickerRange
    ) {
      this.datePickerRange.setFocus();
    }
  }

  ngOnDestroy(): void {}

  search() {
    const validationResult = this.flightSearch.validateSearch();
    if (validationResult.valid) {
      this.saveFlightSearch();

      if (!this.flightSearch.isMultipleLegSearch) {
        if (this.flightSearch.simpleLeg.isOneWay) {
          this.legacyRouter.redirectToFlightOneWay(
            this.flightSearch.getSearchURL()
          );
        } else {
          this.legacyRouter.redirectToFlightRoundTrip(
            this.flightSearch.getSearchURL()
          );
        }
      }
    } else {
      this.errorService.showValidationErrors(
        validationResult.message,
        'cerrar'
      );
    }
  }

  isMultipleDestination() {
    return this.flightSearch.isMultipleLegSearch;
  }

  isOnlyWay() {
    return (
      !this.flightSearch.isMultipleLegSearch &&
      this.flightSearch.simpleLeg.isOneWay
    );
  }

  isRoundTrip() {
    return (
      !this.flightSearch.isMultipleLegSearch &&
      !this.flightSearch.simpleLeg.isOneWay
    );
  }

  onChangeNearDates(event: MatCheckboxChange) {
    this.flightSearch.flexibileDates.setValue(event.checked)
  }

  onDepartDateChange(date: Date) {
    this.flightSearch.simpleLeg.departDate = date;
    if (this.flightSearch.type == TravelType.OneWay)
      this.passengerCounterPanel.setFocus();
  }

  onArrivalDateChange(date: Date) {
    this.flightSearch.simpleLeg.arrivalDate = date;
    this.passengerCounterPanel.setFocus();
  }

  saveFlightSearch() {
    let flight = {
      passengers: this.flightSearch.passengers,
      cabinType: this.flightSearch.passengers.cabinType,
      travelType: this.flightSearch.type,
      isMultipleLegSearch: false,
      flexibleDates: false,
      simpleLeg: {
        origin: {
          code: this.flightSearch.simpleLeg.origin.value.code,
          name: this.flightSearch.simpleLeg.origin.value.name,
        } as AirportSearchModel,
        destination: {
          code: this.flightSearch.simpleLeg.destination.value.code,
          name: this.flightSearch.simpleLeg.destination.value.name,
        } as AirportSearchModel,
        departDate: this.flightSearch.simpleLeg.departDate,
        arrivalDate: this.flightSearch.simpleLeg.arrivalDate,
        isOneWay: true,
      },
      legs: [],
    } as FlightSearchModel;

    this.searchService.saveFlightSearch(flight);
  }
}
