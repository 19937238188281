import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { LocalStorageService } from '../local-storage.service';
import { AuthorizationData } from 'src/app/models/authentication/authorization-data.model';
import * as settings from '../../settings';
import { AuthorizationDataResponse } from './models/authorizarion-data-response.model';
import { User } from 'src/app/models/authentication/user.model';
import { NewUser } from './models/new-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private apiUrl: string = '';
  private key: string = 'mlViajes.authorizationData';
  private userSubject: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  public user$: Observable<User | null> = this.userSubject.asObservable();
  private user: User | null = null;

  constructor(
    private env: EnvironmentService,
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) {
    this.apiUrl = this.env.getValue<string>(settings.API_URL);

    const storedUser = this.localStorage.get(this.key);
    this.userSubject = new BehaviorSubject<User | null>(
      storedUser
        ? {
            firstName: storedUser.firstName,
            lastName: storedUser.lastName,
            email: storedUser.email,
            isStaff: storedUser.isStaff,
          }
        : null
    );
    this.user$ = this.userSubject.asObservable();
  }

  logIn(username: string, password: string): Observable<boolean> {
    const data = `grant_type=password&username=${username}&password=${password}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post<AuthorizationDataResponse>(`${this.apiUrl}token`, data, {
        headers,
      })
      .pipe(
        tap((response: AuthorizationDataResponse) => {
          const authData: AuthorizationData = {
            token: response.access_token,
            userName: response.firstName,
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            newsletter: response.newsletter,
            hasTraceabilityNotification: response.hasTraceabilityNotification,
            isStaff: response.isStaff === 'true',
          };

          this.setAuthorizationData(authData);

          const user = {
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            isStaff: response.isStaff === 'true',
          } as User;

          this.userSubject.next(user);
        }),
        map(() => true),
        catchError((error) => {
          this.logOut();
          return throwError(() => {
            if (error && error.error && error.error.error_description) {
              return error.error.error_description;
            } else {
              return 'Error al iniciar sesión';
            }
          });
        })
      );
  }

  isLoggedIn(): boolean {
    const authData = this.localStorage.get(this.key);
    if (authData) {
      this.userSubject.next({
        firstName: authData.firstName,
        lastName: authData.lastName,
        email: authData.email,
        isStaff: authData.isStaff,
      });

      return true;
    } else {
      this.userSubject.next(null);
    }

    return false;
  }

  getUser(): Observable<User | null> {
    return this.user$;
  }

  logOut() {
    this.localStorage.remove(this.key);
    this.localStorage.removeCookie(this.key);

    this.userSubject.next(null);
  }

  private setAuthorizationData(authData: AuthorizationData) {
    this.localStorage.set(this.key, authData);
    this.localStorage.setCookie(this.key, authData);
  }

  createUser(newUser: NewUser): Observable<boolean> {
    return this.http.post(`${this.apiUrl}api/users/createuser`, newUser).pipe(
      map(() => true),
      catchError((error) => {
        return throwError(() => {
          if (error && error.error) {
            return error.error;
          } else {
            return 'Error al crear la cuenta';
          }
        });
      })
    );
  }

  recoveryPassword(email: string): Observable<boolean> {
    return this.http
      .get(`${this.apiUrl}api/users/forgotpassword?userName=${email}`)
      .pipe(
        map(() => true),
        catchError((error) => {
          return throwError(() => {
            if (error && error.error) {
              return error.error;
            } else {
              return 'Error al recuperar la contraseña';
            }
          });
        })
      );
  }
}
