import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchFocusService {

  constructor() { }
  
  private destinationSelected = new Subject<void>();

  private flightSelected = new Subject<{ id: number, formInput: FormControl }>();
  
  destinationSelected$ = this.destinationSelected.asObservable();

  flightSelected$ = this.flightSelected.asObservable();

  completeDestination() {
    this.destinationSelected.next();
  }

  completeFlight({ id, formInput} : { id: number, formInput: FormControl }) {
    this.flightSelected.next({ id, formInput });
  }
}
