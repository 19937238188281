import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HotelSearchVM } from '../hotel-search-vm';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';
import { ErrorManagementService } from 'src/app/services/error-management.service';
import { HotelSearchModel } from 'src/app/models/search/hotel-search';
import { SearchService } from 'src/app/services/search.service';
import {
  ChildSearchModel,
  RoomSearchModel,
} from 'src/app/models/search/room-search';
import { DatePickerRangeComponent } from 'src/app/components/date-picker-range/date-picker-range.component';
import { RoomsCounterPanelComponent } from 'src/app/components/searchers/rooms-counter-panel/rooms-counter-panel.component';

@Component({
  selector: 'app-hotel-search-bar-home',
  templateUrl: './hotel-search-bar-home.component.html',
  styleUrl: './hotel-search-bar-home.component.scss',
})
export class HotelSearchBarHomeComponent implements OnInit {
  @Input() displayMode: 'inline' | 'block' = 'inline';
  hotelSearch: HotelSearchVM = new HotelSearchVM();

  @ViewChild('datePicker') datePicker: DatePickerRangeComponent | undefined;
  @ViewChild('roomsCounterPanel') roomsCounterPanel:
    | RoomsCounterPanelComponent
    | undefined;
  constructor(
    private legacyRouter: LegacyRouterService,
    private searchService: SearchService,
    private errorService: ErrorManagementService
  ) {}

  ngOnInit(): void {
    this.setInitialValues();
  }

  setInitialValues() {
    const search = this.searchService.retrieveLastHotelSearch();

    if (search) {
      this.hotelSearch.destination.setValue(search.destination);
      this.hotelSearch.checkInDate = search.checkInDate;
      this.hotelSearch.checkOutDate = search.checkOutDate;
      this.hotelSearch.rooms = search.rooms.map((room) => {
        return {
          adults: room.adults,
          childrens: room.childrens.map(
            (child) => ({ age: child.age } as ChildSearchModel)
          ),
        } as RoomSearchModel;
      });
    }
  }

  onCheckInDateChange(date: Date) {
    this.hotelSearch.checkInDate = date;
  }

  onCheckOutDateChange(date: Date) {
    this.hotelSearch.checkOutDate = date;

    if (this.roomsCounterPanel) {
      this.roomsCounterPanel.setFocus();
    }
  }

  onLocationChange() {
    if (this.datePicker) {
      this.datePicker.setFocus();
    }
  }

  search() {
    const validationResult = this.hotelSearch.validateSearch();

    if (validationResult.valid) {
      this.saveHotelSearch();

      this.legacyRouter.redirectToHotelSearch(this.hotelSearch.getSearchURL());
    } else {
      this.errorService.showValidationErrors(
        validationResult.message,
        'cerrar'
      );
    }
  }

  saveHotelSearch() {
    let hotel = {
      destination: {
        id: this.hotelSearch.destination.value.id,
        name: this.hotelSearch.destination.value.name,
      },
      checkInDate: this.hotelSearch.checkInDate,
      checkOutDate: this.hotelSearch.checkOutDate,
      rooms: this.getRooms(),
    } as HotelSearchModel;

    this.searchService.saveHotelSearch(hotel);
  }

  getRooms(): RoomSearchModel[] {
    return this.hotelSearch.rooms.map((room) => {
      return {
        adults: room.adults,
        childrens: room.childrens.map(
          (child) => ({ age: child.age } as ChildSearchModel)
        ),
      } as RoomSearchModel;
    });
  }
}
