import { FormControl } from "@angular/forms";
import { RoomSearch } from "../models/rooms-search";
import { isStayWithinRange } from '../utils/date-utils';

export class HotelSearchVM {
  destination = new FormControl();
  checkInDate: Date;
  checkOutDate: Date;
  rooms: RoomSearch[];

  constructor() {
    this.checkInDate = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
    this.checkOutDate = new Date(Date.now() + 6 * 24 * 60 * 60 * 1000);
    this.rooms = [{ adults: 1, childrens: [] }];
  }

  getSearchURL() {
    const checkIn = this.getCheckInDate();
    const checkOut = this.getCheckOutDate();
    const adults = this.getAdults();
    const childrens = this.getChildrens();
    const destination = this.getDestination();
    const destinationId = this.getDestinationId();
    const searchTrigger = this.getSearchTrigger();

    return (
      `${checkIn}&` +
      `${checkOut}&` +
      `${adults}&` +
      `${childrens}&` +
      `${destination}&` +
      `${destinationId}&` +
      `${searchTrigger}`
    );
  }

  private getCheckInDate() {
    return `checkinDate=${this.checkInDate.toISOString().slice(0, 10)}`;
  }

  private getCheckOutDate() {
    return `checkoutDate=${this.checkOutDate.toISOString().slice(0, 10)}`;
  }

  private getAdults() {
    let adults = 'adults=';

    adults += this.rooms.map((room) => room.adults).join(',');

    return adults;
  }

  private getChildrens() {
    let children = 'children=';

    children += this.rooms
      .map((room) => {
        if (room.childrens.length > 0) {
          const childs = room.childrens.map((child) => child.age).join('_');
          return `${room.childrens.length}_${childs}`;
        } else {
          return '0_0';
        }
      })
      .join(',');

    return children;
  }

  private getDestination() {
    const encodedDestination = encodeURI(this.destination.value.name);
    return `destination=${encodedDestination}`;
  }

  private getDestinationId() {
    return `destinationId=${this.destination.value.id}`;
  }

  private getSearchTrigger() {
    return `searchTrigger=1`;
  }

  public validateSearch(): any {
    let valid = true;
    let message = '';
    if (
      !this.destination.value ||
      !(this.destination.value.id || this.destination.value.nameLong)
    ) {
      message = 'Ingresá el destino u hotel de tu interés';
      valid = false;
    } else if (!this.checkInDate) {
      message = 'Seleccioná la fecha de check in';
      valid = false;
    } else if (!this.checkOutDate) {
      message = 'Seleccioná la fecha de check out';
      valid = false;
    } else if (this.checkInDate > this.checkOutDate) {
      message = 'La fecha de check in debe ser menor a la de check out';
      valid = false;
    } else if (!isStayWithinRange(this.checkInDate, this.checkOutDate, 30)) {
      message = 'No es posible realizar reservas de mas de 30 días en hoteles.';
      valid = false;
    }
    return {
      valid: valid,
      message: message,
    };
  }
}