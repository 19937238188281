import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DynamicPackageSearchVM } from '../dynamic-packages-vm';
import { LegacyRouterService } from 'src/app/services/legacy-router.service';
import { ErrorManagementService } from 'src/app/services/error-management.service';
import { SearchService } from 'src/app/services/search.service';
import {
  ChildSearchModel,
  RoomSearchModel,
} from 'src/app/models/search/room-search';
import {
  DynamicPackageSearchHotelModel,
  DynamicPackageSearchModel,
} from 'src/app/models/search/dynamic-package-search';
import { AirportSearchModel } from 'src/app/models/search/airport-search';
import { LocationSearchModel } from 'src/app/models/search/location-search';
import { DatePickerRangeComponent } from 'src/app/components/date-picker-range/date-picker-range.component';
import { RoomsCounterPanelComponent } from 'src/app/components/searchers/rooms-counter-panel/rooms-counter-panel.component';

@Component({
  selector: 'app-dynamic-packages-bar-home',
  templateUrl: './dynamic-packages-bar-home.component.html',
  styleUrl: './dynamic-packages-bar-home.component.scss',
})
export class DynamicPackagesBarHomeComponent implements OnInit {
  @Input() displayMode: 'inline' | 'block' = 'inline';
  dynamicPackageSearch: DynamicPackageSearchVM = new DynamicPackageSearchVM();

  @ViewChild('datePicker') datePicker: DatePickerRangeComponent | undefined;
  @ViewChild('roomsCounterPanel') roomsCounterPanel:
    | RoomsCounterPanelComponent
    | undefined;

  constructor(
    private legacyRouter: LegacyRouterService,
    private searchService: SearchService,
    private errorService: ErrorManagementService
  ) {}

  ngOnInit(): void {
    this.setInitialValues();
  }

  setInitialValues() {
    const search = this.searchService.retrieveLastDynamicPackageSearch();
    if (search) {
      this.dynamicPackageSearch.origin.setValue(search.origin);
      this.dynamicPackageSearch.destination.setValue(search.destination);
      this.dynamicPackageSearch.departDate = search.departDate;
      this.dynamicPackageSearch.arrivalDate = search.arrivalDate;
      this.dynamicPackageSearch.rooms = search.rooms.map((room) => {
        return {
          adults: room.adults,
          childrens: room.childrens.map(
            (child) => ({ age: child.age } as ChildSearchModel)
          ),
        } as RoomSearchModel;
      });
      this.dynamicPackageSearch.hotelVariants.isDifferentCity =
        search.hotelVariants.isDifferentCity;
      this.dynamicPackageSearch.hotelVariants.location.setValue(
        search.hotelVariants.destination
      );
      this.dynamicPackageSearch.hotelVariants.isDifferentDate =
        search.hotelVariants.isDifferentDate;
      this.dynamicPackageSearch.hotelVariants.checkInDate =
        search.hotelVariants.checkInDate;
      this.dynamicPackageSearch.hotelVariants.checkOutDate =
        search.hotelVariants.checkOutDate;
    } else {
      this.dynamicPackageSearch.origin.setValue({
        code: 'MVD',
        name: 'Montevideo (MVD)',
      });
    }

    this.checkHotelVariantsCheckInDate();
    this.checkHotelVariantsCheckOutDate();
  }

  onDestinationChange() {
    if (this.datePicker) {
      this.datePicker.setFocus();
    }
  }

  isDifferentCity() {
    return this.dynamicPackageSearch.hotelVariants.isDifferentCity;
  }

  isDifferentDate() {
    return this.dynamicPackageSearch.hotelVariants.isDifferentDate;
  }

  onDepartDateChange(date: Date) {
    this.dynamicPackageSearch.departDate = date;

    this.checkHotelVariantsCheckInDate();
  }

  private checkHotelVariantsCheckInDate() {
    if (
      this.dynamicPackageSearch.hotelVariants.checkInDate <
        this.dynamicPackageSearch.departDate ||
      this.dynamicPackageSearch.hotelVariants.checkInDate >
        this.dynamicPackageSearch.arrivalDate
    ) {
      this.dynamicPackageSearch.hotelVariants.checkInDate =
        this.dynamicPackageSearch.departDate;
    }
  }

  onArrivalDateChange(date: Date) {
    this.dynamicPackageSearch.arrivalDate = date;

    this.checkHotelVariantsCheckOutDate();

    if (this.roomsCounterPanel) {
      this.roomsCounterPanel.setFocus();
    }
  }

  private checkHotelVariantsCheckOutDate() {
    if (
      this.dynamicPackageSearch.hotelVariants.checkOutDate <
        this.dynamicPackageSearch.departDate ||
      this.dynamicPackageSearch.hotelVariants.checkOutDate >
        this.dynamicPackageSearch.arrivalDate
    ) {
      this.dynamicPackageSearch.hotelVariants.checkOutDate =
        this.dynamicPackageSearch.arrivalDate;
    }
  }

  onVariantCheckInDateChange(date: Date) {
    this.dynamicPackageSearch.hotelVariants.checkInDate = date;
  }

  onVariantCheckOutDateChange(date: Date) {
    this.dynamicPackageSearch.hotelVariants.checkOutDate = date;
  }

  search() {
    const validationResult = this.dynamicPackageSearch.validateSearch();

    if (validationResult.valid) {
      this.saveDynamicPackageSearch();

      this.legacyRouter.redirectToDynamicPackages(
        this.dynamicPackageSearch.getSearchURL()
      );
    } else {
      this.errorService.showValidationErrors(
        validationResult.message,
        'cerrar'
      );
    }
  }

  saveDynamicPackageSearch() {
    const isDifferentCity =
      this.dynamicPackageSearch.hotelVariants.isDifferentCity;
    const isDifferentDate =
      this.dynamicPackageSearch.hotelVariants.isDifferentDate;
    let dynamicPackage = {
      origin: {
        code: this.dynamicPackageSearch.origin.value.code,
        name: this.dynamicPackageSearch.origin.value.name,
      } as AirportSearchModel,
      destination: {
        code: this.dynamicPackageSearch.destination.value.code,
        name: this.dynamicPackageSearch.destination.value.name,
      } as AirportSearchModel,
      departDate: this.dynamicPackageSearch.departDate,
      arrivalDate: this.dynamicPackageSearch.arrivalDate,
      rooms: this.dynamicPackageSearch.rooms.map((room) => {
        return {
          adults: room.adults,
          childrens: room.childrens.map(
            (child) => ({ age: child.age } as ChildSearchModel)
          ),
        } as RoomSearchModel;
      }),
      hotelVariants: {
        isDifferentCity:
          this.dynamicPackageSearch.hotelVariants.isDifferentCity,
        destination: {
          id:
            isDifferentCity &&
            this.dynamicPackageSearch.hotelVariants.location.value
              ? this.dynamicPackageSearch.hotelVariants.location.value.id
              : '',
          name:
            isDifferentCity &&
            this.dynamicPackageSearch.hotelVariants.location.value
              ? this.dynamicPackageSearch.hotelVariants.location.value.name
              : '',
        } as LocationSearchModel,
        isDifferentDate:
          this.dynamicPackageSearch.hotelVariants.isDifferentDate,
        checkInDate: isDifferentDate
          ? this.dynamicPackageSearch.hotelVariants.checkInDate
          : this.dynamicPackageSearch.departDate,
        checkOutDate: isDifferentDate
          ? this.dynamicPackageSearch.hotelVariants.checkOutDate
          : this.dynamicPackageSearch.arrivalDate,
      } as DynamicPackageSearchHotelModel,
    } as DynamicPackageSearchModel;

    this.searchService.saveDynamicPackageSearch(dynamicPackage);
  }
}
