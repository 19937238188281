<div class="d-flex align-items-center justify-content-between justify-content-sm-start mb-4 mb-xl-3">
    <h5 class="h5 d-inline-flex fw-normal m-0">Viajá más tranquilo</h5>
    <img src="https://assets.toctocviajes.com/img/logos/partners/logo-assist-card-red-h.svg" alt="Logo Assist Card" width="135" height="10" class="ms-sm-2" />
</div>
<!-- search-insurances-bar----------------------------------------- -->
<div class="row pb-xl-4 gx-3">
    <div class="col-12 col-xl">
        <div class="row g-3">
            <!-- Destination -->
            <div class="col-12 col-xl">
                <mat-form-field appearance="outline" class="w-100 t-insurance-search_form-field t-mat-form-field t-hide-bottom-messages">
                    <i class="fa-regular fa-location-dot fs-5 text-primary me-3"></i>
                    <mat-select class="fs-6 fw-semibold" [(ngModel)]="insuranceSearch.continent" (selectionChange)="onContinentChange()">
                        <mat-option class="d-flex ps-0 py-3 overflow-hidden border-bottom border-light" *ngFor="let continent of continents" [value]="continent">
                            <div class="d-flex gap-3 align-items-center ps-4 pe-3 overflow-hidden py-1" matRipple>
                                <i class="fa-regular fa-location-dot fs-5"></i>
                                <span class="fw-semibold lh-sm text-dark text-truncate fs-6">
                                    {{ continent.name }}
                                </span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Date picker -->
            <div class="col-12 col-md-6 col-xl-auto">
                <app-date-picker-range #datePicker [departDate]="insuranceSearch.startDate" [arrivalDate]="insuranceSearch.endDate" (departDateChange)="onStartDateChange($event)" (arrivalDateChange)="onEndDateChange($event)"></app-date-picker-range>
            </div>
            <!-- Passengers -->
            <div class="col-12 col-md-6 col-xl-3">
                <app-insurance-passenger-counter-panel #passengerCounterPanel [(passengers)]="insuranceSearch.passengers"></app-insurance-passenger-counter-panel>
            </div>
        </div>
    </div>

    <div class="col-12 col-xl-auto mt-4 mt-xl-0">
        <button mat-flat-button color="accent" class="t-search-button rounded-3 px-4 w-100" (click)="search()">
            Buscar
        </button>
    </div>
</div>