import { TranslationWidth } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgbDate, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-single-date-picker-mobile',
  templateUrl: './single-date-picker-mobile.component.html',
  styleUrl: './single-date-picker-mobile.component.scss'
})
export class SingleDatePickerMobileComponent implements OnInit, AfterViewInit {
  translationWidth: TranslationWidth = TranslationWidth.Abbreviated;
  @Input()
  set minDate(value: Date) {
    if (value)
      this._minDate = {
        day: value.getDate(),
        month: value.getMonth() + 1,
        year: value.getFullYear()
      };
  }
  @Input()
  set departDate(value: Date) {
    this._departDate = value;
    this.startDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
    if (value)
      this.fromDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
  }

  @Input()
  disableRange = false;

  @Input()
  public inline: boolean = true;
  @Input()
  public disabled: boolean = true;

  public minDefaultDate: Date = new Date();
  public departDateHover: Date = new Date();

  public firstDate: boolean = false
  public secondDate: boolean = false
  monthsToDisplay: number = 12;
  modalTitle: string = ''
  serializedDates: number[] = [];
  
  fromDate: NgbDate = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  _minDate: NgbDateStruct = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  _maxDate: NgbDateStruct = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  startDate: NgbDate = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  _departDate: Date = new Date();

  @ViewChild("mobilePicker")
  mobilePicker!: ElementRef;

  selectingDeparture = true;

  constructor(
    calendar: NgbCalendar,
    @Inject(MAT_BOTTOM_SHEET_DATA) private inputs: any,
    private bottomSheetRef: MatBottomSheetRef<SingleDatePickerMobileComponent>
  ) {
    this.fromDate = calendar.getToday();
    this.departDate = inputs.departDate;
    this.disableRange = inputs.disableRange;
    if (inputs.monthsToDisplay) this.monthsToDisplay = inputs.monthsToDisplay;
    if (inputs.modalTitle) this.modalTitle = inputs.modalTitle;
    if (inputs.availableDates) {
      this.serializedDates = inputs.availableDates.map((d: string | number | Date) => +d);
    }
  }

  ngOnInit() {
    this._minDate = this.inputs.minDate;
    this._maxDate = this.inputs.maxDate;
  }

  ngAfterViewInit() {
    const monthsRow = this.mobilePicker.nativeElement.querySelectorAll(".ngb-dp-months");
    monthsRow[0].classList.add("row");
    const monthsCols = monthsRow[0].querySelectorAll(".ngb-dp-month");
    monthsCols.forEach((element: HTMLElement) => {
      element.classList.add("col-12");
    });

    this.scrollToSelectedDate();
  }

  scrollToSelectedDate(): void {
    setTimeout(() => {
      if (this.mobilePicker && this.mobilePicker.nativeElement) {
        const selectedElement = this.mobilePicker.nativeElement.querySelector(
          '[data-selected="true"]'
        );

        if (selectedElement) {
          selectedElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
          });
        }
      }
    }, 0);
  }

  disMissBottomSheet() {
    this.bottomSheetRef.dismiss();
  }

  isSelected(date: NgbDate) {
    return this.fromDate.equals(date);
  }

  getDepartureDate(): Date {
    return this._departDate;
  }

  selectDepartureDate() {
    this.selectingDeparture = true;
  }

  onDateSelection(date: NgbDate) {
    this.fromDate = date;
    this.startDate = date;
    this._departDate = new Date(date.year, date.month - 1, date.day);

    this.bottomSheetRef.dismiss({
      date: date
    })
  }

  applyDates() {
    this.bottomSheetRef.dismiss({
      startDate: this.fromDate
    });
  }

  isDisabled = (date: NgbDateStruct, current?: { year: number; month: number }): boolean => {
    if (!current) {
      return false;
    }
  
    if (this.serializedDates && this.serializedDates.length) {
      const currDate = new Date(date.year, date.month - 1, date.day);
      return this.serializedDates.indexOf(+currDate) === -1;
    }
    return false;
  };  
}
