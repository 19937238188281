import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value || '';

  const hasLowercase = /[a-z]/.test(value);
  const hasUppercase = /[A-Z]/.test(value);
  const hasNumber = /\d/.test(value);
  const validLength = value.length >= 8 && value.length <= 16;

  const isValid = hasLowercase && hasUppercase && hasNumber && validLength;

  return !isValid ? { passwordCriteria: true } : null;
}
