import { Component, Renderer2 } from '@angular/core';
import { OfferService } from './pages/promotions/components/offer/services/offer.service';
import { LastVisitedUrlService } from './services/last-visited-url.service';
import { NetworkService } from './services/network.service';
import { Router } from '@angular/router';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [OfferService],
})
export class AppComponent {
  title = 'ng-promociones';

  constructor(
    private lastVisitedUrlService: LastVisitedUrlService,
    private networkService: NetworkService,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    const currentPath = this.router.url;
    const currentHref = window.location.href;

    if (
      currentPath === '/' ||
      currentHref === 'https://www.toctocviajes.com/'
    ) {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.setAttribute(link, 'href', 'https://www.toctocviajes.com/');
      this.renderer.appendChild(document.head, link);
    }
  }
}
