import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FlightSearchLegVM } from '../flight-search-vm';
import { SingleDatePickerComponent } from 'src/app/components/date-picker/single-date-picker.component';
import { Subscription } from 'rxjs';
import { SearchFocusService } from 'src/app/services/search-focus.service';
import { FlightsSimpleSearchComponent } from '../flight-search-bar/flights-simple-search/flights-simple-search.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-flight-one-leg',
  templateUrl: './flight-one-leg.component.html',
  styleUrl: './flight-one-leg.component.scss'
})
export class FlightOneLegComponent implements OnInit {
  @Input() displayMode: "inline" | "block" = "inline";
  @Input() flightSearchLeg: FlightSearchLegVM = new FlightSearchLegVM()
  @Input() legId: number = 0
  @Input() minDate: Date = new Date()
  @Output() flightSearchLegChange = new EventEmitter<FlightSearchLegVM>();
  @Output() departDateChange = new EventEmitter<{id: number, date: Date}>();
  @ViewChild('datePicker') datePicker!: SingleDatePickerComponent
  @ViewChild('airportsSearch') airportsSearch!: FlightsSimpleSearchComponent
  private subscription: Subscription = new Subscription();

  constructor(private searchFocus: SearchFocusService){

  }

  ngOnInit(): void {
    this.subscription.add(
      this.searchFocus.flightSelected$.subscribe(
        (data: { id: number; formInput: FormControl }) => {
          if (this.legId == data.id && this.airportsSearch) {
            setTimeout(() => {
              if (this.airportsSearch) {
                this.airportsSearch.setOriginFocus();
              }
            });
          }
        }
      )
    );
  }

  onDepartDateChange(date: Date) {
    this.flightSearchLeg.departDate = date
    this.departDateChange.emit({id: this.legId, date: date})
  }

  onDestinationChange() {
    if (this.datePicker) {
      this.datePicker.setFocus()
    }
  }
}
