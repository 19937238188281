import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-insurance-passenger-counter-panel',
  templateUrl: './insurance-passenger-counter-panel.component.html',
  styleUrl: './insurance-passenger-counter-panel.component.scss',
})
export class InsurancePassengerCounterPanelComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @Input() passengers: number = 0;
  @Output() passengersChange = new EventEmitter<number>();

  isMobile: boolean = false;
  private mobileSubscription!: Subscription;

  bottomSheetRef: MatBottomSheetRef<any> | null = null;
  @ViewChild('mobileTemplate', { static: false })
  bottomSheetContent!: TemplateRef<any>;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isMobile) {
      const targetElement = event.target as HTMLElement;

      if (this.isClickOnOverlay(targetElement)) {
        const elementBelow = document.elementFromPoint(
          event.clientX,
          event.clientY
        ) as HTMLElement;

        if (elementBelow && elementBelow !== targetElement) {
          this.triggerClickOnElement(elementBelow);
        }
      }
    }
  }

  isClickOnOverlay(target: HTMLElement): boolean {
    return target.classList.contains('cdk-overlay-backdrop');
  }

  triggerClickOnElement(element: HTMLElement) {
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    element.dispatchEvent(event);
  }

  constructor(
    private env: EnvironmentService,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnDestroy(): void {
    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.mobileSubscription = this.env.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  setFocus() {
    if (!this.isMobile) {
      this.menuTrigger.openMenu();
    } else {
      this.openBottomSheet();
    }
  }

  isButtonDisabled(buttonType: string): boolean {
    if (buttonType === 'minus') {
      return this.passengers <= 1;
    } else {
      return this.passengers >= 9;
    }
  }

  subtractPassenger() {
    if (this.passengers > 1) {
      this.passengers--;
    }

    this.passengersChange.emit(this.passengers);
  }

  addPassenger() {
    if (this.passengers < 9) {
      this.passengers++;
    }
    this.passengersChange.emit(this.passengers);
  }

  closePassengers() {
    this.passengersChange.emit(this.passengers);
    this.menuTrigger.closeMenu();
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();
    }
  }

  isApplyButtonDisabled() {
    return this.passengers === 0;
  }

  getPassangerCountSpan() {
    const passengers = this.passengers > 1 ? ' Pasajeros' : ' Pasajero';
    return this.passengers + passengers;
  }

  openBottomSheet() {
    if (!this.bottomSheetRef) {
      this.bottomSheetRef = this.bottomSheet.open(this.bottomSheetContent);
      this.bottomSheetRef.afterDismissed().subscribe(() => {
        this.bottomSheetRef = null;
      });
    }
  }
}
