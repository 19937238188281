import { TranslationWidth } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  NgbDate,
  NgbCalendar,
  NgbDateStruct,
  NgbPopover,
} from '@ng-bootstrap/ng-bootstrap';
import { SingleDatePickerMobileComponent } from './date-picker-mobile/single-date-picker-mobile.component';
import { EnvironmentService } from 'src/app/services/environment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-single-date-picker',
  templateUrl: './single-date-picker.component.html',
  styleUrl: './single-date-picker.component.scss',
})
export class SingleDatePickerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() displayMode: 'inline' | 'block' = 'inline';
  startDate: NgbDate = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDay()
  );
  calendar = inject(NgbCalendar);
  model: NgbDateStruct | undefined;

  hoveredDate: NgbDate = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDay()
  );
  fromDate: NgbDate = this.calendar.getToday();
  _departDate: Date = new Date();
  _minDate: NgbDateStruct = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDay()
  );
  _maxDate: NgbDateStruct = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDay()
  );

  translationWidth: TranslationWidth = TranslationWidth.Abbreviated;
  mutationObserver: MutationObserver | undefined;

  isMobile: boolean = false;
  private mobileSubscription!: Subscription;

  @Input() dateFormat: 'short' | 'long' = 'short';

  @Input()
  hasMinDate = false;

  @Input()
  set minDate(value: Date) {
    if (value)
      this._minDate = {
        day: value.getDate(),
        month: value.getMonth() + 1,
        year: value.getFullYear(),
      };
  }

  @Input()
  set departDate(value: Date) {
    this._departDate = value;
    if (value) {
      this.fromDate = new NgbDate(
        value.getFullYear(),
        value.getMonth() + 1,
        value.getDate()
      );
      this.startDate = new NgbDate(
        value.getFullYear(),
        value.getMonth() + 1,
        value.getDate()
      );
    }
  }
  @Output()
  departDateChange = new EventEmitter<Date>();

  @ViewChild('popOverFrom', { static: false })
  popOverFrom!: NgbPopover;

  constructor(
    private elementRef: ElementRef,
    private bottomSheet: MatBottomSheet,
    private env: EnvironmentService
  ) {}

  ngOnInit() {
    this.mobileSubscription = this.env.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    let daysRange = 328;
    if (!this.hasMinDate) {
      const __minDate = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
      this._minDate = {
        day: __minDate.getDate(),
        month: __minDate.getMonth() + 1,
        year: __minDate.getFullYear(),
      };
      daysRange = 330;
    }

    const __maxDate = new Date(Date.now() + daysRange * 24 * 60 * 60 * 1000);
    this._maxDate = {
      day: __maxDate.getDate(),
      month: __maxDate.getMonth() + 1,
      year: __maxDate.getFullYear(),
    };
  }

  ngAfterViewInit(): void {
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.modifyDatepickerButtons();
        }
      });
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  modifyDatepickerButtons() {
    const prevButton = document.querySelector(
      'button[aria-label="Previous month"]'
    ) as HTMLElement;
    const nextButton = document.querySelector(
      'button[aria-label="Next month"]'
    ) as HTMLElement;

    if (prevButton) {
      prevButton.setAttribute('aria-label', 'Mes anterior');
      prevButton.setAttribute('title', 'Mes anterior');
    }

    if (nextButton) {
      nextButton.setAttribute('aria-label', 'Mes siguiente');
      nextButton.setAttribute('title', 'Mes siguiente');
    }
  }

  isSelectingFromDate() {
    if (this.popOverFrom) {
      return this.popOverFrom.isOpen();
    }
    return false;
  }

  onDateSelection(date: NgbDate) {
    const _date = new Date(date.year, date.month - 1, date.day);
    this.fromDate = date;
    this.departDateChange.emit(_date);
    this.popOverFrom.close();
    this.startDate = date;
  }

  getDate(date: NgbDate | null) {
    if (!date) return new Date();
    return new Date(date.year, date.month - 1, date.day);
  }

  togglePopover() {
    if (this.popOverFrom.isOpen()) {
      this.popOverFrom.close();
    } else {
      this.popOverFrom.open();
    }
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }

    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
  }

  getDateFormat() {
    return this.dateFormat;
  }

  setFocus() {
    if (this.isMobile) {
      this.showMobileDatepicker();
    } else {
      this.togglePopover();
    }
  }

  showMobileDatepicker() {
    const datepickerBottomSheet = this.bottomSheet.open(
      SingleDatePickerMobileComponent,
      {
        data: {
          minDate: this._minDate,
          maxDate: this._maxDate,
          departDate: this._departDate,
          clickOnFirst: true,
        },
        panelClass: 'g-datepicker-mobile-container',
      }
    );

    datepickerBottomSheet.afterDismissed().subscribe((data) => {
      if (data) {
        const _startDate = new Date(
          data.date.year,
          data.date.month - 1,
          data.date.day
        );

        this.departDateChange.emit(_startDate);
      }
    });
  }

  isSelected(date: NgbDate) {
    return this.fromDate.equals(date);
  }
}
