import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LastVisitedUrlService {
  private lastUrlKey: string = 'lastUrlVisited';
  private actuallyUrlKey: string = 'actuallyUrlVisited';

  private previousUrl: string = '/home';

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.previousUrl) {
          this.localStorageService.set(this.lastUrlKey, this.previousUrl);
        }

        this.localStorageService.set(
          this.actuallyUrlKey,
          event.urlAfterRedirects
        );

        this.previousUrl = event.urlAfterRedirects;
      }
    });
  }

  getLastUrlVisited(): string {
    return this.localStorageService.get(this.lastUrlKey) || '/home';
  }

  getLastActuallyUrlVisited(): string {
    return this.localStorageService.get(this.actuallyUrlKey) || '/home';
  }
}
