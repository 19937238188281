<!-- Desktop CTA Mat-Menu -->
<div [matMenuTriggerFor]="passengersMenu" aria-label="Selección pasajeros" class="d-none d-lg-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-rooms-button" mat-ripple>
    <i class="fa-regular fa-user fs-5 text-primary me-3"></i>
    <span class="fw-semibold text-truncate d-inline-block">{{
        getPassangerCountSpan()
        }}</span>
</div>

<!-- Mobile CTA ButtomSheet -->
<div aria-label="Selección pasajeros" class="d-flex d-lg-none align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-rooms-button" mat-ripple (click)="openBottomSheet()">
    <i class="fa-regular fa-user fs-5 text-primary me-3"></i>
    <span class="fw-semibold text-truncate d-inline-block">{{
        getPassangerCountSpan()
        }}</span>
</div>

<!-- Dekstop Template - MatMenu -->
<mat-menu class="t-passengers-menu rounded-3 mt-2" #passengersMenu="matMenu" xPosition="before" backdropClass="t-backdrop" hasBackdrop="true">
    <div class="px-3 pt-3 pb-3" (click)="$event.stopPropagation()">
        <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
        <!-- <div class="d-flex gap-3 justify-content-between border-top border-opacity-25 py-3 mt-4">
            <button mat-stroked-button class="w-100" (click)="cancelPassengers()">
                Cancelar
            </button>
            <button mat-flat-button class="w-100" color="primary" [disabled]="isApplyButtonDisabled()" (click)="applyPassengers()">
                Aplicar
            </button>
        </div> -->
    </div>
</mat-menu>

<!-- Mobile template - BottomSheet -->
<ng-template #mobileTemplate>
    <!--Mobile template > Nav header -->
    <div class="container t-rooms-counter-panel-mobile_header position-sticky start-0 top-0 w-100 bg-white d-flex align-items-center justify-content-between ps-4 pe-0">
        <div class="h5 fw-normal m-0">Pasajeros</div>
        <button class="p-4 bg-transparent border-0" (click)="closePassengers()">
            <i class="fa-regular fs-2 text-secondary fa-times"></i>
        </button>
    </div>

    <div class="container p-4 pb-0">
        <!--Mobile template > Base Template -->
        <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
        <!--Mobile template > Bottom CTAs -->
        <div class="d-flex gap-3 justify-content-between border-top border-opacity-25 py-3 mt-5">
            <!-- <button mat-stroked-button class="w-100" (click)="cancelPassengers()">
                Cancelar
            </button> -->
            <button mat-flat-button class="w-100" color="primary" [disabled]="isApplyButtonDisabled()" (click)="closePassengers()">
                Listo
            </button>
        </div>
    </div>
</ng-template>

<!-- Base Template -->
<ng-template #commonTemplate>
    <div class="row align-items-center">
        <div class="col">
            <div class="mat-body-2 text-black lh-1 mb-1">Pasajeros</div>
        </div>
        <div class="col-6">
            <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
                <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="subtractPassenger()" [disabled]="isButtonDisabled('minus')">
                    <i class="fa-regular fa-minus"></i>
                </button>
                <div class="text-center fw-semibold">
                    <span>{{ passengers }}</span>
                </div>
                <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="addPassenger()" [disabled]="isButtonDisabled('plus')">
                    <i class="fa-regular fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
</ng-template>