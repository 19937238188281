import { Component } from '@angular/core';
import { OfferService } from '../promotions/components/offer/services/offer.service';
import { map, Observable, of } from 'rxjs';
import { Product } from '../promotions/components/offer/models/product.model';
import { AppState } from 'src/app/store/reducers';
import { select, Store } from '@ngrx/store';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Configuration } from 'src/app/models/configuration.model';
import { HomeOfferService } from 'src/app/services/home-offers/home-offer.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  hide = true;
  mainCampaign: any;
  offer$: Observable<Product[]> | undefined;
  index: any;
  offer: any;
  contentLoaded = false;
  http: any;
  offers: any;
  Carouseloffer$: Observable<Product[]> | undefined;
  currentUrl$: Observable<string> | undefined;
  private configuration: Configuration | undefined;

  constructor(
    private store: Store<AppState>,
    private configurationService: ConfigurationService,
    private homeOfferService: HomeOfferService
  ) {}

  getIsPromotionActive(): Observable<boolean> {
    return this.configurationService.isPromotionActive();
  }

  ngOnInit(): void {
    this.homeOfferService
      .getHomeOffersCarousel()
      .subscribe((CarouselOffers: Product[]) => {
        this.Carouseloffer$ = of(CarouselOffers);
      });

    this.homeOfferService.getHomeOffers().subscribe((offers: Product[]) => {
      this.offer$ = of(offers);
    });

    setTimeout(() => {
      this.contentLoaded = true;
    }, 2500);

    this.currentUrl$ = this.store.pipe(
      select('router'),
      map((routerState) => routerState.state.url)
    );
  }

  isActive(url: string): Observable<boolean> | undefined {
    return this.currentUrl$?.pipe(map((currentUrl) => currentUrl === url));
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  goToPackages() {
    window.open('https://paquetes.toctocviajes.com/', '_blank');
  }
}
