import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialsModule } from '../materials/materials.module';
import { NgModule } from '@angular/core';
import { DynamicPackagesBarHomeComponent } from './dynamic-packages-bar-home/dynamic-packages-bar-home.component';
import { FlightsModule } from '../flights/flights.module';
import { DynamicPackageAiprortSearchComponent } from './dynamic-packages-bar-home/dynamic-package-aiport-search/dynamic-package-airport-search.component';

@NgModule({
  declarations: [
    DynamicPackagesBarHomeComponent,
    DynamicPackageAiprortSearchComponent,
  ],
  imports: [CommonModule, SharedModule, MaterialsModule, FlightsModule],
  exports: [
    DynamicPackagesBarHomeComponent,
    DynamicPackageAiprortSearchComponent,
  ],
})
export class DynamicPackagesModule {}
