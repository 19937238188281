<!-- Desktop CTA Mat-Menu -->
<div [matMenuTriggerFor]="roomsMenu" aria-label="Selección de huéspedes y habitaciones" class="d-none d-lg-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-rooms-button" mat-ripple>
  <i class="fa-regular fa-user fs-5 text-primary me-3"></i>
  <span class="d-inline-flex fw-semibold me-4"> {{ getGuestCount() }}</span>
  <i class="fa-regular fa-bed-front fs-5 text-primary me-3"></i>
  <span class="d-inline-flex fw-semibold">{{ getRoomCount() }}</span>
</div>

<!-- Mobile CTA ButtomSheet -->
<div aria-label="Selección de huéspedes y habitaciones" class="d-flex d-lg-none align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-rooms-button" mat-ripple (click)="openBottomSheet()">
  <i class="fa-regular fa-user fs-5 text-primary me-3"></i>
  <span class="d-inline-flex fw-semibold me-4"> {{ getGuestCount() }}</span>
  <i class="fa-regular fa-bed-front fs-5 text-primary me-3"></i>
  <span class="d-inline-flex fw-semibold">{{ getRoomCount() }}</span>
</div>

<!-- Dekstop > Mat Menu -->
<mat-menu class="t-rooms-menu rounded-3 mt-2" #roomsMenu="matMenu" xPosition="before" backdropClass="t-backdrop" hasBackdrop="true">
  <div class="px-3 pt-3 pb-3" (click)="$event.stopPropagation()">
    <!-- Dekstop > Template base -->
    <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
    <!-- Destkop > Bottom CTAs -->
    <!-- <div class="d-flex gap-3 pb-3 bg-white"> -->
      <!-- TODO: DESCOMENTAR ESTO CUANDO SE SOLUCIONE LO DE LAS HABITACIONES -->
      <!-- <button mat-button color="primary" class="w-100" (click)="addRoom()">
        <i class="fa-solid fa-plus me-1"></i>
        Agregar habitación
      </button> -->
      
      <!-- <button mat-flat-button color="primary" class="w-auto px-4" (click)="apply()">
        Aceptar
      </button> -->
    <!-- </div> -->
  </div>
</mat-menu>

<!-- Mobile > Buttom sheet -->
<ng-template #mobileTemplate>
  <!-- Mobile > Nav header -->
  <div class="container t-rooms-counter-panel-mobile_header position-sticky start-0 top-0 w-100 bg-white d-flex align-items-center justify-content-between ps-4 pe-0">
    <div class="h5 fw-normal m-0">Habitaciones y huéspedes</div>
    <button class="p-4 bg-transparent border-0" (click)="closeRooms()">
      <i class="fa-regular fs-2 text-secondary fa-times"></i>
    </button>
  </div>
  <!-- Mobile > Template base -->
  <div class="container p-4">
    <ng-container *ngTemplateOutlet="commonTemplate"></ng-container>
  </div>
  <!-- Mobile > Bottom nav CTAs -->
  <div class="container position-sticky bottom-0 start-0 d-flex flex-column gap-3 bg-white p-4">
    <!-- TODO: DESCOMENTAR ESTO CUANDO SE SOLUCIONE LO DE LAS HABITACIONES -->
    <!-- <button mat-button color="primary" class="w-100" (click)="addRoom()">
      <i class="fa-solid fa-plus me-1"></i>
      Agregar habitación
    </button> -->
    <button mat-flat-button color="primary" class="w-100" (click)="closeRooms()">
      Listo
    </button>
  </div>
</ng-template>

<!-- Tempalte base Rooms -->
<ng-template #commonTemplate>
  <!-- Tempalte base Rooms > To +add room, repeat this element -->
  <ng-container *ngFor="let room of rooms; let i = index">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="fw-semibold m-0">Habitación {{ i + 1 }}</h5>
          <a *ngIf="rooms.length != 1" href="#" (click)="deleteRoom(i); $event.preventDefault()" class="fw-semibold text-danger lh-sm py-2" aria-label="Eliminar Habitación">
            <small><i class="fa-regular fa-minus me-1"></i>Eliminar</small>
          </a>
        </div>
        <!-- Tempalte base Rooms > Adultos -->
        <div class="row align-items-center">
          <div class="col">
            <div class="fs-6 text-black lh-1 mb-1">Adultos</div>
          </div>
          <div class="col-6">
            <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="minus(i, 'adults')" [disabled]="isButtonDisabled(i, 'minus', 'adults')">
                <i class="fa-regular fa-minus"></i>
              </button>
              <div class="text-center fw-semibold">
                <span> {{ room.adults }}</span>
              </div>
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="add(i, 'adults')" [disabled]="isButtonDisabled(i, 'plus', 'adults')">
                <i class="fa-regular fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Tempalte base Rooms > Menores -->
        <div class="row align-items-center my-3">
          <div class="col">
            <div class="fs-6 text-black lh-1 mb-1">Menores</div>
            <small class="text-secondary">De 0 a 17 años</small>
          </div>
          <div class="col-6">
            <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="minus(i, 'children')" [disabled]="isButtonDisabled(i, 'minus', 'children')">
                <i class="fa-regular fa-minus"></i>
              </button>
              <div class="text-center fw-semibold">
                <span> {{ room.childrens.length }} </span>
              </div>
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" (click)="add(i, 'children')" [disabled]="isButtonDisabled(i, 'plus', 'children')">
                <i class="fa-regular fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="roomHasChildrens(room)" class="row">
          <div class="col-12">
            <div class="fs-6">Edad de los menores</div>
            <p class="lh-1 mb-4">
              <small class="text-secondary">Ingresa las edades que tendrán al finalizar el viaje.</small>
            </p>
          </div>
          <div class="col-12 mb-4">
            <div class="row row-cols-2 gx-3 gy-4">
              <div *ngFor="let child of room.childrens; let i = index" class="col">
                <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages w-100">
                  <mat-label>Edad niño {{ i + 1 }}</mat-label>
                  <select matNativeControl required [(ngModel)]="child.age">
                    <option *ngFor="let age of ages" [value]="age">{{ age }} años</option>
                  </select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!-- TODO: BORRAR ESTO CUANDO SE ARREGLE LO DE LAS HABITACIONES -->
        <!-- <mat-divider class="border-secondary border-opacity-10 mb-3"></mat-divider> -->
      </div>
    </div>
  </ng-container>
</ng-template>