import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SearchService } from '../services/search.service';

export const lastSearchGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const serachService = inject(SearchService);

  const lastSearch = serachService.retrieveLastSearchProduct();

  if (lastSearch) {
    switch (lastSearch) {
      case 'flight':
        router.navigate(['/flights']);
        return false;
      case 'hotel':
        router.navigate(['/hotels']);
        return false;
      case 'flightHotel':
        router.navigate(['/custom-package']);
        return false;
      case 'insurance':
        router.navigate(['/insurances']);
        return false;
      default:
        router.navigate(['/flights']);
        return false;
    }
  } else {
    router.navigate(['/flights']);
    return false;
  }
};
