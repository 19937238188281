<div class="row align-items-center g-3">
    <div class="col-12 col-md-8 col-xxl-9 position-relative">
        <div class="row">
            <app-flights-simple-search #airportsSearch [(flightSearch)]="flightSearchLeg"
                (destinationChange)="onDestinationChange()"></app-flights-simple-search>
        </div>
    </div>

    <div class="col-12 col-md">
        <app-single-date-picker #datePicker [departDate]="flightSearchLeg.departDate"
            (departDateChange)="onDepartDateChange($event)" [displayMode]="displayMode"
            [dateFormat]="'short'"></app-single-date-picker>
    </div>
</div>