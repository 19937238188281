import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Observable,
  Subscription,
  switchMap,
} from 'rxjs';
import { AirportData, AirportSearch } from './models/airport-search.model';
import { EnvironmentService } from 'src/app/services/environment.service';
import { AirportSearchMobileComponent } from './airport-search-mobile/airport-search-mobile.component';
import { Router } from '@angular/router';
import { AirportService } from 'src/app/services/airports/airport.service';

@Component({
  selector: 'app-airport-search',
  templateUrl: './airport-search.component.html',
  styleUrl: './airport-search.component.scss',
})
export class AirportSearchComponent implements OnInit, OnDestroy {
  airportsSub: Subscription = new Subscription();
  @Input() formInput: FormControl = new FormControl();
  @Output() formInputChange = new EventEmitter<FormControl>();
  groupedAirports: AirportSearch[] = [];

  @Input() placeHolder: string = 'origen';

  isMobile: boolean = false;
  resultNotFound: boolean = false;

  @Input() iconType: string = 'fa-location-dot';
  @Input() isDestination: boolean = false;

  private mobileSubscription!: Subscription;

  @ViewChild('inputElement') input!: ElementRef;

  setFocus() {
    if (this.input && this.input.nativeElement) {
      if (this.isMobile) {
        this.showMobileForm(new MouseEvent('click'));
      } else {
        setTimeout(() => {
          this.input.nativeElement.focus();
        }, 0);
      }
    }
  }

  @Output() setFocusChange = new EventEmitter<boolean>();

  constructor(
    private cityAutocomplete: AirportService,
    private env: EnvironmentService,
    private bottomSheet: MatBottomSheet,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.airportsSub = this.createOptionsObservable(this.formInput).subscribe({
      next: (items) => {
        this.resultNotFound = false;
        if (items && items.length > 0) {
          this.groupedAirports = items;
        } else {
          this.groupedAirports = [];
          this.resultNotFound = true;
        }
      },
      error: (error) => {
        this.groupedAirports = [];
        this.resultNotFound = true;
        console.error(error);
      },
    });

    this.mobileSubscription = this.env.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  showNotFoundResult() {
    const inputValue = this.formInput.value;
    return inputValue && inputValue.length > 2 && this.resultNotFound;
  }

  goTo() {
    this.router.navigate(['/promociones']);
  }

  showSuggestion() {
    if (!this.isDestination) return false;

    const inputValue = this.formInput.value;
    return !inputValue || inputValue.length < 3;
  }

  spinning() {
    if (
      typeof this.formInput.value === 'string' &&
      this.formInput.value.length &&
      this.formInput.value.length > 2
    )
      return true;
    else return false;
  }

  getDisplay(elem?: AirportData): string {
    if (elem) {
      if (elem.selectedDisplayName) return elem.selectedDisplayName;
      if (elem.name) return elem.name;
    }

    return '';
  }

  getCode(elem?: AirportData) {
    return elem ? elem.code : undefined;
  }

  getCityName(elem?: AirportData) {
    return elem ? elem.name : undefined;
  }

  selectFirstOption() {
    if (
      typeof this.formInput.value === 'string' &&
      this.groupedAirports &&
      this.groupedAirports[0] &&
      this.groupedAirports[0].cityAirport
    )
      this.formInput.setValue(this.groupedAirports[0].cityAirport);
  }

  createOptionsObservable(control: FormControl): Observable<AirportSearch[]> {
    this.resultNotFound = false;
    return control.valueChanges.pipe(
      filter((text) => {
        return typeof text === 'string' || text instanceof String;
      }),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((token) => {
        if (token.length < 3) {
          this.groupedAirports = [];
          this.resultNotFound = false;
          return [];
        }

        return this.cityAutocomplete.getCityAirportsOptions(token);
      })
    );
  }

  onOptionSelected() {
    this.formInputChange.emit(this.formInput);
  }

  ngOnDestroy(): void {
    if (this.airportsSub) this.airportsSub.unsubscribe();
    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
  }

  onFocus(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    if (target) {
      target.select();
    }
  }

  showMobileForm(event: MouseEvent) {
    if (this.isMobile) {
      event.preventDefault();
      const airportSearchBS = this.bottomSheet.open(
        AirportSearchMobileComponent,
        {
          data: {
            title: this.placeHolder,
            iconType: this.iconType,
            isDestination: this.isDestination,
          },
          autoFocus: false,
        }
      );
      airportSearchBS.afterDismissed().subscribe((data) => {
        if (data) {
          this.formInput.setValue(data.value);
          this.formInputChange.emit(this.formInput);
        }
      });
      return false;
    }

    return false;
  }

  hasNearCity(elem?: AirportData) {
    return elem && elem.nearCity;
  }

  getDisplayNearCity(elem?: AirportData) {
    return elem && elem.nearCity ? `cerca de ${elem.displayNearCity}` : '';
  }
}
