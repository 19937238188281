import * as settings from '../app/settings';
interface Environment {
  production: boolean;
  [key: string]: any;
}

export const environment: Environment = {
  production: false,
};

environment[settings.API_URL] = 'https://apitest.toctocviajes.com/';
environment[settings.LEGACY_TTV_URL] = 'https://wwwtest2.toctocviajes.com/ttv/';
