import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { AppState } from 'src/app/store/reducers';
import * as settings from 'src/app/settings';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  currentUrl$: Observable<string> | undefined;
  user$ = this.authService.getUser();
  legacyTTV: string = '';

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private configurationService: ConfigurationService,
    private authService: AuthenticationService,
    private environmentService: EnvironmentService
  ) {}

  getIsPromotionActive(): Observable<boolean> {
    return this.configurationService.isPromotionActive();
  }

  ngOnInit(): void {
    this.currentUrl$ = this.store.pipe(
      select('router'),
      map((routerState) => routerState.state.url)
    );

    this.legacyTTV = this.environmentService.getValue(settings.LEGACY_TTV_URL);
  }

  isActive(url: string): Observable<boolean> | undefined {
    return this.currentUrl$?.pipe(map((currentUrl) => currentUrl === url));
  }

  show() {
    return (
      this.router.url !== '/login' && this.router.url !== '/create-account'
    );
  }

  getQuoterUrl() {
    return this.legacyTTV + 'quotes';
  }
}
