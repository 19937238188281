export function isStayWithinRange(
  dateOne: Date,
  dateTwo: Date,
  days: number
): boolean {
  const timeDifference = dateTwo.getTime() - dateOne.getTime();
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24) + 1;

  return dayDifference <= days;
}

export function isMoreThanOrEqualsDays(
  dateOne: Date,
  dateTwo: Date,
  days: number
): boolean {
  const timeDifference = dateTwo.getTime() - dateOne.getTime();
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24) + 1;

  return dayDifference > days;
}
