import { FormControl } from '@angular/forms';
import { RoomSearch } from '../models/rooms-search';
import { DateTime } from 'luxon';
import { isStayWithinRange } from '../utils/date-utils';

export class DynamicPackageSearchHotelVM {
  isDifferentCity: boolean;
  location = new FormControl();
  isDifferentDate: boolean;
  checkInDate: Date;
  checkOutDate: Date;

  constructor() {
    this.isDifferentCity = false;
    this.checkInDate = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
    this.checkOutDate = new Date(Date.now() + 6 * 24 * 60 * 60 * 1000);
    this.isDifferentDate = false;
  }
}

export class DynamicPackageSearchVM {
  origin = new FormControl();
  destination = new FormControl();
  departDate: Date;
  arrivalDate: Date;
  rooms: RoomSearch[];
  hotelVariants = new DynamicPackageSearchHotelVM();

  constructor() {
    this.departDate = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
    this.arrivalDate = new Date(Date.now() + 6 * 24 * 60 * 60 * 1000);
    this.rooms = [{ adults: 1, childrens: [] }];
  }

  validateSearch() {
    let valid = true;
    let message = '';

    let departCode = null;
    if (this.origin.value) departCode = this.origin.value.code;
    let arrivalCode = null;
    if (this.destination.value) arrivalCode = this.destination.value.code;

    if (!departCode) {
      message = 'Por favor, ingresá tu ciudad de origen';
      valid = false;
    } else if (!arrivalCode) {
      message = 'Por favor, ingresá tu ciudad de destino';
      valid = false;
    } else if (!this.departDate) {
      message = 'Por favor, seleccioná la fecha de salida';
      valid = false;
    } else if (!this.arrivalDate) {
      message = 'Por favor, seleccioná la fecha de llegada';
      valid = false;
    } else if (departCode === arrivalCode) {
      message = 'El origen y el destino no pueden ser iguales';
      valid = false;
    } else if (
      !(
        DateTime.fromJSDate(this.arrivalDate).diff(
          DateTime.fromJSDate(this.departDate),
          'days'
        ).days >= 0
      )
    ) {
      message = 'La fecha de salida debe ser menor a la de llegada';
      valid = false;
    } else if (!this.isDepartureDateOnRange(this.departDate)) {
      message = 'La fecha de salida debe ser mayor a dos días a partir de hoy';
      valid = false;
    } else if (
      this.hotelVariants.isDifferentCity &&
      (!this.hotelVariants.location.value ||
        !this.hotelVariants.location.value.id)
    ) {
      message = 'Ingresá el destino u hotel de tu interés';
      valid = false;
    } else if (this.hotelVariants.isDifferentDate) {
      if (!this.hotelVariants.checkInDate) {
        message = 'Seleccioná la fecha de check in';
        valid = false;
      } else if (!this.hotelVariants.checkOutDate) {
        message = 'Seleccioná la fecha de check out';
        valid = false;
      } else if (
        !(
          DateTime.fromJSDate(this.hotelVariants.checkOutDate).diff(
            DateTime.fromJSDate(this.hotelVariants.checkInDate),
            'days'
          ).days >= 0
        )
      ) {
        message = 'La fecha de check in debe ser menor a la de check out';
        valid = false;
      } else if (
        !isStayWithinRange(
          this.hotelVariants.checkInDate,
          this.hotelVariants.checkOutDate,
          30
        )
      ) {
        message =
          'No es posible realizar reservas de mas de 30 días en hoteles.';
        valid = false;
      }
    } else if (!isStayWithinRange(this.departDate, this.arrivalDate, 30)) {
      message = 'No es posible realizar reservas de mas de 30 días en hoteles.';
      valid = false;
    }

    return {
      valid,
      message,
    };
  }

  isDepartureDateOnRange(departDate: Date): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const minimumDate = new Date(today);
    minimumDate.setDate(today.getDate() + 2);

    return departDate >= minimumDate;
  }

  getSearchURL() {
    const airports = this.getAirportSearch();
    const dates = this.getDatesSearch();
    const passengers = this.getPassengersSearch();
    const hotelDates = this.hotelVariants.isDifferentDate
      ? this.getHotelDatesSearch()
      : dates;
    const rooms = this.getRoomsSearch();
    const location = this.getLocationSearch();

    return (
      `${airports}/` +
      `${dates}/` +
      `${passengers}/` +
      `${hotelDates}/` +
      `${rooms}/` +
      `${location}/` +
      `${this.hotelVariants.isDifferentCity}/` +
      `///////` +
      `13/` +
      `//` +
      `hotel-list`
    );
  }

  private getLocationSearch() {
    if (this.hotelVariants.isDifferentCity) {
      const encodedLocation = encodeURI(this.hotelVariants.location.value.name);
      return `${encodedLocation}/${this.hotelVariants.location.value.id}`;
    } else {
      return `/`;
    }
  }

  private getRoomsSearch() {
    const adults = this.getAdults();
    const childrens = this.getChildrens();
    return `${adults}/${childrens}`;
  }

  private getChildrens() {
    let children = '';

    children += this.rooms
      .map((room) => {
        if (room.childrens.length > 0) {
          const childs = room.childrens.map((child) => child.age).join('_');
          return `${room.childrens.length}_${childs}`;
        } else {
          return '0_0';
        }
      })
      .join(',');

    return children;
  }

  private getAdults() {
    let adults = '';

    adults += this.rooms.map((room) => room.adults).join(',');

    return adults;
  }

  private getHotelDatesSearch() {
    return `${this.hotelVariants.checkInDate
      .toISOString()
      .slice(0, 10)}/${this.hotelVariants.checkOutDate
      .toISOString()
      .slice(0, 10)}`;
  }

  private getPassengersSearch() {
    return this.rooms
      .reduce(
        (acc, room) => {
          acc[0] += room.adults;

          room.childrens.forEach((child) => {
            if (child.age >= 2 && child.age <= 17) {
              acc[1]++;
            } else if (child.age < 2) {
              acc[2]++;
            }
          });

          return acc;
        },
        [0, 0, 0]
      )
      .join('/');
  }

  private getDatesSearch() {
    return `${this.departDate.toISOString().slice(0, 10)}/${this.arrivalDate
      .toISOString()
      .slice(0, 10)}`;
  }

  private getAirportSearch() {
    return `${this.origin.value.code}/${this.destination.value.code}`;
  }
}
