import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { environment } from "src/environments/environment";
import { isPlatformBrowser } from "@angular/common";
import { StateTransferService } from "./state-transfer.service";
import { BehaviorSubject } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Injectable({
  providedIn: "root"
})
export class EnvironmentService {
  private isMobileSubject = new BehaviorSubject<boolean>(false);
  isMobile$ = this.isMobileSubject.asObservable();
  private customResolution = '(max-width: 992px)'
  
  constructor(
    private state: StateTransferService, 
    @Inject(PLATFORM_ID) private platformId: Object,
    private breakpointObserver: BreakpointObserver) {
      this.breakpointObserver.observe([this.customResolution])
      .subscribe(result => this.isMobileSubject.next(result.matches));
    }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  getValue<T>(key: keyof typeof environment): T {
    return environment[key] as T;
  }
}
