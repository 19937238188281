<!-- Logged user -->
<ng-container *ngIf="user$ | async as user">
  <div class="d-flex align-items-center mb-4">
    <i class="t-logged-user-face fa-solid fa-user fs-1 text-secondary bg-secondary bg-opacity-25 rounded-circle" aria-hidden="true"></i>
    <div class="ps-3">
      <span class="fs-4 fw-semibold">{{ user.firstName }}</span><br />
      <span class="fs-6 text-secondary">{{ user.email }}</span>
    </div>
  </div>
  <div class="row mx-n3">
    <ul class="t-sidenav-list w-100 px-0 m-0">
      <li>
        <a mat-ripple href="#" class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3" (click)="goToMyProfile(); $event.preventDefault()">
          <i class="fa-regular fa-user"></i>
          Mi Perfil
        </a>
      </li>
      <li>
        <a mat-ripple href="#" class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3" (click)="logOut(); $event.preventDefault()">
          <i class="fa-regular fa-arrow-right-from-bracket"></i>
          Cerrar sesión
        </a>
      </li>
    </ul>
  </div>
</ng-container>

<!-- Unlogged user -->
<ng-container *ngIf="!(user$ | async)">
  <div class="text-center mb-4">
    <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/outline-icon-user-ok.svg" width="70px" height="54px" alt="Inicio de sesión" class="mb-3" />
    <div class="fw-semibold d-inline-table lh-sm fs-5 mb-4">
      Iniciá sesión y descubrí beneficios
    </div>
    <button mat-flat-button color="primary" class="w-100 mb-2" (click)="goToLogIn()">
      Iniciar sesión
    </button>
    <button mat-button color="primary" class="w-100" (click)="goToCreateAccount()">Crear una cuenta</button>
  </div>
</ng-container>