<!-- Login -->
<div class="t-login d-flex vh-100 overflow-hidden">
  <section class="t-sidebar-img col d-none d-lg-block p-5">
    <h1 class="h1 fw-bold text-white text-center">
      Conectamos amigos <br />
      con experiencias únicas
    </h1>
  </section>

  <section class="t-content-login bg-white col d-grid align-items-baseline overflow-auto">
    <nav class="d-flex justify-content-end w-100 border-bottom p-3">
      <a href="" class="text-dark"><i class="fa-regular fa-xmark fs-2"></i></a>
    </nav>
    <main class="d-flex justify-content-center align-items-center">
      <div class="t-content-login text-center px-3 py-5">
        <!-- Sign in -->
        <div *ngIf="showLogIn()">
          <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" width="120" alt="Logo TocTocViajes" class="d-flex mx-auto" />

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Bienvenido de nuevo</h2>
            <p class="text-secondary m-0">
              Ingresá tus datos y accedé a tu cuenta.
            </p>
          </div>

          <form [formGroup]="loginForm" (ngSubmit)="logIn()" class="text-start">
            <div class="row mb-3">
              <label class="fw-semibold mb-1">Email</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="email" matInput placeholder="Ingresa tu email" formControlName="userName" />
              </mat-form-field>
            </div>

            <div class="row">
              <label class="fw-semibold mb-1">Contraseña</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="password" matInput placeholder="Ingresa tu contraseña" [type]="hide ? 'password' : 'text'" formControlName="password" />
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <i class="fa-regular {{hide ? 'fa-eye-slash' : 'fa-eye'}} fa-2xs"></i>
                </button>
              </mat-form-field>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-3 mb-4">
              <!-- <mat-checkbox color="primary" checked class="ms-n2">Recordarme</mat-checkbox> -->
              <a href="#" (click)="recoverPassword(); $event.preventDefault()" class="text-primary" aria-label="Recuperar contraseña">Recuperar contraseña</a>
            </div>

            <button type="button" mat-flat-button color="primary" aria-label="Ingresar a la cuenta" class="w-100 text-center mb-2 p-4" type="submit">
              <div *ngIf="!loading">Ingresar</div>
              <div *ngIf="loading">
                <i class="fas fa-spinner-third fa-fw fs-4 fa-spin"></i>
              </div>
            </button>
            <!-- Login with Facebook
            <button type="button" mat-stroked-button aria-label="Ingresar con Facebook" class="w-100 text-center p-4">
              <div class="d-flex align-items-center">
                <i class="fa-brands fa-facebook text-primary fs-5 me-2"></i>Ingresar con Facebook
              </div>
            </button> -->
          </form>

          <p class="mt-4 text-secondary">
            ¿No tenes cuenta?
            <a href="/create-account" class="text-primary" aria-label="Crear cuenta">Crear una</a>
          </p>
        </div>
        <!-- Recover password -->
        <div *ngIf="showRecovery()">
          <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" width="120" alt="Logo TocTocViajes" class="d-flex mx-auto" />

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Recuperar contraseña</h2>
            <p class="text-secondary m-0">
              Ingresá el correo electrónico asociado con su cuenta y te
              enviaremos las instrucciones para restablecer su contraseña.
            </p>
          </div>

          <form action="" class="text-start">
            <div class="row mb-3">
              <label class="fw-semibold mb-3">Email</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="email" matInput placeholder="Ingresa el email de tu cuenta" [formControl]="recoveryEmail" />
              </mat-form-field>
            </div>

            <button type="button" mat-flat-button color="primary" aria-label="Enviar instrucciones" class="w-100 text-center mb-2 p-4" (click)="recover()">
              <div *ngIf="!loading">
                Enviar instrucciones
              </div>
              <div *ngIf="loading">
                <i class="fas fa-spinner-third fa-fw fs-4 fa-spin"></i>
              </div>
            </button>
            <button type="button" mat-stroked-button color="primary" aria-label="Volver" class="w-100 text-center p-4" (click)="backToLogIn()">
              <div class="d-flex align-items-center">Volver</div>
            </button>
          </form>
        </div>
        <!-- Check mail message -->
        <div *ngIf="showCheckMailMessage()">
          <div class="row justify-content-center">
            <div class="bg-light rounded-circle w-auto p-5 position-relative d-flex justify-content-center align-items-center">
              <i class="fa-light fa-envelope fa-2xl position-absolute"></i>
            </div>
          </div>

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Revisá tu email</h2>
            <p class="text-secondary mb-2">
              Te enviamos las instrucciones de recuperación de contraseña a tu correo electrónico.
            </p>
            <p class="text-secondary mb-0">
              Si no recibiste el email, revisá tu casilla de spam o <a href="#" (click)="backToLogIn(); $event.preventDefault()" aria-label="back">intentá con otro correo electrónico
              </a>.
            </p>
          </div>

          <button type="button" mat-flat-button color="primary" aria-label="Entendido" class="w-100 text-center mb-2 p-4" (click)="backToLogIn()">
            Entendido
          </button>
        </div>
      </div>
    </main>
  </section>
</div>