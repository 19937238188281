import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FlightSearchLegVM } from '../../flight-search-vm';
import { AirportSearchComponent } from 'src/app/components/airport-search/airport-search.component';
import { SearchFocusService } from 'src/app/services/search-focus.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-flights-simple-search',
  templateUrl: './flights-simple-search.component.html',
  styleUrl: './flights-simple-search.component.scss',
})
export class FlightsSimpleSearchComponent implements OnInit {
  @Input() flightSearch: FlightSearchLegVM = new FlightSearchLegVM();
  @Output() flightSearchChange = new EventEmitter<FlightSearchLegVM>();
  @Input() displayMode: 'inline' | 'block' = 'inline';
  @Output() destinationChange = new EventEmitter<void>();

  @ViewChild('destinationSearch') destinationAirport!: AirportSearchComponent;
  @ViewChild('originSearch') originAirport!: AirportSearchComponent;

  constructor() {}

  public getLegChange(leg: FlightSearchLegVM) {
    this.flightSearchChange.emit(leg);
  }

  public swapDestinations() {
    const temp = this.flightSearch.origin.value;
    this.flightSearch.origin.setValue(this.flightSearch.destination.value);
    this.flightSearch.destination.setValue(temp);
  }

  ngOnInit(): void {}

  setOriginFocus() {
    this.originAirport.setFocus();
  }

  onOriginChange() {
    this.destinationAirport.setFocus();
  }

  onDestinationChange() {
    this.flightSearchChange.emit(this.flightSearch);
    this.destinationChange.emit();
  }
}
