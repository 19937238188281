<!-- Navbar Home Mobile (< 992px) -->
<div class="bg-white d-lg-none border-top border-secondary border-opacity-25">
  <nav class="t-navbar-mobile">
    <div class="w-100 d-flex">
      <button mat-ripple [class.t-active]="isActive('/flights') | async" [routerLink]="'/flights'">
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-flights-product-light fs-5"></i>
        </div>
        <small>Vuelos</small>
      </button>
      <button mat-ripple [class.t-active]="isActive('/hotels') | async" [routerLink]="'/hotels'">
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-hotels-product-light fs-5"></i>
        </div>
        <small>Hoteles</small>
      </button>
      <button mat-ripple [class.t-active]="isActive('/custom-package') | async" [routerLink]="'/custom-package'">
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-dp-product-light fs-5"></i>
        </div>
        <small>Vuelo + Hotel</small>
      </button>
      <button mat-ripple (click)="goToPackages()">
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-packages-product-light fs-5"></i>
        </div>
        <small>Paquetes</small>
      </button>
      <button mat-ripple [class.t-active]="isActive('/insurances') | async" [routerLink]="'/insurances'">
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-insurances-product-light fs-5"></i>
        </div>
        <small>Asistencia</small>
      </button>
      <button mat-ripple>
        <div class="t-navbar-mobile_product-icon" [routerLink]="'/promociones'">
          <i class="fa-kit fa-promotions-product-light fs-5"></i>
        </div>
        <small>Promociones</small>
      </button>
    </div>
  </nav>
</div>

<!-- Buscadores -->
<div class="bg-white border-bottom border-secondary border-opacity-10 mb-lg-5">
  <div class="container p-4 px-xl-0">
    <div class="t-searchers-container p-lg-4 pb-xl-0 mb-lg-n5">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<!-- H1 -->
<div class="container px-4 py-4 py-md-5">
  <h1 class="h2 fw-bold text-center my-5">✈️ La Agencia de Viajes más querida: <br class="d-none d-md-block"> Vuelos, Hoteles y Paquetes a donde quieras</h1>
</div>

<!-- Banner Backend Promo -->
<!-- <div *ngIf="getIsPromotionActive() | async" class="container px-4"> -->
<!-- <a href="" target="_blank" class="t-banner-promo d-block rounded-4 overflow-hidden"> -->
<!-- Banner Backend Promo > img  (Mobile - Tablet)-->
<!-- <img src="https://tocuyprodmedia.blob.core.windows.net/img/banner-promo-small.jpg" alt="Banner promo small" class="w-100 d-md-none"> -->
<!-- Banner Backend Promo > img  (Desktop)-->
<!-- <img src="https://tocuyprodmedia.blob.core.windows.net/img/banner-promo-large.jpg" alt="Banner promo small" class="w-100 d-none d-md-block"> -->
<!-- </a> -->
<!-- <hr class="border-secondary border-opacity-25 my-4 my-sm-5"> -->
<!-- </div> -->

<!-- Banner Backend - Type: Flight / V+H / Hotel
<div class="container px-4">
  <a href="" target="_blank" class="t-highlight-offer t-overlay position-relative d-block rounded-4 overflow-hidden">
    <img class="t-img-bg" src="https://tocuyprodmedia.blob.core.windows.net/img/background-offer-backend.jpg" alt="Background backend">
    <div class="t-highlight-offer_content position-relative text-white p-3 p-lg-5">
      <div class="pb-3">
        <div class="d-flex align-items-center mb-2">
          <h2 class="display-6 fw-semibold lh-1 m-0">Punta Cana</h2>
          <i class="fa-kit fa-dp-product-regular fs-1 ms-2"></i>
        </div>
        <p class="fw-light w-75">
          ¡Conocé aguas turquesas y lo mejor del Caribe!
        </p>
      </div>
      <div class="w-100 d-flex align-items-end justify-content-between d-md-grid gap-3 pt-lg-5">
        <div class="fs-6 lh-sm fw-semibold text-uppercase pe-3">
          Vuelo + 5 Noches de Hotel
        </div>
        <div class="text-end tex-md-left d-grid gap-2 d-md-flex align-items-center">
          <div class="fs-6 lh-1 text-decoration-line-through order-md-2">USD 1250</div>
          <div class="d-flex align-items-center fs-3 lh-1 fw-semibold order-md-1">
            <span class="fs-6 me-1 fw-bold">USD</span>1200
          </div>
        </div>
      </div>
    </div>
  </a>
  <hr class="border-secondary border-opacity-25 my-4 my-sm-5">
</div> -->

<!-- Static Banners XS-SM-MD -->
<div class="container d-lg-none px-4 pb-5">
  <div class="row d-flex flex-column gap-3">
    <!-- Static Banners > to /promociones -->
    <div class="col-12">
      <a mat-ripple href="/promociones" target="_blank"
        class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 pe-0">
          <div class="h5 fw-normal mb-2">
            ¡Ofertas que no podés<br />dejar pasar!
          </div>
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-sm py-1">
            Ver ofertas
            <i class="fa-solid fa-chevron-right fs-6 ms-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-promotions.webp" loading="lazy"  alt="Ofertas que no podes dejar pasar" />
      </a>
    </div>
    <!-- Static Banners > Betoctoc -->
    <div class="col-12">
      <app-betoctoc-static-banner></app-betoctoc-static-banner>
    </div>
    <!-- Static Banners > CallCenter -->
    <div class="col-12">
      <a mat-ripple href="tel://+59829085555" aria-label="Llamanos" class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 pe-0">
          <div class="h5 fw-normal mb-2">
            ¿Querés hablar con<br />nuestros asesores?
          </div>
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-sm py-1">
            ¡Llamanos!
            <i class="fa-solid fa-chevron-right fs-6 ms-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-call-center-agent.webp" loading="lazy" alt="Agente de viajes" />
      </a>
    </div>
  </div>
  <hr class="border-secondary border-opacity-25">
  <div class="row">
    <!-- Banner mobile Santander -->
    <div class="col-12">
      <div class="text-sm-center bg-white border border-secondary border-opacity-10 rounded-4 p-3 overflow-hidden t-banner-soy-18">
        <span class="fs-6 text-dark lh-1">
          Hasta 18 cuotas sin recargo <br class="d-md-none"> con tarjetas de crédito
          <img src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg" loading="lazy" width="100" height="17" alt="Santander" class="mb-2" />
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Static Bnners Desktop -->
<section class="container d-none d-lg-block px-4 pb-5">
  <div class="row row-cols-3 g-4">
    <!-- Static Bnners Desktop > Assist Card -->
    <div class="col">
      <a mat-ripple href="/insurances" aria-label="Viajá con la mejor Asistencia" target="_blank" class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 p-sm-4 pe-0">
          <div class="h5 fw-normal mb-2">Viajá con la mejor Asistencia</div>
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-assist-card.svg" width="148" height="11" alt="Assist Card" />
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-sm py-1">
            Ver más
            <i class="fa-solid fa-chevron-right fs-6 ms-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-assist-card.webp" alt="Viajá con la mejor Asistencia" />
      </a>
    </div>
    <!-- Static Bnners Desktop > Banner Packages -->
    <div class="col">
      <a mat-ripple href="https://paquetes.toctocviajes.com/paquetes" aria-label="Paquetes" target="_blank" class="t-static-banner t-packages-b d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 p-sm-4 pe-0">
          <div class="h5 fw-normal mb-2">
            Conocé nuestra sección<br /> de paquetes
          </div>
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-sm py-1">
            Ver paquetes
            <i class="fa-solid fa-chevron-right fs-6 ms-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-packages-home.webp" alt="Conocé nuestra sección de Paquetes" />
      </a>
    </div>
    <!-- Static Bnners Desktop > Banner Santander -->
    <div class="col">
      <div class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="p-3 p-sm-4 pe-0">
          <div class="h5 fw-normal mb-3">Hasta 18 cuotas sin recargo<br>con tarjetas de crédito</div>
          <img src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg" width="125" height="22" alt="Santander" />
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-santander.webp" alt="Hasta 18 cuotas sin recargo con tarjetas de crédito" />
      </div>
    </div>
  </div>
</section>

<!-- Offers Grid -->
<section class="container d-none d-lg-block px-4 py-sm-5">
  <div class="row">
    <div class="col-12 col-lg">
      <h2 class="mb-5">📢 Ofertas pensadas para vos</h2>
      <div class="row">
        <!-- Offers Grid > Featured Offer -->
        <div class="col-lg-4 col-xl-6 mb-4">
          <ng-container *ngIf="!contentLoaded">
            <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height: '400px', width: '100%','border-radius': '3rem 1rem 1rem 1rem','margin': '0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
          </ng-container>
          <ng-container *ngIf="contentLoaded">
            <div *ngFor="let offer of offer$ | async, trackBy trackByFn, let index = index;  let first = first ;let last = last;">
              <div *ngIf="first" class="t-rounded-top-left">
                <a href="{{ offer.url }}" target="_blank" class="offer t-offer-card">
                  <div mat-ripple class="t-offer-card_content t-highest-card t-rounded-top-left overflow-hidden position-relative overflow-hidden pt-3 bg-secondary bg-gradient bg-opacity-25">
                    <img ngSrc="{{ offer.imageUrl }}" alt="{{ offer.descriptionLine }}" onerror="this.src='assets/img/onerror-no-img.svg'" height="400" width="630" fetchpriority="high" />
                    <div class="t-offer-card_content_price position-absolute start-0 bottom-0 w-100 d-flex align-items-end justify-content-between text-white p-4 pt-5 lh-1">
                      <div class="pt-5 pe-3">
                        <div class="t-offer-card_description">
                          <h3 class="h4">{{ offer.title }}</h3>
                          <div class="lh-sm text-white mb-2">
                            {{ offer.descriptionLine }}
                          </div>
                        </div>
                        <div class="fs-6 lh-sm fw-semibold text-uppercase" *ngIf="offer.offerTypeCustomText">
                          {{offer.offerTypeCustomText }}
                        </div>
                      </div>
                      <div class="d-block text-end">
                        <div class="fs-6 lh-1 text-decoration-line-through mb-2">USD {{ offer.priceOld }}</div>
                        <div class="d-flex align-items-center fs-3 lh-1 fw-semibold m-0">
                          <span class="fs-6 me-1 fw-bold">USD</span>{{ offer.priceNew }}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Offers Grid > Offer -->
        <div class="col-lg-4 col-xl-3 mb-4">
          <ng-container *ngIf="!contentLoaded">
            <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height: '400px',width: '100%','border-radius': '1rem','margin': '0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
          </ng-container>
          <ng-container *ngIf="contentLoaded">
            <div *ngFor="let offer of offer$ | async, trackBy trackByFn, let index = index;  let first = first ;let last = last;">
              <div *ngIf="index === 1">
                <a href="{{ offer.url }}" target="_blank" class="offer t-offer-card">
                  <div mat-ripple class="mat-ripple t-offer-card_content t-highest-card overflow-hidden position-relative rounded-4 bg-secondary bg-gradient bg-opacity-25 pt-3">
                    <img ngSrc="{{ offer.imageUrl }}" alt="{{ offer.descriptionLine }}" onerror="this.src='assets/img/onerror-no-img.svg'" height="400" width="304" fetchpriority="high" />
                    <div class="t-offer-card_content_price position-absolute start-0 bottom-0 w-100 d-flex align-items-end justify-content-between text-white p-4 pt-5 lh-1">
                      <div class="pt-5 pe-3">
                        <div class="t-offer-card_description">
                          <h3 class="h4">{{ offer.title }}</h3>
                          <div class="lh-sm text-white mb-2">
                            {{ offer.descriptionLine }}
                          </div>
                        </div>
                        <div class="fs-6 lh-sm fw-semibold text-uppercase" *ngIf="offer.offerTypeCustomText">
                          {{offer.offerTypeCustomText }}
                        </div>
                      </div>
                      <div class="d-block text-end">
                        <div class="fs-6 lh-1 text-decoration-line-through mb-2">USD {{ offer.priceOld }}</div>
                        <div class="d-flex align-items-center fs-3 lh-1 fw-semibold m-0">
                          <span class="fs-6 me-1 fw-bold">USD</span>{{ offer.priceNew }}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Offers Grid > Carrousel Offer -->
        <div class="col-lg-4 col-xl-3 mb-4">
          <ng-container *ngIf="!contentLoaded">
            <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height: '400px',width: '100%','border-radius': '1rem','margin': '0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
          </ng-container>
          <ng-container *ngIf="contentLoaded">
            <div *ngIf="Carouseloffer$ | async as CarouselOffers" id="t-offersCarousel" class="carousel carousel-dark slide carousel-fade rounded-4 overflow-hidden bg-secondary bg-gradient bg-opacity-25" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button *ngFor="let offer of CarouselOffers.slice(0, 3); let i = index" type="button" [attr.data-bs-target]="'#t-offersCarousel'" [attr.data-bs-slide-to]="i" [class.active]="i === 0" [attr.aria-current]="i === 0 ? 'true' : null" [attr.aria-label]="'Slide ' + (i + 1)">
                </button>
              </div>
              <div class="carousel-inner">
                <div *ngFor="let offer of CarouselOffers.slice(0, 3); let i = index">
                  <div class="carousel-item" [ngClass]="{ 'active': i === 0 }" [attr.data-bs-slide-to]="i" data-bs-interval="4000">
                    <a href="" target="_blank">
                      <img class="d-block w-100" src="{{ offer.banners[0].fileUrl }}" alt="{{ offer.title }}" onerror="this.src='assets/img/onerror-no-img.svg'" height="400" width="304">
                      <div class="carousel-caption d-none d-md-block">
                        <h3 class="h4">{{ offer.title }}</h3>
                        <p class="text-secondary">{{ offer.descriptionLine1 }}</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#t-offersCarousel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#t-offersCarousel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-4">
        <!-- Offers Grid > Bottom grid Offers -->
        <ng-container *ngIf="!contentLoaded">
          <div *ngFor="let index of [0, 1, 2, 3]">
            <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height:'350px',width: '100%','border-radius': '1rem','margin':'0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height:'1rem',width: '70%','border-radius': '1rem','margin': '1rem 0 0.3rem 0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{height:'1rem',width: '90%','border-radius': '1rem','margin': '0','background':'rgba(0, 0, 0, 0.03)'}"></ngx-skeleton-loader>
          </div>
        </ng-container>
        <ng-container *ngIf="contentLoaded">
          <div class="col" *ngFor="let offer of (offer$ | async)?.slice(2, 6)">
            <a href="{{ offer.url }}" target="_blank" class="t-offer-card">
              <div mat-ripple class="t-offer-card_content overflow-hidden position-relative rounded-4 overflow-hidden mb-3 pt-3 bg-secondary bg-gradient bg-opacity-25">
                <img ngSrc="{{ offer.imageUrl }}" alt="{{ offer.descriptionLine }}" onerror="this.src='assets/img/onerror-no-img.svg'" height="350" width="304" fetchpriority="high" />
                <div class="t-offer-card_content_price position-absolute start-0 bottom-0 w-100 d-flex align-items-end justify-content-between text-white p-4 pt-5 lh-1">
                  <div class="pt-5 pe-3">
                    <div class="fs-6 lh-sm fw-semibold text-uppercase" *ngIf="offer.offerTypeCustomText">
                      {{offer.offerTypeCustomText }}
                    </div>
                  </div>
                  <div class="d-block text-end">
                    <div class="fs-6 lh-1 text-decoration-line-through mb-2">USD {{ offer.priceOld }}</div>
                    <div class="d-flex align-items-center fs-3 lh-1 fw-semibold m-0">
                      <span class="fs-6 me-1 fw-bold">USD</span> {{ offer.priceNew }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="t-offer-card_description">
                <h3 class="h4 text-dark mb-1">{{ offer.title }}</h3>
                <div class="fs-6 text-secondary t-descript">
                  {{ offer.descriptionLine }}
                </div>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<!-- Large Banners -->
<section title="Banners" class="container d-none d-lg-block px-4 py-sm-5 mb-5">
  <div class="row g-5 justify-content-center">
    <div class="col-lg-12 col-xl-10 col-xxl-6">
      <!-- Large Banners > /promociones -->
      <a mat-ripple href="https://www.toctocviajes.com/promociones/" aria-label="Promociones" target="_blank" class="t-static-banner-lg h-100 d-flex bg-white text-dark position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden align-items-end justify-content-between">
        <div class="p-5 pe-0 h-100 d-flex flex-column align-items-start">
          <h3 class="mb-2 lh-sm">
            Conocé nuestras promos para los mejores destinos
          </h3>
          <p class="fs-5 text-secondary mb-4">
            Tenemos las mejores ofertas para vos.
          </p>
          <button mat-flat-button color="primary" class="fw-semibold mt-auto p-4">
            Ver promos
          </button>
        </div>
        <img class="static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-promotions--lg.webp" loading="lazy" alt="Conocé nuestras promos para los mejores destinos" />
      </a>
    </div>
    <div class="col-lg-12 col-xl-10 col-xxl-6">
      <!-- Large Banners > Betoctoc -->
      <a mat-ripple href="https://www.toctocviajes.com/betoctoc" aria-label="Betoctoc" target="_blank" class="t-static-banner-lg h-100 d-flex bg-white text-dark position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden align-items-end justify-content-between">
        <div class="p-5 pe-0 h-100 d-flex flex-column align-items-start">
          <h3 class="mb-2 lh-sm">
            ¡Enterate antes de las mejores ofertas!
          </h3>
          <p class="fs-5 text-secondary mb-4">
            Sumate a la mejor comunidad de viajes desde tu celular.
          </p>
          <button mat-flat-button color="primary" class="fw-semibold mt-auto p-4">
            Quiero suscribirme
          </button>
        </div>
        <img class="static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-betoctoc--lg.webp" loading="lazy" alt="Enterate antes de las mejores ofertas con Betoctoc" />
      </a>
    </div>
  </div>
</section>

<!-- Benefits -->
<section class="bg-white d-none d-sm-block py-sm-5">
  <div class="container px-4 py-5">
    <h2 class="mb-2">Conocé los beneficios de comprar en TocTocViajes</h2>
    <p class="fs-5 text-secondary mb-5">Conocé los beneficios de comprar en TocTocViajes</p>
    <!-- Benefit 1 -->
    <div class="row row-cols-sm-2 row-cols-lg-3 g-5">
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-01.svg" loading="lazy" alt="Todas las aerolíneas y hoteles en un mismo lugar." width="63" height="63" />
        <h3 class="fs-5 my-3">Todas las aerolíneas y hoteles <br class="d-none d-md-block"> en un mismo lugar.</h3>
        <p class="fs-6 text-secondary">
          Compará entre todas las aerolíneas y cientos de miles de hoteles en todo el mundo para acceder a los precios
          más convenientes.
        </p>
      </div>
      <!-- Benefit 2 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-02.svg" loading="lazy" alt="Resolvé todo en un mismo lugar" width="63" height="63" />
        <h3 class="fs-5 my-3">¡Resolvé todo en un mismo lugar!</h3>
        <p class="fs-6 text-secondary">
          El pasaje al destino que soñaste, el hotel ideal y la asistencia en viaje para que todo salga perfecto.
        </p>
      </div>
      <!-- Benefit 3 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-03.svg" loading="lazy" alt="Pagar es más fácil y seguro" width="63" height="63" />
        <h3 class="fs-5 my-3">Pagar es más fácil y seguro.</h3>
        <p class="fs-6 text-secondary">
          Podés hacerlo con tarjeta de crédito, transferencia bancaria o en redes de cobranza.
        </p>
      </div>
      <!-- Benefit 4 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-04.svg" loading="lazy" alt="Estamos para ayudarte" width="63" height="63" />
        <h3 class="fs-5 my-3">Y no lo olvides, ¡estamos para ayudarte!</h3>
        <p class="fs-6 text-secondary">
          Más de 50 profesionales ubicados en Uruguay para darte asistencia vía telefónica, por mail, Whatsapp o en nuestras sucursales.
        </p>
      </div>
      <!-- Benefit 5 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-05.svg" loading="lazy" alt="Pagá tu viaje hasta en 18 cuotas sin recargo" width="63" height="63" />
        <h3 class="fs-5 my-3">Pagá tu viaje hasta en 18 cuotas sin recargo con Tarjetas de crédito
          Santander.</h3>
        <p class="fs-6 text-secondary">
          Y olvidate de los cobros adicionales en tu tarjeta por compras internacionales.
        </p>
      </div>
      <!-- Benefit 6 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-06.svg" loading="lazy" alt="Más de 60 años de experiencia en viajes." width="63" height="63" />
        <h3 class="fs-5 my-3">Más de 60 años de experiencia en viajes.</h3>
        <p class="fs-6 text-secondary">
          Somos parte de 5M TravelGroup, un grupo con medio siglo en la industria de viajes y tecnología aplicada al
          turismo.
        </p>
      </div>
    </div>
  </div>
</section>

<!-- Pyment Methods -->
<section class="t-payment-methods-section bg-white border-top border-dark border-opacity-10">
  <div class="t-smile container px-4">
    <h2 class="mb-2">Viajar es mucho más fácil</h2>
    <p class="fs-5 text-secondary mb-5">
      Tenemos muchas formas de pago para que puedas hacer realidad tu viaje.
    </p>
    <div class="row justify-content-between g-5 g-xl-0">
      <div class="col-12 col-xl-auto">
        <h3 class="fs-5 fw-normal border-bottom border-secondary border-opacity-25 pb-3 mb-4">Tarjetas de crédito</h3>
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-visa.svg" loading="lazy" alt="Visa" width="51" height="17" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-master-card.svg" loading="lazy" alt="Master Card" width="44" height="27" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-oca.svg" loading="lazy" alt="OCA" width="50" height="16" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-amex.svg" loading="lazy" alt="AMEX" width="61" height="16" />
        <img class="mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-prex.svg" loading="lazy" alt="Prex" width="53" height="17" />
        <div class="d-flex mb-3">
          <img src="https://assets.toctocviajes.com/img/18-cuotas-santander.svg" loading="lazy" width="297" height="29" alt="Hasta 18 cuotas sin recargo con Santander" />
        </div>
      </div>
      <div class="col-12 col-xl-auto">
        <h3 class="fs-5 fw-normal border-bottom border-secondary border-opacity-25 pb-3 mb-4">Transferencias</h3>
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg" loading="lazy" alt="Santander" width="102" height="18" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-brou.svg" loading="lazy" alt="BROU" width="97" height="27" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-bbva.svg" loading="lazy" alt="BBVA" width="64" height="20" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-scotiabank.svg" loading="lazy" alt="Scotiabank" width="95" height="14" />
        <img class="mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-itau--orange.svg" loading="lazy" alt="Itaú" width="34" height="34" />
      </div>
      <div class="col-12 col-xl-auto">
        <h3 class="fs-5 fw-normal border-bottom border-secondary border-opacity-25 pb-3 mb-4">Redes de Cobranza</h3>
        <div class="d-flex">
          <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/logo-abitab.svg" loading="lazy" alt="Abitab" width="89" height="25" />
          <img class="mb-3" src="https://assets.toctocviajes.com/img/logos/logo-redpagos.svg" loading="lazy" alt="Redpagos" width="97" height="19" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Testimonials -->
<section class="d-none d-sm-block py-sm-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-12">
        <h2 class="h2 mb-5">Miles de uruguayos confiaron en TocTocViajes y lo recomiendan</h2>
        <div class="row row-cols-sm-2 row-cols-xl-4 g-5">
          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fs-5 text-primary me-2"></i>
                <span class="fs-6 lh-1 fw-bold text-dark">Agosto 2024</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4">
                Todas las veces que viajé con Toc Toc, la atención fue excelente, tanto en la venta como en el postventa (que para mí es lo más importante). Contratar por la web es fácil y, para viajes más complejos, te resuelven todo rápido por mail o teléfono. ¡Seguiré viajando con ellos!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Manuel</span>
                <span class="fs-6 text-secondary">Cliente desde hace 4 años.</span>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fs-5 text-primary me-2"></i>
                <span class="fs-6 lh-1 fw-bold text-dark">Agosto 2024</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4">
                Excelente atención y asesoramiento. Nos orientaron mucho y buscaron las mejores opciones para nuestras necesidades. ¡Sin dudas continuaremos con esta agencia!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Sandra</span>
                <span class="fs-6 text-secondary">Cliente desde hace 1 año.</span>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fs-5 text-primary me-2"></i>
                <span class="fs-6 lh-1 fw-bold text-dark">Setiembre 2024</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4">
                Lo que más me gusta de Toc Toc Viajes es que podés comprar tu viaje desde el sitio web o tienen unos locales preciosos para ir y con profesionales que saben mucho y te asesoran. Los súper recomiendo!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Patricia</span>
                <span class="fs-6 text-secondary">Cliente desde hace 5 años.</span>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fs-5 text-primary me-2"></i>
                <span class="fs-6 lh-1 fw-bold text-dark">Setiembre 2024</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4">
                Compramos nuestros pasajes y estamos recomendando a familiares a ir con nosotros y comprar sus pasajes en Toc Toc, es una empresa muy seria. La propuesta de Santander 18 cuotas sin interés también me parece excelente!!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Jorge</span>
                <span class="fs-6 text-secondary">Cliente desde hace 3 años.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Links to landings -->
<section class="t-destinations-links-section py-sm-5">
  <div class="container px-4 py-5">
    <div class="row align-items-center">
      <div class="col-12 col-sm-11 col-xl-4 mb-5 mb-xl-0">
        <h2 class="h2 mb-4">Los destinos que más le gustan a nuestros viajeros</h2>
        <p class="t-description lead fw-normal text-secondary">
          En TocTocViajes encontrás vuelos, hoteles, vuelo + hotel, paquetes y asistencia de Assist Card en viaje. Podés comprar online o solicitar que uno
          de nuestros asesores te ayude a armar el viaje perfecto. Aprovechá nuestras ofertas y promociones para
          viajar a cualquier lugar del mundo.
        </p>
      </div>
      <div class="col-12 col-xl-8 ps-xl-5">
        <div class="row row-cols-1 row-cols-md-3 g-4 g-sm-5">
          <!-- Popular destinations Flights -->
          <div class="col">
            <div class="bg-white p-3 p-sm-4 rounded-4 shadow-sm mb-3 m-lg-0">
              <div class="d-flex align-items-center mb-4">
                <i class="fa-kit fa-flights-product-regular fa-xl text-primary me-2"></i>
                <h3 class="h5 m-0">Vuelos</h3>
              </div>
              <div class="row row-cols-2 row-cols-md-1">
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Rio-de-Janeiro-RIO/vuelos" target="_blank" title="Vuelos a Río de Janeiro">
                        <small>Vuelos a Río de Janeiro</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Salvador-de-Bahia-SSA/vuelos" target="_blank" title="Vuelos a Salvador de Bahía">
                        <small>Vuelos a Salvador de Bahía</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Miami-MIA/vuelos" target="_blank" title="Vuelos a Miami">
                        <small>Vuelos a Miami</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Nueva-York-NYC/vuelos" target="_blank" title="Vuelos a New York">
                        <small>Vuelos a New York</small>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Cancun-CUN/vuelos" target="_blank" title="Vuelos a Cancún">
                        <small>Vuelos a Cancún</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Punta-Cana-PUJ/vuelos" target="_blank" title="Vuelos a Punta Cana">
                        <small>Vuelos a Punta Cana</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Madrid-MAD/vuelos" target="_blank" title="Vuelos a Madrid">
                        <small>Vuelos a Madrid</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Barcelona-BCN/vuelos" target="_blank" title="Vuelos a Barcelona">
                        <small>Vuelos a Barcelona</small>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <!-- Popular destinations Hotels -->
          <div class="col pt-md-5">
            <div class="bg-white p-3 p-sm-4 rounded-4 shadow-sm mb-3 m-lg-0">
              <div class="d-flex align-items-center mb-4">
                <i class="fa-kit fa-hotels-product-regular fa-xl text-primary me-2"></i>
                <h3 class="h5 m-0">Hoteles</h3>
              </div>
              <div class="row row-cols-2 row-cols-md-1">
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Rio-de-Janeiro-RIO/hoteles" target="_blank" title="Hoteles en Río de Janeiro">
                        <small>Hoteles en Río de Janeiro</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Salvador-de-Bahia-SSA/hoteles" target="_blank" title="Hoteles en Salvador de Bahía">
                        <small>Hoteles en Salvador de Bahía</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Miami-MIA/hoteles" target="_blank" title="Hoteles en Miami">
                        <small>Hoteles en Miami</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Nueva-York-NYC/hoteles" target="_blank" title="Hoteles en New York">
                        <small>Hoteles en New York</small>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Cancun-CUN/hoteles" target="_blank" title="Hoteles en Cancún">
                        <small>Hoteles en Cancún</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Punta-Cana-PUJ/hoteles" target="_blank" title="Hoteles en Punta Cana">
                        <small>Hoteles en Punta Cana</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Madrid-MAD/hoteles" target="_blank" title="Hoteles en Madrid">
                        <small>Hoteles en Madrid</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Barcelona-BCN/hoteles" target="_blank" title="Hoteles en Barcelona">
                        <small>Hoteles en Barcelona</small>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <!-- Popular destinations Packages -->
          <div class="col">
            <div class="bg-white p-3 p-sm-4 rounded-4 shadow-sm mb-3 m-lg-0">
              <div class="d-flex align-items-center mb-4">
                <i class="fa-kit fa-packages-product-regular fa-xl text-primary me-2"></i>
                <h3 class="h5 m-0">Paquetes</h3>
              </div>
              <div class="row row-cols-2 row-cols-md-1">
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Rio-de-Janeiro-RIO/paquetes" target="_blank" title="Paquetes en Río de Janeiro">
                        <small>Paquetes en Río de Janeiro</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Salvador-de-Bahia-SSA/paquetes" target="_blank" title="Paquetes en Salvador de Bahía">
                        <small>Paquetes en Salvador de Bahía</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Miami-MIA/paquetes" target="_blank" title="Paquetes en Miami">
                        <small>Paquetes en Miami</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Nueva-York-NYC/paquetes" target="_blank" title="Paquetes en New York">
                        <small>Paquetes en New York</small>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Cancun-CUN/paquetes" target="_blank" title="Paquetes en Cancún">
                        <small>Paquetes en Cancún</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Punta-Cana-PUJ/paquetes" target="_blank" title="Paquetes en Punta Cana">
                        <small>Paquetes en Punta Cana</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Madrid-MAD/paquetes" target="_blank" title="Paquetes en Madrid">
                        <small>Paquetes en Madrid</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Barcelona-BCN/paquetes" target="_blank" title="Paquetes en Barcelona">
                        <small>Paquetes en Barcelona</small>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--carousel ofertas-->
<!-- <div *ngIf="Carouseloffer$ | async as CarouselOffers">
  <ul>
    <li *ngFor="let offer of CarouselOffers">{{ offer.title }}</li>
  </ul>
</div> -->