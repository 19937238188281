import { Injectable } from '@angular/core';
import { map, Observable, of, shareReplay } from 'rxjs';
import { EnvironmentService } from './environment.service';
import * as settings from '../settings';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../models/configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private apiUrl: string = '';
  private configuration$: Observable<any> | undefined;

  constructor(private http: HttpClient, private env: EnvironmentService) {
    this.apiUrl = this.env.getValue<string>(settings.API_URL);

    this.getAndSetConfiguration();
  }

  private getAndSetConfiguration() {
    if (!this.configuration$) {
      const url = `${this.apiUrl}api/landings/configuration`;
      this.configuration$ = this.http
        .get<Configuration>(url)
        .pipe(shareReplay(1));
    }
  }

  isPromotionActive(): Observable<boolean> {
    return this.configuration$
      ? this.configuration$.pipe(
          shareReplay(1),
          map((configuration) =>
            configuration
              ? configuration.isPromotionActive ||
                configuration.isPromotionWithoutDiscount
              : false
          )
        )
      : of(false);
  }

  hasDiscount(): Observable<boolean> {
    return this.configuration$
      ? this.configuration$.pipe(
          shareReplay(1),
          map((configuration) =>
            configuration
              ? configuration.promotionPercentageDiscount > 0
              : false
          )
        )
      : of(false);
  }

  getConfiguration(): Observable<Configuration> {
    return this.configuration$ ? this.configuration$ : of(null);
  }
}
