import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { debounceTime, distinctUntilChanged, filter, Observable, switchMap } from 'rxjs';
import { HotelRegionGroup, LocationSearch, RegionType } from '../models/location-search.model';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-location-search-mobile',
  templateUrl: './location-search-mobile.component.html',
  styleUrl: './location-search-mobile.component.scss'
})
export class LocationSearchMobileComponent implements OnInit, OnDestroy, AfterViewInit {
  formInput = new FormControl();
  title: string = '';
  groupedRegions: HotelRegionGroup[] = [];
  regions!: Observable<HotelRegionGroup[]>;

  @ViewChild('inputElement') inputElement!: ElementRef;
  
  constructor(
    private bottomSheetRef: MatBottomSheetRef<LocationSearchMobileComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private locationAutocomplete: LocationService
  ) {}

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.focus();
  }

  ngOnInit() {
    this.regions = this.createOptionsObservable(this.formInput);
    this.title = this.data.title;
  }

  createOptionsObservable(control: FormControl): Observable<HotelRegionGroup[]> {
    return control.valueChanges.pipe(
      filter(text => typeof text === "string" || text instanceof String),
      filter(text => text.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(token => {
        return this.locationAutocomplete.getLocationOptions(token);
      })
    );
  }

  selectItem(airport: LocationSearch) {
    this.bottomSheetRef.dismiss({
      value: airport
    });
  }

  getDisplay(elem?: LocationSearch) {
    if (elem && elem.name) {
        return elem.name;
    }

    return '';
  }

  getCode(elem?: LocationSearch) {
    return elem ? elem.id : undefined;
  }

  getCityName(elem?: LocationSearch) {
    return elem ? elem.name : undefined;
  }

  getRegionType(elem?: HotelRegionGroup) {
    if (elem){
      switch(elem.regionType) {
        case RegionType.City:
          return 'Ciudad';
        case RegionType.Hotel:
          return 'Hotel';
        case RegionType.Neighborhood:
          return 'Barrio';
      }
    }

    return '';
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  spinning() {
    if (typeof this.formInput.value === "string" && this.formInput.value.length) return true;
    else return false;
  }

  getTitle() {
    return this.title;
  }

  getLowerTitle() {
    return this.title.toLowerCase();
  }

  ngOnDestroy() {}
}
